<script setup lang="ts">
import router from "@/router";
import ColorfulBackground from "@/practice/ColorfulBackground.vue";
import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'
import {
  Card,
  CardContent,
} from '@/components/ui/card'
import IssenHeader from "@/practice/IssenHeader.vue";
import { Checkbox } from '@/components/ui/checkbox'
import { ref, watch, type Ref, computed } from "vue";
import { getMessagesFromLanguage, LocaleToLanguage } from "@/languages/messages";
import { getDefaultSourceLanguage, getLanguageFlag, getLanguageFlagCountry } from "@/practice/util";
import { onMounted } from 'vue';
import { apiRes, type CheckAllowlistResponse, type CheckUsernameResponse, type CreateStripePortalSessionResponse, type GetAgentsResponse, type GetAvailableAgentTemplatesResponse, type GetLanguageSettingsResponse, type GetUserResponse, type GetUserUsageResponse, PracticeAPI, type SubscribeResponse, type UpdateLanguageSettingsResponse, type UpdateUserResponse } from "@/practice/api";
import { protocol, domainPort } from "@/url";
import { UserStatus, ProficiencyLevel, TutorFormalness, TutorLanguageUsage, StudentGrammarHelp, SavedWordsPracticeStyle, type Agent } from "@/types/model";
import { Slider } from "@/components/ui/slider";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/ui/tabs'
const languages = Object.values(LocaleToLanguage).sort();
const sourceLanguage = ref("");
const guiLanguage = ref("");
const pageStrings = ref(getMessagesFromLanguage(getDefaultSourceLanguage()));
const API: PracticeAPI = new PracticeAPI(protocol + domainPort);
const getUserResult = apiRes<GetUserResponse>();
const updateUserResult = apiRes<UpdateUserResponse>();
const targetLanguage = ref("");
const selectedAgentTemplateId = ref("");
const formalness = ref("");
const proficiencyLevel = ref("");
const tutorLanguageUsage = ref("");
const grammarCorrection = ref("");
const noiseCancellation = ref(true);
const name = ref("");
const tutorPatience = ref([5]);
const speechSpeedRatio = ref([1.0]);
const hasOnboarded = ref(false);
const usageResult = apiRes<GetUserUsageResponse>();
const availableAgentTemplates = apiRes<GetAvailableAgentTemplatesResponse>();
const getAgentsResult = apiRes<GetAgentsResponse>();
const weeklyUsage = ref(-1);
const totalHistoricalUsage = ref(-1);
const languageSettings = apiRes<GetLanguageSettingsResponse>();
const updateLanguageSettingsResult = apiRes<UpdateLanguageSettingsResponse>();
const initialized = ref(false);
const timesEditedTutorPatience = ref(0);
const timesEditedSpeechSpeedRatio = ref(0);
const manualMode = ref(false);
const savedWordsPracticeStyles = ref<string[]>([]);
const maxDailyReviews = ref(20);
const lesson_mode = ref(true);
const activeTab = ref("account"); // Default to account tab initially

// Add these computed properties after the other refs and before the onMounted function
const isRecognitionCardsDisabled = computed(() => {
  if (!getUserResult.data.value?.user) return false;
  return !getUserResult.data.value.user.enableRecallCards;
});

const isRecallCardsDisabled = computed(() => {
  if (!getUserResult.data.value?.user) return false;
  return !getUserResult.data.value.user.enableRecognitionCards;
});

// Add these functions to handle checkbox updates with validation
function updateRecognitionCards(value: boolean) {
  if (getUserResult.data.value?.user) {
    // If trying to set to false, ensure the other one is true
    if (!value && !getUserResult.data.value.user.enableRecallCards) {
      // Can't disable both, so keep this one true
      return;
    }
    getUserResult.data.value.user.enableRecognitionCards = value;
  }
}

function updateRecallCards(value: boolean) {
  if (getUserResult.data.value?.user) {
    // If trying to set to false, ensure the other one is true
    if (!value && !getUserResult.data.value.user.enableRecognitionCards) {
      // Can't disable both, so keep this one true
      return;
    }
    getUserResult.data.value.user.enableRecallCards = value;
  }
}

onMounted(() => {
  // Create promises for all API calls
  const userPromise = API.getUser(getUserResult).then(() => {
    if (!getUserResult.data.value) {
      router.push("/email?redirect=settings");
      return;
    }
    
    // Set initial values from user data
    if (getUserResult.data.value.user.sourceLang) {
      sourceLanguage.value = getUserResult.data.value.user.sourceLang;
      guiLanguage.value = getUserResult.data.value.user.guiLang;
      pageStrings.value = getMessagesFromLanguage(guiLanguage.value);
    }
    
    if (getUserResult.data.value) {
      name.value = getUserResult.data.value.user.name;
      tutorPatience.value = [getUserResult.data.value.user.tutorPatience];
      speechSpeedRatio.value = [getUserResult.data.value.user.speechSpeedRatio];
      selectedAgentTemplateId.value = getUserResult.data.value.user.selectedAgentTemplateId;
      hasOnboarded.value = getUserResult.data.value.user.hasOnboarded;
      noiseCancellation.value = getUserResult.data.value.user.noiseCancellation;
      sourceLanguage.value = getUserResult.data.value.user.sourceLang;
      guiLanguage.value = getUserResult.data.value.user.guiLang;
      pageStrings.value = getMessagesFromLanguage(guiLanguage.value);
      manualMode.value = getUserResult.data.value.user.manualMode;
      maxDailyReviews.value = getUserResult.data.value.user.maxDailyReviews || 20;
      lesson_mode.value = getUserResult.data.value.user.lessonMode !== undefined ? getUserResult.data.value.user.lessonMode : true;
      
      // Set the active tab based on onboarded status
      if (getUserResult.data.value.user.hasOnboarded) {
        activeTab.value = "language";
      }
    }
  }).catch(() => {
    router.push("/email?redirect=settings");
  });
  
  // Start agent templates API call
  const templatesPromise = API.getAvailableAgentTemplates(availableAgentTemplates);
  API.getAgents(getAgentsResult);
  
  // Start usage data API call
  const usagePromise = API.getUserUsage(usageResult).then(() => {
    if (usageResult.data.value) {
      weeklyUsage.value = usageResult.data.value.weeklyDuration;
      totalHistoricalUsage.value = usageResult.data.value.totalDuration;
    }
  });
  
  // Start language settings API call independently
  const languageSettingsPromise = API.getLanguageSettings(languageSettings).then(() => {
    if (languageSettings.data.value) {
      targetLanguage.value = languageSettings.data.value.languageSettings.language;
      formalness.value = languageSettings.data.value.languageSettings.formalness;
      proficiencyLevel.value = languageSettings.data.value.languageSettings.proficiencyLevel;
      tutorLanguageUsage.value = languageSettings.data.value.languageSettings.tutorLanguageUsage;
      grammarCorrection.value = languageSettings.data.value.languageSettings.grammarCorrection;
      savedWordsPracticeStyles.value = languageSettings.data.value.languageSettings.savedWordsPracticeStyles;
    }
  });
  
  // Wait for all API calls to complete before setting initialized to true
  Promise.allSettled([userPromise, templatesPromise, usagePromise, languageSettingsPromise])
    .then(() => {
      getUserResult.isFetching.value = false;
      initialized.value = true;
    });
})

// Add a watch for languageSettings to update the tab when data is available
watch(() => languageSettings.data.value, (newValue) => {
  if (newValue && getUserResult.data.value?.user?.hasOnboarded && activeTab.value === "account") {
    activeTab.value = "language";
  }
});

watch(targetLanguage, async (newValue) => {
  if (!initialized.value) {
    return;
  }
  if (newValue !== null) {
    if (selectedAgentTemplateId.value !== "") {
      const currentSelectedAgentTemplate = availableAgentTemplates.data.value?.agentTemplates.find(at => at.agentTemplateId === selectedAgentTemplateId.value);
      if (currentSelectedAgentTemplate?.language !== newValue) {
        // TODO: have this be smart and get a tutor that the user has used before rather than a random one
        const agent: Agent | undefined = getAgentsResult.data.value?.agents.find(a => a.language === newValue);
        if (agent) {
          selectedAgentTemplateId.value = agent.agentTemplateId;
        } else {
          selectedAgentTemplateId.value = availableAgentTemplates.data.value?.agentTemplates.find(at => at.language === newValue && !at.deprecated)?.agentTemplateId ?? "";
        }
        await API.update_user(updateUserResult, {
          selectedAgentTemplateId: selectedAgentTemplateId.value,
        });
        if (getUserResult.data.value && getUserResult.data.value.user.hasOnboarded) {
          await API.getLanguageSettingsForLanguage(newValue, languageSettings);
          if (languageSettings.data.value) {
            targetLanguage.value = languageSettings.data.value.languageSettings.language;
            formalness.value = languageSettings.data.value.languageSettings.formalness;
            proficiencyLevel.value = languageSettings.data.value.languageSettings.proficiencyLevel;
            tutorLanguageUsage.value = languageSettings.data.value.languageSettings.tutorLanguageUsage;
            grammarCorrection.value = languageSettings.data.value.languageSettings.grammarCorrection;
            savedWordsPracticeStyles.value = languageSettings.data.value.languageSettings.savedWordsPracticeStyles;
          }
        }
      }
    }
  }
});

async function goToApp() {
  router.push("/");
}


async function goToSubscribe() {
  router.push("/subscribe");
}
async function manageAccount() {
  router.push("/manage-account");
}

watch(sourceLanguage, async (newValue) => {
  console.log("sourceLanguage", newValue);
  if (newValue !== null) {
    sourceLanguage.value = newValue;
    await API.update_user(updateUserResult, {
      sourceLang: newValue,
    });
  }
})

watch(guiLanguage, async (newValue) => {
  console.log("guiLanguage", newValue);
  if (newValue !== null) {
    guiLanguage.value = newValue;
    pageStrings.value = getMessagesFromLanguage(newValue);
    await API.update_user(updateUserResult, {
      guiLang: newValue,
    });
  }
})

watch(selectedAgentTemplateId, async (newValue) => {
  if (newValue !== null && initialized.value) {
    await API.update_user(updateUserResult, {
      selectedAgentTemplateId: newValue,
    });
  }
});

watch(noiseCancellation, async (newValue) => {
  if (newValue !== null && initialized.value) {
    await API.update_user(updateUserResult, {
      noiseCancellation: newValue,
    });
  }
});

watch(formalness, async (newValue) => {
  if (newValue !== null && initialized.value) {
    await API.updateLanguageSettings(updateLanguageSettingsResult, {
      language: targetLanguage.value,
      formalness: newValue,
    });
  }
});

watch(proficiencyLevel, async (newValue) => {
  if (newValue !== null && initialized.value) {
    await API.updateLanguageSettings(updateLanguageSettingsResult, {
      language: targetLanguage.value,
      proficiencyLevel: newValue,
    });
  }
});

watch(tutorLanguageUsage, async (newValue) => {
  if (newValue !== null && initialized.value) {
    await API.updateLanguageSettings(updateLanguageSettingsResult, {
      language: targetLanguage.value,
      tutorLanguageUsage: newValue,
    });
  }
});

watch(grammarCorrection, async (newValue) => {
  if (newValue !== null && initialized.value) {
    await API.updateLanguageSettings(updateLanguageSettingsResult, {
      language: targetLanguage.value,
      grammarCorrection: newValue,
    });
  }
});

watch(tutorPatience, async (newValue) => {
  if (newValue !== null && initialized.value) {
    const thisTime = timesEditedTutorPatience.value;
    timesEditedTutorPatience.value += 1;
    await new Promise(resolve => setTimeout(resolve, 1000));
    if (thisTime + 1 === timesEditedTutorPatience.value) { // This means the user has stopped editing
      try {
        await API.update_user(updateUserResult, {
          tutorPatience: tutorPatience.value[0],
        });
      } catch (error) {
        console.error("Error updating tutor patience settings:", error);
      }
    }
  }
});
watch(manualMode, async (newValue) => {
  if (newValue !== null && initialized.value) {
    try {
      await API.update_user(updateUserResult, {
        manualMode: newValue,
      });
    } catch (error) {
      console.error("Error updating manual mode settings:", error);
    }
  }
});

// Add watchers for flashcard preferences
watch(() => getUserResult.data.value?.user.enableRecognitionCards, async (newValue) => {
  if (newValue !== undefined && initialized.value) {
    try {
      await API.update_user(updateUserResult, {
        enableRecognitionCards: newValue,
      });
    } catch (error) {
      console.error("Error updating enableRecognitionCards settings:", error);
    }
  }
});

watch(() => getUserResult.data.value?.user.enableRecallCards, async (newValue) => {
  if (newValue !== undefined && initialized.value) {
    try {
      await API.update_user(updateUserResult, {
        enableRecallCards: newValue,
      });
    } catch (error) {
      console.error("Error updating enableRecallCards settings:", error);
    }
  }
});

watch(() => getUserResult.data.value?.user.playAudioAutomatically, async (newValue) => {
  if (newValue !== undefined && initialized.value) {
    try {
      await API.update_user(updateUserResult, {
        playAudioAutomatically: newValue,
      });
    } catch (error) {
      console.error("Error updating playAudioAutomatically settings:", error);
    }
  }
});

watch(() => getUserResult.data.value?.user.targetLangDefinition, async (newValue) => {
  if (newValue !== undefined && initialized.value) {
    try {
      await API.update_user(updateUserResult, {
        targetLangDefinition: newValue,
      });
    } catch (error) {
      console.error("Error updating targetLangDefinition settings:", error);
    }
  }
});

watch(() => getUserResult.data.value?.user.enableSpeakingMode, async (newValue) => {
  if (newValue !== undefined && initialized.value) {
    try {
      await API.update_user(updateUserResult, {
        enableSpeakingMode: newValue,
      });
    } catch (error) {
      console.error("Error updating enableSpeakingMode settings:", error);
    }
  }
});

watch(() => getUserResult.data.value?.user.showSentenceInFront, async (newValue) => {
  if (newValue !== undefined && initialized.value) {
    try {
      await API.update_user(updateUserResult, {
        showSentenceInFront: newValue,
      });
    } catch (error) {
      console.error("Error updating showSentenceInFront settings:", error);
    }
  }
});

watch(speechSpeedRatio, async (newValue) => {
  if (newValue !== null && initialized.value) {
    const thisTime = timesEditedSpeechSpeedRatio.value;
    timesEditedSpeechSpeedRatio.value += 1;
    await new Promise(resolve => setTimeout(resolve, 1000));
    if (thisTime + 1 === timesEditedSpeechSpeedRatio.value) { // This means the user has stopped editing
      try {
        await API.update_user(updateUserResult, {
          speechSpeedRatio: speechSpeedRatio.value[0],
        });
      } catch (error) {
        console.error("Error updating speech speed ratio settings:", error);
      }
    }
  }
});

// Add watcher for maxDailyReviews
watch(maxDailyReviews, async (newValue) => {
  if (newValue !== null && initialized.value) {
    if (newValue < 0 || newValue > 9999) {
      return;
    }
    try {
      await API.update_user(updateUserResult, {
        maxDailyReviews: newValue,
      });
    } catch (error) {
      console.error("Error updating maxDailyReviews settings:", error);
    }
  }
});

// Add watcher for lesson_mode
watch(lesson_mode, async (newValue) => {
  if (newValue !== undefined && initialized.value) {
    try {
      await API.update_user(updateUserResult, {
        lessonMode: newValue,
      });
    } catch (error) {
      console.error("Error updating lessonMode settings:", error);
    }
  }
});

function toggleSavedWordsPracticeStyle(style: string) {
  const index = savedWordsPracticeStyles.value.indexOf(style);
  
  if (index === -1) {
    savedWordsPracticeStyles.value.push(style);
  } else if (savedWordsPracticeStyles.value.length > 1) {
    savedWordsPracticeStyles.value.splice(index, 1);
  }
  
  if (initialized.value) {
    API.updateLanguageSettings(updateLanguageSettingsResult, {
      language: targetLanguage.value,
      savedWordsPracticeStyles: savedWordsPracticeStyles.value,
    });
  }
}

function isSavedWordsPracticeStyleSelected(style: string): boolean {
  return savedWordsPracticeStyles.value.includes(style);
}

</script>

<style scoped>
.settings-bg {
  /* background: var(--bg-base-hover); */
  min-height: 100%;
  width: 100%;
}

/* Add custom styles for the select components */
.select-with-flag {
  display: flex;
  align-items: center;
  width: 100%;
}

.flag-icon {
  flex-shrink: 0;
  margin-right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
}

.select-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<template>
  <div class="settings-bg">
    <div class="w-full flex justify-between">
      <IssenHeader :pageStrings="pageStrings" :getUserResult="getUserResult" :preLogout="() => { }" :postLogout="() => { }" />
    </div>
  <div class="background-container" >
    <ColorfulBackground :isVisible="true" />
  </div>
    <div class="flex flex-col flex-grow items-center overflow-y-auto m-0 p-0">
      <div class="w-[350px] md:w-[500px] lg:w-[600px] mb-5">
        <span class="text-h2xl md:text-h3xl text-left">{{ pageStrings.settings }}</span>
        <Button @click="goToApp" variant="secondary" class="float-right"
          :disabled="getUserResult.isFetching.value || updateUserResult.isFetching.value">
          {{ pageStrings.goToApp }}
        </Button>
      </div>

      <Tabs v-model="activeTab" class="w-[350px] md:w-[500px] lg:w-[600px] m-0 p-0">
      <TabsList class="grid w-full grid-cols-4 gap-1 mb-3 p-1 bg-bg-component shadow-card-rest rounded-sm">
        <TabsTrigger value="account" class="p-2 text-md md:text-lg">
          {{ pageStrings.account }}
        </TabsTrigger>
        <TabsTrigger value="language" class="p-2 text-md md:text-lg" v-if="languageSettings.data.value">
          {{ pageStrings.language }}
        </TabsTrigger>
        <TabsTrigger value="tutor" class="p-2 text-md md:text-lg">
          {{ pageStrings.tutor }}
        </TabsTrigger>
        <TabsTrigger value="flashcardPreferences" class="p-2 text-md md:text-lg" v-if="languageSettings.data.value">
          {{ pageStrings.flashcards }}
        </TabsTrigger>
      </TabsList>

      <TabsContent value="account">
        <Card class="w-[350px] md:w-[500px] lg:w-[600px] mb-10 pt-6" v-if="getUserResult.data.value">
          <CardContent>
            <div class="flex justify-between items-center w-full mb-4">
              <div>
                <p class="text-md md:text-lg mb-2 mt-0">{{ pageStrings.subscriptionStatus }}</p>
                <p class="text-sm md:text-md">{{ getUserResult.data.value.user.userStatus === UserStatus.SUBSCRIBED ?
                  pageStrings.subscribed : pageStrings.notSubscribed }}</p>
              </div>
              <Button @click="goToSubscribe" v-if="getUserResult.data.value.user.userStatus !== UserStatus.SUBSCRIBED"
                variant="secondary" :disabled="getUserResult.isFetching.value || updateUserResult.isFetching.value">
                {{ pageStrings.subscribe }}
              </Button>
              <Button @click="manageAccount" variant="secondary"
                v-if="getUserResult.data.value.user.userStatus === UserStatus.SUBSCRIBED"
                :disabled="getUserResult.isFetching.value || updateUserResult.isFetching.value">
                {{ pageStrings.manageAccount }}
              </Button>
            </div>
            <p v-if="totalHistoricalUsage !== -1" class="text-md md:text-lg mb-2 mt-0 mt-4">{{ pageStrings.usage }}</p>
            <p v-if="totalHistoricalUsage !== -1" class="text-sm md:text-md">{{ totalHistoricalUsage }} {{
              pageStrings.minutes }}</p>
          </CardContent>
        </Card>
      </TabsContent>

      <TabsContent value="language">
        <Card class="w-[350px] md:w-[500px] lg:w-[600px] mb-10 pt-2" v-if="getUserResult.data.value && hasOnboarded">
          <CardContent >
            <div class="pt-4 grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <div>
                <p class="text-md md:text-lg mb-2 mt-2">{{ pageStrings.interfaceLanguage }}</p>
                <Select v-model="guiLanguage" class="mb-4" v-if="hasOnboarded">
                  <SelectTrigger class="w-[280px] md:w-[200px] lg:w-[240px]">
                    <div v-if="guiLanguage" class="select-with-flag">
                      <img :src="`/assets/country_icons/${getLanguageFlag(guiLanguage)}.svg`" 
                        class="flag-icon" 
                        alt="flag" />
                      <div class="select-text">
                        {{ guiLanguage.split('_').map((word: string) => word.charAt(0).toUpperCase() +
                          word.slice(1).toLowerCase()).join(' ') }}
                      </div>
                    </div>
                    <div v-else>
                      {{ pageStrings.selectLanguage }}
                    </div>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem v-for="language in languages" :key="language" :value="language">
                      <div class="select-with-flag">
                        <img :src="`/assets/country_icons/${getLanguageFlag(language)}.svg`" 
                          class="flag-icon" 
                          alt="flag" />
                        <div class="select-text">
                          {{ language.split('_').map((word: string) => word.charAt(0).toUpperCase() +
                            word.slice(1).toLowerCase()).join(' ') }}
                        </div>
                      </div>
                    </SelectItem>
                  </SelectContent>
                </Select>
              </div>

              <div>
                <p class="text-md md:text-lg mb-2 mt-2"> {{ pageStrings.nativeLanguage }}</p>
                <Select v-model="sourceLanguage" class="mb-4" v-if="hasOnboarded">
                  <SelectTrigger class="w-[280px] md:w-[200px] lg:w-[240px]">
                    <div v-if="sourceLanguage" class="select-with-flag">
                      <img :src="`/assets/country_icons/${getLanguageFlag(sourceLanguage)}.svg`" 
                        class="flag-icon" 
                        alt="flag" />
                      <div class="select-text">
                        {{ sourceLanguage.split('_').map((word: string) => word.charAt(0).toUpperCase() +
                          word.slice(1).toLowerCase()).join(' ') }}
                      </div>
                    </div>
                    <div v-else>
                      {{ pageStrings.selectLanguage }}
                    </div>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem v-for="language in languages" :key="language" :value="language">
                      <div class="select-with-flag">
                        <img :src="`/assets/country_icons/${getLanguageFlag(language)}.svg`" 
                          class="flag-icon" 
                          alt="flag" />
                        <div class="select-text">
                          {{ language.split('_').map((word: string) => word.charAt(0).toUpperCase() +
                            word.slice(1).toLowerCase()).join(' ') }}
                        </div>
                      </div>
                    </SelectItem>
                  </SelectContent>
                </Select>
              </div>
              
              <div>
                <p class="text-md md:text-lg mb-2 mt-2">{{ pageStrings.language }}</p>
                <Select v-model="targetLanguage" class="mb-4" v-if="hasOnboarded">
                  <SelectTrigger class="w-[280px] md:w-[200px] lg:w-[240px]">
                    <div v-if="targetLanguage" class="select-with-flag">
                      <img :src="`/assets/country_icons/${getLanguageFlag(targetLanguage)}.svg`" 
                        class="flag-icon" 
                        alt="flag" />
                      <div class="select-text">
                        {{ targetLanguage.split('_').map((word: string) => word.charAt(0).toUpperCase() +
                          word.slice(1).toLowerCase()).join(' ') }}
                      </div>
                    </div>
                    <div v-else>
                      {{ pageStrings.selectLanguage }}
                    </div>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem v-for="language in languages" :key="language" :value="language">
                      <div class="select-with-flag">
                        <img :src="`/assets/country_icons/${getLanguageFlag(language)}.svg`" 
                          class="flag-icon" 
                          alt="flag" />
                        <div class="select-text">
                          {{ language.split('_').map((word: string) => word.charAt(0).toUpperCase() +
                            word.slice(1).toLowerCase()).join(' ') }}
                        </div>
                      </div>
                    </SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>

            <Separator class="mt-6 mb-0 bg-border-strong" />
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4 gap-x-6">
              <div>
                <p class="text-md md:text-lg mb-2 mt-4">{{ pageStrings.tutor }}</p>
                <Select v-model="selectedAgentTemplateId" class="mb-4">
                  <SelectTrigger class="w-[280px] md:w-[200px] lg:w-[240px]">
                    <div v-if="selectedAgentTemplateId && availableAgentTemplates.data.value" class="select-with-flag">
                      <img 
                        :src="`/assets/country_icons/${getLanguageFlagCountry(
                          availableAgentTemplates.data.value.agentTemplates.find(at => at.agentTemplateId === selectedAgentTemplateId)?.language || '',
                          availableAgentTemplates.data.value.agentTemplates.find(at => at.agentTemplateId === selectedAgentTemplateId)?.homeCountry || ''
                        )}.svg`" 
                        class="flag-icon" 
                        alt="flag" />
                      <div class="select-text">
                        {{ availableAgentTemplates.data.value.agentTemplates.find(at => at.agentTemplateId === selectedAgentTemplateId)?.name + 
                           " (" + availableAgentTemplates.data.value.agentTemplates.find(at => at.agentTemplateId === selectedAgentTemplateId)?.homeCity + ")" }}
                      </div>
                    </div>
                    <div v-else>
                      {{ pageStrings.selectTutor }}
                    </div>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem
                      v-for="agentTemplate in availableAgentTemplates.data.value?.agentTemplates.filter(at => at.language === targetLanguage && (!at.deprecated || getAgentsResult.data.value?.agents.find(a => a.agentTemplateId === at.agentTemplateId)) )"
                      :key="agentTemplate.agentTemplateId" :value="agentTemplate.agentTemplateId">
                      <div class="select-with-flag">
                        <img :src="`/assets/country_icons/${getLanguageFlagCountry(agentTemplate.language, agentTemplate.homeCountry)}.svg`" 
                          class="flag-icon" 
                          alt="flag" />
                        <div class="select-text">{{ agentTemplate.name + " (" + agentTemplate.homeCity + ")" }}</div>
                      </div>
                    </SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div>
                <div class="flex items-center mt-4">
                  <Checkbox :checked="lesson_mode" @update:checked="lesson_mode = $event" />
                  <p class="text-md md:text-lg ml-4">{{ pageStrings.lessonMode }}</p>
                </div>
                <span class="flex justify-between mt-2 text-gray-500">
                  <span class="text-xs md:text-sm">{{ pageStrings.lessonModeDescription }}</span>
                </span>
              </div>

              <div>
                <p class="text-md md:text-lg mb-2 mt-4">{{ pageStrings.proficiencyLevel }}</p>
                <Select v-model="proficiencyLevel" class="mb-4">
                  <SelectTrigger class="w-[280px] md:w-[200px] lg:w-[240px]">
                    <SelectValue :placeholder="pageStrings.selectProficiencyLevel" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem v-for="proficiency in Object.values(ProficiencyLevel)" :key="proficiency"
                      :value="proficiency">
                      {{ proficiency.split('_').map((word: string) => word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase()).join(' ')}}
                    </SelectItem>
                  </SelectContent>
                </Select>
              </div>

              <div>
                <p class="text-md md:text-lg mb-2 mt-4">{{ pageStrings.tutorLanguageUsage }}</p>
                <Select v-model="tutorLanguageUsage" class="mb-4">
                  <SelectTrigger class="w-[280px] md:w-[200px] lg:w-[240px]">
                    <SelectValue :placeholder="pageStrings.selectTutorLanguageUsage" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem v-for="tutorLanguageUsage in Object.values(TutorLanguageUsage)" :key="tutorLanguageUsage"
                      :value="tutorLanguageUsage">
                      {{ tutorLanguageUsage.split('_').map((word: string) => word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase()).join(' ')}}
                    </SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div>
                <p class="text-md md:text-lg mb-2 mt-4">{{ pageStrings.grammarCorrection }}</p>
                <Select v-model="grammarCorrection" class="mb-4">
                  <SelectTrigger class="w-[280px] md:w-[200px] lg:w-[240px]">
                    <SelectValue :placeholder="pageStrings.selectGrammarCorrection" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem v-for="grammarCorrection in Object.values(StudentGrammarHelp)" :key="grammarCorrection"
                      :value="grammarCorrection">
                      {{ grammarCorrection.split('_').map((word: string) => word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase()).join(' ')}}
                    </SelectItem>
                  </SelectContent>
                </Select>
              </div>

              <div>
                <p class="text-md md:text-lg mb-2 mt-4">{{ pageStrings.formalness }}</p>
                <Select v-model="formalness" class="mb-4">
                  <SelectTrigger class="w-[280px] md:w-[200px] lg:w-[240px]">
                    <SelectValue :placeholder="pageStrings.selectLanguage" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem v-for="form in Object.values(TutorFormalness)" :key="form" :value="form">
                      {{ form.split('_').map((word: string) => word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase()).join(' ')}}
                    </SelectItem>
                  </SelectContent>
                </Select>
              </div>


            </div>
          </CardContent>
        </Card>
      </TabsContent>
      <TabsContent value="tutor">
        <Card class="w-[350px] md:w-[500px] lg:w-[600px] mb-14 pt-2" v-if="getUserResult.data.value">
          <CardContent>
            <p class="text-md md:text-lg mt-4" :class="{ 'text-gray-500': manualMode }">{{ pageStrings.tutorPatience }}: {{ tutorPatience?.[0] }}</p>
            <Slider class="mt-2" v-model="tutorPatience" :max="11" :min="1" :step="1" trackColor="bg-bg-chat-sent"
              :thumbColor="manualMode ? 'bg-gray-300' : 'bg-fg-interactive'" :rangeColor="manualMode ? 'bg-gray-300' : 'bg-bg-active'" :disabled="manualMode"/>
            <span class="flex justify-between mt-2 text-gray-500">
              <span class="text-xs md:text-sm">{{ pageStrings.tutorPatienceDescription }}</span>
            </span>
            <span class="flex items-center mt-4">
              <p class="text-md md:text-lg">{{ pageStrings.manualMode }}</p>
              <Checkbox :checked="manualMode" @update:checked="manualMode = $event" class="mr-2 ml-3"/>
            </span>
            <span class="flex justify-between mt-2 text-gray-500">
              <span class="text-xs md:text-sm">{{ pageStrings.manualModeDescriptionMobile }}</span>
            </span>
            <p class="text-md md:text-lg mt-4">{{ pageStrings.speechSpeedRatio }}: {{ speechSpeedRatio?.[0] }}</p>
            <Slider class="mt-2" v-model="speechSpeedRatio" :max="1.2" :min="0.7" :step="0.05"
              trackColor="bg-bg-chat-sent" thumbColor="bg-fg-interactive" />
            <span class="flex justify-between mt-2 text-gray-500">
              <span class="text-xs md:text-sm">{{ pageStrings.speechSpeedRatioDescription }}</span>
            </span>
            <div class="flex items-center mt-4">
              <Checkbox :checked="noiseCancellation" @update:checked="noiseCancellation = $event" />
              <p class="text-md md:text-lg ml-4">{{ pageStrings.noiseCancellation }}</p>
            </div>

            <Separator class="my-4 mb-2 bg-border-strong" />
            <div v-if="getUserResult.data.value && hasOnboarded">
              <div>
                <p class="text-md md:text-lg mb-2 mt-4">{{ pageStrings.savedWordsPracticeStyles }}</p>
                <span class="flex justify-between mb-4 text-gray-500">
                  <span class="text-xs md:text-sm">{{ pageStrings.savedWordsPracticeStylesDescription }}</span>
                </span>
                <div class="flex flex-col space-y-4">
                  <div class="flex flex-col" v-for="style in Object.values(SavedWordsPracticeStyle)" :key="style">
                    <div class="flex items-center space-x-2">
                      <Checkbox 
                        :id="style" 
                        :checked="isSavedWordsPracticeStyleSelected(style)"
                        @update:checked="() => toggleSavedWordsPracticeStyle(style)"
                        :disabled="isSavedWordsPracticeStyleSelected(style) && savedWordsPracticeStyles.length === 1"
                      />
                      <label :for="style" class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                        {{ pageStrings[`savedWordsPracticeStyles${style.charAt(0).toUpperCase() + style.slice(1)}`] || `Saved Words Practice Style for ${style}` }}
                      </label>
                    </div>
                    <p class="text-xs md:text-sm text-gray-500 ml-6 mt-1">
                      {{ pageStrings[`savedWordsPracticeStyleDescription${style.charAt(0).toUpperCase() + style.slice(1)}`] || `Description for ${style}` }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </TabsContent>

      <TabsContent value="flashcardPreferences">
        <Card class="w-[350px] md:w-[500px] lg:w-[600px] mb-14 pt-4" v-if="getUserResult.data.value && hasOnboarded">
          <CardContent>
            <div class="mt-2">
              <p class="text-md md:text-lg mb-2">{{ pageStrings.maxCardsPerDay }}</p>
              <div class="flex items-center">
                <input 
                  type="number" 
                  v-model="maxDailyReviews" 
                  min="9" 
                  max="9999" 
                  class="w-24 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <span class="ml-3 text-sm text-gray-500">{{ pageStrings.maxCardsPerDayDescription }}</span>
              </div>
            </div>
            <div class="flex items-center mt-4">
              <Checkbox 
                :checked="getUserResult.data.value.user.enableRecognitionCards" 
                @update:checked="updateRecognitionCards" 
                :disabled="isRecognitionCardsDisabled"
              />
              <p class="text-md md:text-lg ml-4" :class="{ 'text-gray-500': isRecognitionCardsDisabled }">
                {{ pageStrings.enableRecognitionCards }}
                <span v-if="isRecognitionCardsDisabled" class="text-xs ml-2">({{ pageStrings.requiredWhenRecallDisabled}})</span>
              </p>
            </div>
            <span class="flex justify-between mt-2 text-gray-500">
              <span class="text-xs md:text-sm">{{ pageStrings.enableRecognitionCardsDescription }}</span>
            </span>

            <div class="flex items-center mt-4">
              <Checkbox 
                :checked="getUserResult.data.value.user.enableRecallCards" 
                @update:checked="updateRecallCards" 
                :disabled="isRecallCardsDisabled"
              />
              <p class="text-md md:text-lg ml-4" :class="{ 'text-gray-500': isRecallCardsDisabled }">
                {{ pageStrings.enableRecallCards }}
                <span v-if="isRecallCardsDisabled" class="text-xs ml-2">({{ pageStrings.requiredWhenRecognitionDisabled }})</span>
              </p>
            </div>
            <span class="flex justify-between mt-2 text-gray-500">
              <span class="text-xs md:text-sm">{{ pageStrings.enableRecallCardsDescription }}</span>
            </span>

            <div class="flex items-center mt-4">
              <Checkbox 
                :checked="getUserResult.data.value.user.playAudioAutomatically" 
                @update:checked="getUserResult.data.value.user.playAudioAutomatically = $event" 
              />
              <p class="text-md md:text-lg ml-4">{{ pageStrings.playAudioAutomatically }}</p>
            </div>
            <span class="flex justify-between mt-2 text-gray-500">
              <span class="text-xs md:text-sm">{{ pageStrings.playAudioAutomaticallyDescription }}</span>
            </span>

            <div class="flex items-center mt-4">
              <Checkbox 
                :checked="getUserResult.data.value.user.targetLangDefinition" 
                @update:checked="getUserResult.data.value.user.targetLangDefinition = $event" 
              />
              <p class="text-md md:text-lg ml-4">{{ pageStrings.targetLangDefinition }}</p>
            </div>
            <span class="flex justify-between mt-2 text-gray-500">
              <span class="text-xs md:text-sm">{{ pageStrings.targetLangDefinitionDescription }}</span>
            </span>

            <!-- <div class="flex items-center mt-4">
              <Checkbox 
                :checked="getUserResult.data.value.user.enableSpeakingMode" 
                @update:checked="getUserResult.data.value.user.enableSpeakingMode = $event" 
              />
              <p class="text-md md:text-lg ml-4">{{ pageStrings.enableSpeakingMode }}</p>
            </div>
            <span class="flex justify-between mt-2 text-gray-500">
              <span class="text-xs md:text-sm">{{ pageStrings.enableSpeakingModeDescription }}</span>
            </span> -->

            <div class="flex items-center mt-4">
              <Checkbox 
                :checked="getUserResult.data.value.user.showSentenceInFront" 
                @update:checked="getUserResult.data.value.user.showSentenceInFront = $event" 
              />
              <p class="text-md md:text-lg ml-4">{{ pageStrings.showSentenceInFront }}</p>
            </div>
            <span class="flex justify-between mt-2 text-gray-500">
              <span class="text-xs md:text-sm">{{ pageStrings.showSentenceInFrontDescription }}</span>
            </span>


          </CardContent>
        </Card>
      </TabsContent>
      </Tabs>
    </div>
  </div>
</template>
