<script setup lang="ts">
import {
  Card,
  CardContent,
} from '@/components/ui/card'
import IssenHeader from "@/practice/IssenHeader.vue";
import { onMounted, ref, type Ref, computed, onUnmounted, watch } from "vue";
import { getMessagesFromLanguage } from "@/languages/messages";
import { FSRS_TARGET_RETENTION, getDefaultSourceLanguage } from "@/practice/util";
import { apiRes, PracticeAPI, type GetUserResponse, type GetDueCardsResponse, type RateCardResponse, type GetLanguageSettingsResponse } from "@/practice/api";
import { domainPort, protocol } from "@/url";
import { PhCircleNotch, PhPlayCircle, PhPauseCircle, PhCheck, PhX } from '@phosphor-icons/vue';
import { type Card as FlashCard, type Word, Rating, State, STUDY_AHEAD_TIME_SECS } from '@/types/model';
import { Button } from '@/components/ui/button';
import { useRouter } from 'vue-router';
import { Separator } from '@/components/ui/separator';
import { boldedSentence, convertRubyToRaw } from '@/practice/util';
import ColorfulBackground from '@/practice/ColorfulBackground.vue';


const guiLanguage = ref(getDefaultSourceLanguage());
const pageStrings= ref(getMessagesFromLanguage(guiLanguage.value));
const getUserResult = apiRes<GetUserResponse>();
const API: PracticeAPI = new PracticeAPI(protocol + domainPort);
const dueCardsResult = apiRes<GetDueCardsResponse>();
const frontOfCard: Ref<boolean> = ref(true);
const definitionInSourceLang: Ref<boolean> = ref(true);
const showLongDefinition: Ref<boolean> = ref(false);

// Audio state tracking
const currentAudio = ref<HTMLAudioElement | null>(null);
const isWordAudioPlaying = ref(false);
const isSentenceAudioPlaying = ref(false);
const currentAudioType = ref<'word' | 'sentence' | null>(null);
const isAutoPlayedAudio = ref(false); // Track if audio was triggered by auto-play when flipping card
const rateCardResult = apiRes<RateCardResponse>();
// Review timing tracking
const reviewStartTime = ref<number>(Date.now());
const doneReviewsToday = ref(0);
const router = useRouter();

// Track total cards for progress calculation
const totalCards = ref(0);

// Computed property for progress percentage
const progressPercentage = computed(() => {
  if (totalCards.value === 0) {
    if  (doneReviewsToday.value === 0) {
      return 0;
    } else {
      return 100;
    }
  }
  const remaining = numReviewsLeft.value;
  return Math.min(100, Math.floor((doneReviewsToday.value / (doneReviewsToday.value + remaining)) * 100));
});

// Keyboard event handler
const keyLastPressed = ref<Record<string, number>>({});
const KEY_COOLDOWN_MS = 100; // Keep cooldown low to allow quick successive presses
const keyActionPerformed = ref<Record<string, boolean>>({}); // Track if action was performed for this keydown

onMounted(async () => {
  // Call all APIs concurrently
  await Promise.all([
    API.getUser(getUserResult),
    API.getDueCards(dueCardsResult),
  ]);
  
  // Process results after all APIs have completed
  if (getUserResult.data.value && getUserResult.data.value.user.guiLang) {
    guiLanguage.value = getUserResult.data.value.user.guiLang;
    pageStrings.value = getMessagesFromLanguage(guiLanguage.value);
  }
  
  doneReviewsToday.value = dueCardsResult.data.value?.doneReviewsToday || 0;
  definitionInSourceLang.value = !getUserResult.data.value?.user.targetLangDefinition || false;
  
  // Set total cards for progress tracking
  if (dueCardsResult.data.value && dueCardsResult.data.value.cards) {
    const now = Date.now() + STUDY_AHEAD_TIME_SECS * 1000;
    totalCards.value = dueCardsResult.data.value.cards.filter(card => (card.due * 1000) < now).length;
  }
  
  // Initialize the review timer
  resetReviewTimer();
  
  // Add keyboard event listeners
  window.addEventListener('keydown', handleKeyDown);
  window.addEventListener('keyup', handleKeyUp);
});

onUnmounted(() => {
  // Remove keyboard event listeners when component is unmounted
  window.removeEventListener('keydown', handleKeyDown);
  window.removeEventListener('keyup', handleKeyUp);
  
  // Stop any playing audio when component is unmounted
  stopAudio();
});

// Handle key release
const handleKeyUp = (event: KeyboardEvent) => {
  const key = event.key;
  // Reset the action performed flag when key is released
  keyActionPerformed.value[key] = false;
};

const handleKeyDown = (event: KeyboardEvent) => {
  // Only process if we have an active card
  if (!activeCard.value) return;
  
  const currentTime = Date.now();
  const key = event.key;
  
  // If we've already performed an action for this keydown, ignore it
  // This is the key to preventing holding from triggering multiple actions
  if (keyActionPerformed.value[key]) {
    return;
  }
  
  // Check if the key was recently pressed (prevent very rapid presses)
  if (keyLastPressed.value[key] && currentTime - keyLastPressed.value[key] < KEY_COOLDOWN_MS) {
    return;
  }
  
  // Update the last pressed time for this key
  keyLastPressed.value[key] = currentTime;
  
  // Mark that we've performed an action for this keydown
  keyActionPerformed.value[key] = true;
  
  switch (key) {
    case ' ':  // Space key
      if (frontOfCard.value) {
        // If on front of card, flip it
        flipCard();
      } else {
        // If on back of card, rate as correct
        handleCardRating(3);
      }
      break;
    case '1':
      if (frontOfCard.value) {
        // If on front of card, flip it
        flipCard();
      } else {
        // If on back of card, rate as incorrect
        handleCardRating(1);
      }
      break;
    case '3':
      if (frontOfCard.value) {
        // If on front of card, flip it
        flipCard();
      } else {
        // If on back of card, rate as correct and play sound
        handleCardRating(3);
      }
      break;
  }
};

// Function to play the correct sound
const playCorrectSound = () => {
//   const correctSound = new Audio('/assets/correct1.mp3');
//   correctSound.play();
};

// Define a type for the combined card and word data
type ActiveCardData = {
  card: FlashCard;
  word: Word;
} | null;

// Create a computed property for the active card
const activeCard = computed<ActiveCardData>(() => {
  if (!dueCardsResult.data.value || 
      !dueCardsResult.data.value.cards.length || 
      !dueCardsResult.data.value.words.length) {
    return null;
  }
  // Debug: Print the sorted words by their date
  if (dueCardsResult.data.value) {
    dueCardsResult.data.value.cards.forEach(card => {
      const word = dueCardsResult.data.value?.words.find(w => w.id === card.wordId);
    });
    
    // Sort cards by due date for debugging
    const sortedCards = [...dueCardsResult.data.value.cards].sort((a, b) => 
      new Date(a.due).getTime() - new Date(b.due).getTime()
    );
    
    sortedCards.forEach(card => {
      const word = dueCardsResult.data.value?.words.find(w => w.id === card.wordId);
    });
  }
  const now = new Date();
  const studyAheadTime = new Date(now.getTime() + 1200 * 1000); // Add 1200 seconds (20 minutes)
  
  // Find the card with the oldest due date that is due (due date < now + study ahead time)
  const dueCards = dueCardsResult.data.value.cards.filter(card => 
    new Date(card.due * 1000) < studyAheadTime
  );

  
  // If no cards are due, return null
  if (dueCards.length === 0) {
    return null;
  }
  
  // Sort by due date (oldest first) and get the first one
  const card = dueCards.sort((a, b) => 
    new Date(a.due).getTime() - new Date(b.due).getTime()
  )[0];
  
  // Find the matching word based on the wordId in the card
  const word = dueCardsResult.data.value.words.find(w => w.id === card.wordId);
  
  // If we found both the card and the word, return the combined object
  if (card && word) {
    return { card, word };
  }
  
  return null;
});

const parsedWordWithTransliteration = computed(() => {
  if (!activeCard.value || !activeCard.value.word.wordWithTransliteration) return null;
  
  const wordWithTransliteration = activeCard.value.word.wordWithTransliteration;
  
  // Check if there are parentheses in the string
  const match = wordWithTransliteration.match(/^(.*?)\s*\((.*?)\)\s*$/);
  
  if (match) {
    // Return an array with [word, transliteration]
    return [match[1].trim(), match[2].trim()];
  } else {
    // If no parentheses, return the word as is and null for transliteration
    return [wordWithTransliteration, null];
  }
});

const flipCard = () => {
  frontOfCard.value = !frontOfCard.value;
  // Stop any playing audio when flipping the card
  stopAudio();
  
  // Clear key pressed state when flipping card
  keyLastPressed.value = {};
  // We don't reset keyActionPerformed here because we need it to persist until keyup
  
  // If flipping to the back of the card, play audio automatically
  if (!frontOfCard.value && activeCard.value && getUserResult.data.value?.user.playAudioAutomatically) {
    // Set the flag to indicate this is auto-played audio
    isAutoPlayedAudio.value = true;
    
    // Play word audio if available
    if (activeCard.value.word.wordAudioS3Link) {
      // Use setTimeout to ensure the UI updates before playing audio
      setTimeout(() => {
        // Check if we're still on the back of the card
        if (frontOfCard.value) {
          return;
        }
        // Use toggleAudio instead of creating a new audio element directly
        toggleAudio(activeCard.value!.word.wordAudioS3Link!, 'word', true); // Pass true to indicate auto-play
      }, 100);
    } 
    // If no word audio but sentence audio is available, play that
    else if (activeCard.value.word.sentenceAudioS3Link) {
      setTimeout(() => {
        // Check if we're still on the back of the card
        if (frontOfCard.value) {
          return;
        }
        // Use toggleAudio instead of creating a new audio element directly
        toggleAudio(activeCard.value!.word.sentenceAudioS3Link!, 'sentence', true); // Pass true to indicate auto-play
      }, 100);
    }
  }
};

// Add a watch to play sentence audio after word audio ends, but only if it was auto-played
watch([isWordAudioPlaying, currentAudioType], ([isPlaying, type], [wasPlaying, oldType]) => {
  // If word audio just finished playing (was playing but now stopped)
  if (wasPlaying && !isPlaying && oldType === 'word' && !frontOfCard.value && activeCard.value?.word.sentenceAudioS3Link) {
    // Only auto-play sentence audio if the word audio was triggered by auto-play (flipping card)
    if (isAutoPlayedAudio.value) {
      // Wait a short delay before playing sentence audio
      setTimeout(() => {
        // Check if we're still on the back of the card
        if (frontOfCard.value) {
          return;
        }
        // Play sentence audio, passing true to maintain auto-play flag
        toggleAudio(activeCard.value!.word.sentenceAudioS3Link!, 'sentence', true);
      }, 100);
    }
  }
});

const handleCardRating = async (rating: Rating) => {
  if (!activeCard.value) return;
  
  // Stop any playing audio
  stopAudio();
  
  // Clear key pressed state when rating a card
  keyLastPressed.value = {};
  // We don't reset keyActionPerformed here because we need it to persist until keyup
  
  const reviewDuration = Math.round((Date.now() - reviewStartTime.value) / 1000);
  
  // Play correct sound if rating is 3 (correct)
  if (rating === 3) {
    playCorrectSound();
  }
  doneReviewsToday.value += 1;


  let simulatedNextReviewMins = 1000;

  let newStep = activeCard.value.card.step;
  let newState = activeCard.value.card.state;
  if (activeCard.value.card.state === State.LEARNING) {
    if (rating <= 1) {
      newStep = 0;
    } else {
      newStep = newStep ? newStep + 1 : 1;
    }
    if (newStep == 0) {
      simulatedNextReviewMins = 1;
    } else if (newStep == 1) {
      simulatedNextReviewMins = 10;
    } else {
      // Graduated
      newState = State.REVIEW;
      newStep = null;
      simulatedNextReviewMins = 1000;
    }
  } else if (activeCard.value.card.state === State.REVIEW) {
    if (rating <= 1) {
      newState = State.RELEARNING;
      newStep = 0;
      simulatedNextReviewMins = 10;
    } else {
      newStep = null;
      simulatedNextReviewMins = 1000;
    }
  } else if (activeCard.value.card.state === State.RELEARNING) {
    if (rating <= 1) {
      newStep = 0;
      simulatedNextReviewMins = 10;
    } else {
      // Graduated
      newStep = null;
      newState = State.REVIEW;
      simulatedNextReviewMins = 1000;
    }
  }
  
  API.rateCard(activeCard.value.card.id, rating, reviewDuration, rateCardResult).then(() => {
    // Replace the card in the list with the updated card from the response
    if (rateCardResult.data.value && rateCardResult.data.value.card && dueCardsResult.data.value) {
      const updatedCard = rateCardResult.data.value.card;
      const cardIndex = dueCardsResult.data.value.cards.findIndex(card => card.id === updatedCard.id);
      
      if (cardIndex !== -1 && updatedCard.lastReview && dueCardsResult.data.value && 
          dueCardsResult.data.value.cards[cardIndex]?.lastReview && 
          updatedCard.lastReview > (dueCardsResult.data.value.cards[cardIndex]?.lastReview || 0)) {
        // Replace the old card with the updated one
        dueCardsResult.data.value.cards[cardIndex] = updatedCard;
      }
    }
  });
  if (activeCard.value) {
    const cardIndex = dueCardsResult.data.value?.cards.findIndex(card => card.id === activeCard.value?.card.id);
    if (cardIndex !== -1 && dueCardsResult.data.value?.cards && cardIndex !== undefined) {
      dueCardsResult.data.value.cards[cardIndex].due = (Date.now() + simulatedNextReviewMins * 60 * 1000) / 1000;
      dueCardsResult.data.value.cards[cardIndex].step = newStep;
      dueCardsResult.data.value.cards[cardIndex].state = newState;
    }
    
  }
  frontOfCard.value = true;
  
  // Reset the timer for the next card
  resetReviewTimer();
  
  // Remove automatic audio playing when showing a new card on the front
};

const handleExit = () => {
  // Stop any playing audio before navigating away
  stopAudio();
  // Navigate to home page
  router.push('/');
};

const toggleAudio = (url: string, type: 'word' | 'sentence', isAuto = false) => {
  if (url === '') return;
  
  // Update the auto-play flag based on the isAuto parameter
  if (isAuto) {
    isAutoPlayedAudio.value = true;
  } else if (type === 'word') {
    // Only reset the flag for manual word audio plays
    isAutoPlayedAudio.value = false;
  }
  
  // If we have a current audio and it's the same type
  if (currentAudio.value && currentAudioType.value === type) {
    if (type === 'word' ? isWordAudioPlaying.value : isSentenceAudioPlaying.value) {
      // Pause the audio
      currentAudio.value.pause();
      if (type === 'word') {
        isWordAudioPlaying.value = false;
      } else {
        isSentenceAudioPlaying.value = false;
      }
      currentAudio.value = null;
      currentAudioType.value = null;
    } else {
      // Resume the audio
      try {
        currentAudio.value.play().catch(() => {
          // Handle play() promise rejection (e.g., if user hasn't interacted with the page)
          if (type === 'word') {
            isWordAudioPlaying.value = false;
          } else {
            isSentenceAudioPlaying.value = false;
          }
          currentAudio.value = null;
          currentAudioType.value = null;
        });
        
        if (type === 'word') {
          isWordAudioPlaying.value = true;
        } else {
          isSentenceAudioPlaying.value = true;
        }
      } catch (error) {
        if (type === 'word') {
          isWordAudioPlaying.value = false;
        } else {
          isSentenceAudioPlaying.value = false;
        }
        currentAudio.value = null;
        currentAudioType.value = null;
      }
    }
  } else {
    // Stop any currently playing audio
    stopAudio(false); // Don't reset auto-play flag when stopping audio to play a new one
    
    // Create and play new audio
    const audio = new Audio(url);
    
    // Set up event listeners before playing
    audio.addEventListener('ended', () => {
      if (type === 'word') {
        isWordAudioPlaying.value = false;
      } else {
        isSentenceAudioPlaying.value = false;
      }
      currentAudio.value = null;
      currentAudioType.value = null;
    });
    
    // Handle errors
    audio.addEventListener('error', () => {
      if (type === 'word') {
        isWordAudioPlaying.value = false;
      } else {
        isSentenceAudioPlaying.value = false;
      }
      currentAudio.value = null;
      currentAudioType.value = null;
    });
    
    // Play the audio with error handling
    try {
      currentAudio.value = audio;
      currentAudioType.value = type;
      
      audio.play().catch((error) => {
        if (type === 'word') {
          isWordAudioPlaying.value = false;
        } else {
          isSentenceAudioPlaying.value = false;
        }
        currentAudio.value = null;
        currentAudioType.value = null;
      });
      
      if (type === 'word') {
        isWordAudioPlaying.value = true;
      } else {
        isSentenceAudioPlaying.value = true;
      }
    } catch (error) {
      if (type === 'word') {
        isWordAudioPlaying.value = false;
      } else {
        isSentenceAudioPlaying.value = false;
      }
      currentAudio.value = null;
      currentAudioType.value = null;
    }
  }
};

// Add a watch on activeCard to stop audio when the card changes
watch(() => activeCard.value?.card.id, (newCardId, oldCardId) => {
  if (newCardId !== oldCardId && oldCardId !== undefined) {
    // Stop any playing audio when the card changes
    stopAudio();
  }
});

// Computed property for number of cards left to review
const numReviewsLeft = computed(() => {
  if (!dueCardsResult.data.value || !dueCardsResult.data.value.cards || dueCardsResult.data.value.cards.length === 0) {
    return 0;
  }
  const now = Date.now() + STUDY_AHEAD_TIME_SECS * 1000;
  let count = 0;
  dueCardsResult.data.value.cards.forEach(card => {
    if ((card.due * 1000) < now) {
      // If the card is in learning state (1) and step is 0, count it as 2 cards (itself + 1 additional reviews)
      if (card.state === 1 && card.step === 0) {
        count += 2; // Count +2 to count reviews instead of cards
      } else {
        count += 1;
      }
    }
  });
  return count;
});

// Add a watch on numReviewsLeft to stop audio when there are no more cards to review
watch(numReviewsLeft, (newCount, oldCount) => {
  if (newCount === 0 && oldCount > 0) {
    // Stop any playing audio when there are no more cards to review
    stopAudio();
  }
});

const stopAudio = (resetAutoFlag = true) => {
  if (currentAudio.value) {
    // Pause the audio
    currentAudio.value.pause();
    
    // Remove all event listeners to prevent memory leaks
    currentAudio.value.onended = null;
    currentAudio.value.onerror = null;
    currentAudio.value.onpause = null;
    currentAudio.value.onplay = null;
    
    // Set the src to empty to release resources
    currentAudio.value.src = '';
    
    // Set to null to allow garbage collection
    currentAudio.value = null;
  }
  
  // Reset all audio state flags
  isWordAudioPlaying.value = false;
  isSentenceAudioPlaying.value = false;
  currentAudioType.value = null;
  
  // Only reset the auto-play flag if requested
  if (resetAutoFlag) {
    isAutoPlayedAudio.value = false;
  }
};

// Reset the review timer when a new card is shown
const resetReviewTimer = () => {
  reviewStartTime.value = Date.now();
};

</script>

<template>
  <span class="page-container flex flex-col">
    <!-- Add the colorful background -->
    <div class="background-container">
      <ColorfulBackground :isVisible="true" />
    </div>
    
    <div class="w-full flex justify-between">
      <IssenHeader :showCloseButton="true" :pageStrings="pageStrings" @close="handleExit"
      :getUserResult="getUserResult" :preLogout="()=>{}" />
    </div>
  <!-- <SavedWords :open="savedWordsOpen" @update:open="savedWordsOpen = $event" :pageStrings="pageStrings" :updatedSavedWords="updatedSavedWords" 
  :getWordsResult="getWordsResult.data.value" :loading="getWordsResult.isFetching.value"/> -->
    <div class="flex-1 flex flex-col w-full h-full">
      <div class="flex flex-grow justify-center items-center h-full flex-col">
        <Card class="w-[350px] md:w-[400px] lg:w-[500px] h-[480px] md:h-[550px] lg:h-[650px] border-0 shadow-card-hover rounded-xl">
          <CardContent class="h-full flex flex-col pt-8 pb-4 mb-0">
            <span v-if="getUserResult.isFetching.value || dueCardsResult.isFetching.value" class="flex flex-col items-center justify-center h-full">
                <p class="text-center mb-2 text-lg">{{ pageStrings.loading }}</p>
                <PhCircleNotch class="w-6 h-6 animate-spin"/>
            </span>
            <span v-else class="h-full flex flex-col mb-0">
                <span v-if="!activeCard" class="flex flex-col items-center justify-center h-full text-center">
                  <p class="mb-4 text-xl">{{ pageStrings.noCardsLeft }}</p>
                  <p class="mb-4 text-xl text-[#D96E59]"> {{pageStrings.todaysReviews}} {{ doneReviewsToday }} 🔥</p> 
                  <Button @click="$router.push('/')" variant="default" class="mt-2 text-text-base">{{ pageStrings.gotoHomepage }}</Button>
                </span>
                <div v-else class="w-full h-full flex flex-col">
                    <div v-if="frontOfCard" class="flex flex-col h-full">
                        <div class="flex-grow flex items-center justify-center flex-col">
                            <div class="text-center text-hxl font-normal">
                                <span v-if="activeCard.card.cardType === 'RECOGNITION'" v-html="activeCard.word.word"></span>
                                <span v-else-if="activeCard.card.cardType === 'RECALL' && activeCard.word.shortDefinitionSourceLang.length > 0" v-html="activeCard.word.shortDefinitionSourceLang"></span>
                                <span v-else-if="activeCard.card.cardType === 'RECALL' && activeCard.word.shortDefinitionTargetLang.length > 0" v-html="activeCard.word.shortDefinitionTargetLang"></span>
                                <span v-else>INVALID CARD TYPE CONTACT SUPPORT</span>
                            </div>
                            
                            <!-- Show sentence on front if enabled AND it's a recognition card -->
                            <div v-if="getUserResult.data.value?.user.showSentenceInFront && activeCard.word.sentence && activeCard.card.cardType === 'RECOGNITION'" class="mt-6 mx-4">
                                <p class="text-lg-regular" v-html="convertRubyToRaw(boldedSentence(activeCard.word.sentence, activeCard.word.word))"></p>
                            </div>
                        </div>
                        <div class="flex flex-col justify-center">
                            <Button 
                                @click="flipCard" 
                                variant="default"
                                class="w-full bg-button-secondary"
                            >
                                {{ pageStrings.seeAnswer }}
                            </Button>
                            <!-- Hide on mobile, show on md screens and up -->
                            <div class="text-xs text-gray-500 text-center mt-2 hidden md:block mx-6">
                              <p>{{ pageStrings.pressXorYtoFlip.replace('XXX', 'Space').replace('YYY', '3') }}</p>
                            </div>
                        </div>
                    </div>
                    <div v-else class="h-full flex flex-col mb-0">
                        <div class="overflow-y-auto flex-grow px-2 pt-0">
                            <!-- Word with transliteration -->
                            <div class="mb-4">
                              <span v-if="parsedWordWithTransliteration" class="text-center">

                                <span v-if="parsedWordWithTransliteration[1]" class="flex flex-col items-center justify-center block mt-1">
                                  <span class="text-hxl font-normal mb-1" v-html="parsedWordWithTransliteration[0]"></span>
                                  <span 
                                      @click="toggleAudio(activeCard.word.wordAudioS3Link || '', 'word')" 
                                      class="text-[11px] font-normal text-subtle bg-[#DDEDF9] pr-1 pl-2 py-1 rounded-md inline-flex items-center leading-none cursor-pointer">
                                    {{ parsedWordWithTransliteration[1] }}
                                    <button 
                                      v-if="activeCard.word.wordAudioS3Link"
                                      class="ml-1 flex items-center px-1 py-0.5 bg-transparent text-subtle hover:bg-[#C5DEF2] rounded-md text-xs"
                                    >
                                      <PhPlayCircle v-if="!isWordAudioPlaying" class="w-3 h-3 m-0 p-0" />
                                      <PhPauseCircle v-else class="w-3 h-3 m-0 p-0" />
                                    </button>
                                  </span>
                                </span>
                                <span v-else class="flex flex-row items-center justify-center">
                                  <span class="text-hxl font-normal" v-html="parsedWordWithTransliteration[0]"></span>
                                  <!-- Only show this button if there's no transliteration -->
                                  <button 
                                  id="largePlayButton"
                                    v-if="!parsedWordWithTransliteration[1] && activeCard.word.wordAudioS3Link"
                                    @click="toggleAudio(activeCard.word.wordAudioS3Link, 'word')" 
                                    class="ml-0 flex items-center px-2 py-1 bg-primary text-white rounded-md hover:bg-primary-hover text-lg"
                                  >
                                    <PhPlayCircle v-if="!isWordAudioPlaying" class="w-6 h-6 mr-1 text-black" />
                                    <PhPauseCircle v-else class="w-6 h-6 mr-1 text-black" />
                                  </button>
                                </span>
                              </span>

                              <span v-else class="text-xl font-medium text-center block">
                                {{ activeCard.word.word }}
                              </span>
                            </div>

                            <Separator class="w-full mb-4 bg-gray-200"/>
                            
                            <!-- Definitions -->
                            <div v-if="(definitionInSourceLang && activeCard.word.shortDefinitionSourceLang) || activeCard.word.shortDefinitionTargetLang.length == 0">
                              <!-- Short definition centered and larger -->
                              <p class="text-[2rem] mb-4 text-center font-normal text-[#D96E59]" v-if="activeCard.word.shortDefinitionSourceLang">{{ activeCard.word.shortDefinitionSourceLang.toLowerCase() }}</p>
                              
                              <!-- Sentence with audio - moved above long definition -->
                              <div v-if="activeCard.word.sentence" class="mb-4">
                                <div class="flex items-center mb-0">
                                  <p class="text-md font-bold">{{ pageStrings.sentence }}</p>
                                  
                                  <!-- Sentence Audio Button -->
                                  <button 
                                    v-if="activeCard.word.sentenceAudioS3Link"
                                    @click="toggleAudio(activeCard.word.sentenceAudioS3Link, 'sentence')" 
                                    class="ml-0 flex items-center px-2 py-1 bg-primary text-white rounded-md hover:bg-primary-hover text-md"
                                  >
                                    <PhPlayCircle v-if="!isSentenceAudioPlaying" class="w-5 h-5 mr-1 text-black" />
                                    <PhPauseCircle v-else class="w-5 h-5 mr-1 text-black" />
                                  </button>
                                </div>
                                <p class="text-md" v-html="boldedSentence(activeCard.word.sentence, activeCard.word.word)"></p>
                              </div>
                              
                              <!-- Long definition hidden behind button -->
                              <div v-if="activeCard.word.longDefinitionSourceLang" class="mt-2">
                                <p 
                                  @click="showLongDefinition = !showLongDefinition" 
                                  class="text-center text-primary cursor-pointer hover:underline text-md text-text-muted"
                                >
                                  {{ showLongDefinition ? (pageStrings.hideLongDefinition ) : (pageStrings.seeLongDefinition) }}
                                </p>
                                <div v-if="showLongDefinition" class="mt-2">
                                  <p class="text-md mb-1 font-bold">{{ pageStrings.longDefinition }}</p>
                                  <p class="text-md mb-3">{{ activeCard.word.longDefinitionSourceLang }}</p>
                                </div>
                              </div>
                            </div>
                            <div v-else>
                              <!-- Short definition centered and larger -->
                              <p class="text-[2rem] mb-4 text-center font-normal text-[#D96E59]" v-if="activeCard.word.shortDefinitionTargetLang.length <= 20">{{ activeCard.word.shortDefinitionTargetLang.toLowerCase() }}</p>
                              <p class="text-xl mb-4 text-center font-normal text-[#D96E59]" v-else>{{ activeCard.word.shortDefinitionTargetLang.toLowerCase() }}</p>
                              
                              <!-- Sentence with audio - moved above long definition -->
                              <div v-if="activeCard.word.sentence" class="mb-4">
                                <div class="flex items-center mb-0">
                                  <p class="text-md font-bold">{{ pageStrings.sentence }}</p>
                                  
                                  <!-- Sentence Audio Button -->
                                  <button 
                                    v-if="activeCard.word.sentenceAudioS3Link"
                                    @click="toggleAudio(activeCard.word.sentenceAudioS3Link, 'sentence')" 
                                    class="ml-0 flex items-center px-2 py-1 bg-primary text-white rounded-md hover:bg-primary-hover text-md"
                                  >
                                    <PhPlayCircle v-if="!isSentenceAudioPlaying" class="w-5 h-5 mr-1 text-black" />
                                    <PhPauseCircle v-else class="w-5 h-5 mr-1 text-black" />
                                  </button>
                                </div>
                                <p class="text-md" v-html="boldedSentence(activeCard.word.sentence, activeCard.word.word)"></p>
                              </div>
                              <!-- Long definition hidden behind button -->
                              <div v-if="activeCard.word.longDefinitionTargetLang" class="mt-2">
                                <p 
                                  @click="showLongDefinition = !showLongDefinition" 
                                  class="text-center text-primary cursor-pointer hover:underline text-md text-text-muted"
                                >
                                  {{ showLongDefinition ? (pageStrings.hideLongDefinition ) : (pageStrings.seeLongDefinition) }}
                                </p>
                                <div v-if="showLongDefinition" class="mt-2">
                                  <p class="text-md mb-1 font-bold">{{ pageStrings.longDefinition }}</p>
                                  <p class="text-md mb-3">{{ activeCard.word.longDefinitionTargetLang }}</p>
                                </div>
                              </div>


                            </div>
                        </div>
                        
                        <!-- Rating buttons with hints underneath each button -->
                        <div class="flex justify-between mt-4 mb-0">
                            <div class="w-[48%] flex flex-col">
                                <Button 
                                    @click="handleCardRating(1)" 
                                    variant="default"
                                    class="w-full text-text-base mb-1 bg-button-secondary"
                                >
                                    <PhX class="w-5 h-5 mr-1" />
                                    {{ pageStrings.wrong }}
                                </Button>
                                <!-- Hide on mobile, show on md screens and up -->
                                <div class="text-xs text-gray-500 text-center mt-1 hidden md:block mx-6">
                                  <p>{{ pageStrings.pressXXX.replace('XXX', '1') }}</p>
                                </div>
                            </div>
                            <div class="w-[48%] flex flex-col">
                                <Button 
                                    @click="handleCardRating(3)" 
                                    variant="default"
                                    class="w-full text-lg font-medium text-text-base mb-1 bg-button-secondary"
                                >
                                    <PhCheck class="w-5 h-5 mr-1" />
                                    {{ pageStrings.correct }}
                                </Button>
                                <!-- Hide on mobile, show on md screens and up -->
                                <div class="text-xs text-gray-500 text-center mt-1 hidden md:block mx-6">
                                  <p>{{ pageStrings.pressXXX.replace('XXX', '3') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </span>
          </CardContent>

        </Card>
        
        <!-- Add links below the card -->
        <div class="flex flex-col justify-center items-center mt-4" style="width: inherit; max-width: inherit;">
          <!-- Progress bar -->
          <div class="progress-bar-container mb-2 w-[296px] md:w-[346px] lg:w-[448px] border-0 shadow-card-hover">
            <div class="progress-bar border-0 shadow-card-hover" :style="{ width: progressPercentage + '%' }"></div>
          </div>
          <div class="flex justify-center items-center space-x-4 hidden md:flex">
            <a @click="handleExit" class="text-lg text-gray-500 hover:underline cursor-pointer">{{ pageStrings.exitFlashcards }}</a>
          </div>
        </div>
      </div>
    </div>
  </span>
</template>

<style scoped>
.background-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.progress-bar-container {
  height: 0.5rem;
  background-color: #FFF0ED; /* Light pink background */
  border-radius: 0.25rem;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background-color: #D96E59; /* Red-orange color */
  border-radius: 0.25rem;
  transition: width 0.3s ease;
}
</style>

