<script setup lang="ts">
import router from "@/router";
import { PhSpinner } from '@phosphor-icons/vue';
import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import { Input } from "@/components/ui/input";
import IssenHeader from "@/practice/IssenHeader.vue";
import { Label } from "@/components/ui/label"
import { ref, type Ref } from "vue";
import { getMessagesFromLanguage } from "@/languages/messages";
import { getDefaultSourceLanguage } from "@/practice/util";
import { onMounted } from 'vue';
import { apiRes, type GetUserResponse, type LogoutResponse, PracticeAPI, type ResetPasswordResponse } from "@/practice/api";
import { protocol, domainPort } from "@/url";
  import { useRoute } from 'vue-router';
const sourceLanguage = ref(getDefaultSourceLanguage());
const pageStrings= ref(getMessagesFromLanguage(sourceLanguage.value));
const API: PracticeAPI = new PracticeAPI(protocol + domainPort);
const logoutResult = apiRes<LogoutResponse>();
const getUserResult = apiRes<GetUserResponse>();
const error: Ref<string> = ref("");
const route = useRoute();
const email = ref("");
const resetPasswordResult = apiRes<ResetPasswordResponse>();

onMounted(async () => {
    await API.getUser(getUserResult);
    if (getUserResult.data.value) {
      await API.logout(logoutResult);
    }
    email.value = route.query.email as string || "";
  }
)

async function submitResetRequest() {
    if (email.value === "") {
      error.value = pageStrings.value.enterEmail;
      return;
    }
    await API.resetPassword(email.value, resetPasswordResult);
    router.push({ path: "/confirmation-code", query: { email: email.value } })
}
function back() {
  router.push("/email");
}

</script>

<template>
  <span class="page-container flex flex-col">
    <div class="w-full flex justify-between">
      <IssenHeader :pageStrings="pageStrings" :getUserResult="getUserResult" :preLogout="() => {}" :postLogout="() => {}"/>
    </div>
    <div class="flex-1 flex flex-col w-full">
      <div class="flex flex-grow justify-center items-center">
        <Card class="w-[350px] bg-bg-base shadow-card-rest">
          <CardHeader class="mb-0">
            <CardTitle class="text-xl mb-0">
              {{ pageStrings.resetPassword }}</CardTitle>
          </CardHeader>
          <CardContent>
            <div v-if="error" class="text-red-500 font-bold mb-2 mt-0">{{ error }}</div>
            <div class="grid items-center w-full gap-4">
              <Label class="text-md">{{ pageStrings.email }} / {{ pageStrings.username }}</Label>
              <div class="flex flex-col space-y-1.5">
                <Input type="text" v-model="email" :placeholder="email ? email : 'taylor@example.com'" ref="emailInput"
                  autofocus
                  :disabled="resetPasswordResult.isFetching.value"
                  @keyup.enter="submitResetRequest" />
              </div>
            </div>
          </CardContent>
          <CardFooter class="flex px-6 pb-6 mt-2">
            <Button @click="submitResetRequest" variant="primary" :disabled="resetPasswordResult.isFetching.value">
              <PhSpinner v-if="resetPasswordResult.isFetching.value" class="w-4 h-4 mr-2 animate-spin text-white" />{{ pageStrings.resetPassword }}
            </Button>
            <Button class="ml-2" variant="ghost" @click="back">
                {{ pageStrings.back }}
            </Button>
          </CardFooter>
        </Card>
      </div>
    </div>
  </span>
</template>
