<script setup lang="ts">
import { useRouter } from 'vue-router';
import SettingsDropdown from "@/practice/SettingsDropdown.vue";
import type { GetUserResponse } from '@/practice/api';
import type { Ref } from 'vue';
import { PhX } from '@phosphor-icons/vue';
import ColorfulBackground from "@/practice/ColorfulBackground.vue";
const props = defineProps<{
  pageStrings: { [key: string]: string },
  getUserResult: {
    data: Ref<GetUserResponse | null>;
    isFetching: Ref<boolean>;
    error: Ref<number | null>;
  };
  preLogout: () => void;
  postLogout?: () => void;
  clickSavedWordsProp?: () => void;
  showCloseButton?: boolean;
}>();
const emit = defineEmits<{
  close: [];
}>();

// Function to handle close button click
function clickCloseButton() {
  emit('close');
}

const router = useRouter();

function goToHome() {
  router.push("/");
}

function clickSavedWords() {
  if (router.currentRoute.value.path !== '/chat' && router.currentRoute.value.path !== '/') {
    router.push('/chat?savedWords=true');
  } else {
    props.clickSavedWordsProp?.();
  }
} 
</script>

<template>
    <div class="background-container" >
      <ColorfulBackground :isVisible="true" />
    </div>
    <div class="w-full flex justify-between px-4 pt-4 md:px-10 md:pt-10 mb-4 relative z-[15]">

        <!-- Left section: Close button on mobile / Logo on md and larger screens -->
        <div class="flex items-start">
          <div v-if="showCloseButton" class="md:hidden mt-2">
            <PhX class="h-8 w-8 cursor-pointer" @click="$emit('close')" />
          </div>
          <!-- Logo hidden on mobile, visible on md and larger screens -->
          <div class="hidden md:block">
            <img src="/assets/logo-black.png" alt="issen logo" class="mt-2 h-10 cursor-pointer" @click="goToHome">
          </div>
        </div>
        
        <!-- Center section: Logo centered on mobile only, hidden on md and larger screens -->
        <div class="absolute left-1/2 transform -translate-x-1/2 md:hidden">
          <img src="/assets/logo-black.png" alt="issen logo" class="mt-2 h-7 cursor-pointer" @click="goToHome">
        </div>
        
        <!-- Right section: Settings dropdown -->
        <div class="flex items-center">
          <SettingsDropdown :pageStrings="pageStrings" :getUserResult="getUserResult" :preLogout="preLogout"
          :postLogout="postLogout" :clickSavedWords="clickSavedWords" />
        </div>
    </div>
</template>


<style scoped>
/* Component styles go here */
</style>

