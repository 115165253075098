<script setup lang="ts">
import router from "@/router";
import { PhSpinner } from '@phosphor-icons/vue';
import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import IssenHeader from "@/practice/IssenHeader.vue";
import { ref, watch, type Ref } from "vue";
import { getMessagesFromLanguage } from "@/languages/messages";
import { getDefaultSourceLanguage } from "@/practice/util";
import { onMounted } from 'vue';
import { apiRes, type GetUserResponse, type LoginResponse, type LogoutResponse, PracticeAPI } from "@/practice/api";
import { protocol, domainPort } from "@/url";
  import { useRoute } from 'vue-router';
const sourceLanguage = ref(getDefaultSourceLanguage());
const pageStrings= ref(getMessagesFromLanguage(sourceLanguage.value));
const API: PracticeAPI = new PracticeAPI(protocol + domainPort);
const logoutResult = apiRes<LogoutResponse>();
const getUserResult = apiRes<GetUserResponse>();
const error: Ref<string> = ref("");
const route = useRoute();
const email = ref("");
import {
  PinInput,
  PinInputGroup,
  PinInputInput,
} from '@/components/ui/pin-input' // @ts-ignore

const pinValue = ref<string[]>([])
const loginWithCodeResult = apiRes<LoginResponse>();


const handleComplete = async (e: string[]) => {
  console.log("Handle complete");
  if (e.length !== 5) {
    error.value = pageStrings.value.enterCode;
    return;
  }
  await API.loginWithCode(email.value, e.join(""), loginWithCodeResult);

    console.log("Login with code result", loginWithCodeResult.data.value);
  if (loginWithCodeResult.data.value && loginWithCodeResult.data.value.success) {
    router.push("/manage-account?resetPassword=true");
  } else {
    if (loginWithCodeResult.data.value && loginWithCodeResult.data.value.message) {
      console.log("Login with code result message", loginWithCodeResult.data.value.message);
      error.value = pageStrings.value.invalidCodeOrExpired;
      pinValue.value = [];
    } else {
      error.value = pageStrings.value.serverError;
    }
  }
}

onMounted(async () => {
  await API.logout(logoutResult);
  email.value = route.query.email as string || "";
  if (route.query.code) {
    pinValue.value = (route.query.code as string).split("");
  }
})

function back() {
  router.push("/email");
}

</script>

<template>
  <span class="page-container flex flex-col">
    <div class="w-full flex justify-between">
      <IssenHeader :pageStrings="pageStrings" :getUserResult="getUserResult" :preLogout="() => {}" :postLogout="() => {}"/>
    </div>
    <div class="flex-1 flex flex-col w-full">
      <div class="flex flex-grow justify-center items-center">
        <Card class="w-[350px] bg-bg-base shadow-card-rest">
          <CardHeader class="mb-0">
            <CardTitle class="text-xl mb-0">
              {{ pageStrings.resetPassword }}</CardTitle>
          </CardHeader>
          <CardContent>
            <div v-if="error" class="text-red-500 font-bold mb-2 mt-0">{{ error }}</div>
            <div class="text-center mb-4 mt-0">{{ pageStrings.weHaveSentCode }}</div>
              <PinInput
                id="pin-input"
                v-model="pinValue"
                placeholder="○"
                :disabled="loginWithCodeResult.isFetching.value"
                @complete="handleComplete"
                :otp="true"
                class="flex justify-center mt-2"
              >
          <PinInputGroup class="flex justify-center">
            <PinInputInput
              v-for="(id, index) in 5"
              :key="id"
              :index="index"
            />
          </PinInputGroup>
        </PinInput>
          </CardContent>
          <CardFooter class="flex px-6 pb-6 mt-2">
            <Button @click="handleComplete" variant="primary" :disabled="loginWithCodeResult.isFetching.value">
              <PhSpinner v-if=loginWithCodeResult.isFetching.value class="w-4 h-4 mr-2 animate-spin text-white"/> {{ pageStrings.enterCode }}
            </Button>
            <Button class="ml-2" variant="ghost" @click="back">
                {{ pageStrings.back }}
            </Button>
          </CardFooter>
        </Card>
      </div>
    </div>
  </span>
</template>
