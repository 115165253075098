<script setup lang="ts">
import router from "@/router";

import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import { Input } from "@/components/ui/input";
import IssenHeader from "@/practice/IssenHeader.vue";
import { Label } from "@/components/ui/label"
import { ref, type Ref } from "vue";
import { getMessagesFromLanguage } from "@/languages/messages";
import { getDefaultSourceLanguage } from "@/practice/util";
import { onMounted } from 'vue';
import { apiRes, type CheckAllowlistResponse, type CheckUsernameResponse, type CreateUserResponse, type GetUserResponse, PracticeAPI, type SubscribeResponse, type UpdateUserResponse } from "@/practice/api";
import { protocol, domainPort } from "@/url";
import { UserStatus } from "@/types/model";
  import { useRoute } from 'vue-router';
import { sha256 } from "@/practice/crypto";
import { Checkbox } from '@/components/ui/checkbox'
import { aggregationFns } from "@tanstack/vue-table";
import { priceId } from "@/practice/subscription";


const guiLanguage = ref(getDefaultSourceLanguage());
const pageStrings= ref(getMessagesFromLanguage(guiLanguage.value));
const password1 = ref("");
const password2 = ref("");
const name = ref(""); 
const isFocused0: Ref<boolean> = ref(false);
const isFocused1: Ref<boolean> = ref(false);
const isFocused2: Ref<boolean> = ref(false);

const API: PracticeAPI = new PracticeAPI(protocol + domainPort);
const updateUserResult = apiRes<UpdateUserResponse>();
const getUserResult = apiRes<GetUserResponse>();
const error: Ref<string> = ref("");
const route = useRoute();
const agreeToTerms = ref(false);
const email = ref("");
let priceIdOverride: string = priceId;
const subscribeResult = apiRes<SubscribeResponse>();

// TRIAL / STANDARD / SUBSCRIBED
// email / no email
// ?subscribe or not


// 1-8. TRIAL -> /subscribe
// No email not possible, only possible for TRIAL
// 10. STANDARD, email, ?subscribe
// 12. STANDARD, email, no ?subscribe
// 18. SUBSCRIBED, email, ?subscribe  
// 20. SUBSCRIBED, email, no ?subscribe


// 25 not logged in -> email



onMounted(async () => {
    await API.getUser(getUserResult);
    if (getUserResult.data.value && getUserResult.data.value.user.guiLang) {
      guiLanguage.value = getUserResult.data.value.user.guiLang;
      pageStrings.value = getMessagesFromLanguage(guiLanguage.value);
    }
    email.value = route.query.email as string || "";
    if (!getUserResult.data.value) {
      router.push("/email");
      return;
    }

    if (!getUserResult.data.value.user.name.startsWith('temp_') && !getUserResult.data.value.user.name.includes('@')) {
      name.value = getUserResult.data.value.user.name;
    }
    email.value = getUserResult.data.value.user.username;
    if (name.value === email.value) {
      name.value = "";
    }

    setTimeout(() => {
      if (name.value !== "") {
        isFocused1.value = true;
      } else {
        isFocused0.value = true;
      }
    }, 50);
  }
)
async function subscribe() {
  await API.subscribe(priceIdOverride, subscribeResult);
    if (subscribeResult.data.value) {
      window.location.href = subscribeResult.data.value.url;
    } else {
      console.log("No url in subscribeResult");
    }
}


async function submitPassword() {
  if (password1.value !== password2.value) {
    error.value = pageStrings.value.passwordsDoNotMatch;
    return;
  }
  if (password1.value === "password") {
    error.value = pageStrings.value.invalidPassword;
    return;
  }
  if (!agreeToTerms.value) {
    error.value = pageStrings.value.mustAgreeToTerms;
    return;
  }
  if (name.value === "") {
    error.value = pageStrings.value.enterName;
    return;
  }
  if (email.value === "" && !route.query.email) {
    error.value = pageStrings.value.enterEmail;
    return;
  }
  const hashedPass = await sha256(password1.value);
  //@ts-ignore

  await API.update_user(updateUserResult, {name: name.value, password: hashedPass});
  if (updateUserResult.data.value) {

    if (route.query.subscribe === "true") {
      await subscribe();
      return;
    } else if (route.query.subscribed === "true") {
      router.push("/subscribed");
    } else {
      router.push("/onboarding");
    }
  } else {
    error.value = pageStrings.value.failedToUpdateUser;
  }
}

</script>

<template>
  <span class="page-container flex flex-col">
    <div class="w-full flex justify-between">
      <IssenHeader :pageStrings="pageStrings" :getUserResult="getUserResult" :preLogout="() => {}" :postLogout="() => {}"/>
    </div>
    <div class="flex-1 flex flex-col w-full">
      <div class="flex flex-grow justify-center items-center">
        <Card class="w-[350px] bg-bg-base shadow-card-rest">
          <CardHeader>
            <CardTitle class="text-green-700">
            {{ pageStrings.waitlist }}
            </CardTitle>
            <CardTitle class="text-xl">
              {{ pageStrings.manageAccount}}</CardTitle>
          </CardHeader>
          <CardContent>
            <div v-if="error" class="text-red-500 font-bold mb-2">{{ error }}</div>
              <div class="grid items-center w-full gap-4">
            <p mb-2> {{ pageStrings.email}}: {{ email }}</p>
               <div class="flex flex-col space-y-1.5">
                  <Label class="text-md">{{ pageStrings.name }}</Label>
                  <Input type="text" v-model="name"  placeholder="name"
                  autofocus
                   :focus="isFocused0"
                   @focus="isFocused0=true"
                   @blur="isFocused0=false"
                   />
                </div>
                <div class="flex flex-col space-y-1.5">

                  <Label class="text-md">{{ pageStrings.password }}</Label>
                  <Input type="password" v-model="password1"  placeholder="password"
                   :focus="isFocused1"
                   @focus="isFocused1=true"
                   @blur="isFocused1=false"
                   />
                </div>
                <div class="flex flex-col space-y-1.5">
                  <Input type="password" v-model="password2"  placeholder="confirm password"
                  :focus="isFocused2"
                  @blur="isFocused2=false" 
                  @focus="isFocused2=true"
                   @keyup.enter="submitPassword" />
                </div>
                <div class="items-top flex gap-x-2 mt-2">
                  <Checkbox id="terms1" :checked="agreeToTerms" @update:checked="agreeToTerms=!agreeToTerms" />
                  <div class="grid gap-1.5 leading-none">
                    <label
                      for="terms1"
                      class="text-md font-medium peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      <!-- You agree to our Terms of Service and Privacy Policy. -->
                      {{ pageStrings.tos1 }} <a class="cursor-pointer text-blue-500" href="https://issen.com/terms-of-use">{{ pageStrings.tos2 }}</a>
                       {{ pageStrings.tos3 }} <a class="cursor-pointer text-blue-500" href="https://issen.com/privacy-policy">{{ pageStrings.tos4 }}</a> {{ pageStrings.tos5 }}
                    </label>
                  </div>
                </div>
            </div>
          </CardContent>
          <CardFooter class="flex justify-between px-6 pb-6">
            <Button @click="submitPassword" class="buttonDefault">
                {{ pageStrings.continue }}
            </Button>
          </CardFooter>
        </Card>
      </div>
    </div>
  </span>
</template>
