<script setup lang="ts">
import type { HTMLAttributes } from 'vue'
import { cn } from '@/practice/util'
import { useVModel } from '@vueuse/core'
import { ref, watch } from 'vue'

const props = defineProps<{
  defaultValue?: string | number
  modelValue?: string | number
  class?: HTMLAttributes['class']
  focus?: boolean
}>()

const emits = defineEmits<{
  (e: 'update:modelValue', payload: string | number): void
  (e: 'blur'): void
  (e: 'focus'): void
}>()

const modelValue = useVModel(props, 'modelValue', emits, {
  passive: true,
  defaultValue: props.defaultValue,
})

const grandChildRef = ref<HTMLInputElement | null>(null)

const focusOnGrandChild = () => {
  if (grandChildRef.value) {
    grandChildRef.value.focus()
  }
}

watch(() => props.focus, (newVal) => {
  if (newVal) {
    focusOnGrandChild()
  }
})

const handleBlur = () => {
  emits('blur')
}

const handleFocus = () => {
  emits('focus')
}

</script>

<template>
  <input
    ref="grandChildRef"
    v-model="modelValue"
    @blur="handleBlur"
    @focus="handleFocus"
    :class="cn('flex h-[2.87rem] text-lg text-text-subtle rounded-sm border border-input bg-base px-[0.875rem] py-3 shadow-xs transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50', props.class)"
  >
</template>
