import '@/assets/index.css'

import { createApp } from 'vue'
import App from '@/App.vue'
import router from "@/router";

import { initializeApp } from "firebase/app";
import { getAnalytics, type Analytics } from "firebase/analytics";
import { buildEnv } from './url'
const firebaseConfig = {
  apiKey: "AIzaSyC3bPkkeqAvUb-xxVn-UtagypNm0JSL-as",
  authDomain: "issen-appl.firebaseapp.com",
  projectId: "issen-appl",
  storageBucket: "issen-appl.firebasestorage.app",
  messagingSenderId: "307971587661",
  appId: "1:307971587661:web:1d8ca2e4a46575a3b3e604",
  measurementId: "G-FXGWW6G15V"
};

// Initialize Firebase
let analytics: Analytics | null = null;
if (buildEnv === 'production') {
  const firebaseApp = initializeApp(firebaseConfig);
  analytics = getAnalytics(firebaseApp);

  // @ts-ignore
  window.gtag('config', 'G-FXGWW6G15V', {
    'linker': {
      'accept_incoming': true
    }
  })

}

const app = createApp(App)
app.use(router)
app.config.globalProperties.$analytics = analytics
app.provide('analytics', analytics)
app.mount('#app')

