<script setup lang="ts">
import { computed, ref, h, defineComponent, watch } from 'vue';
import type { HistoricalStats, StatsRecord } from '@/types/model';
import { BarChart } from '@/components/ui/chart-bar';
import { DialogClose } from '@/components/ui/dialog';
import { PhX } from '@phosphor-icons/vue';

interface TooltipItem {
  name: string;
  value: number | string;
  color: string;
  callsLabel?: string;
  cardReviewsLabel?: string;

  [key: string]: any; // Allow dynamic property names
}

// Custom tooltip component with minimal structure to avoid double borders
const CustomTooltip = defineComponent({
  props: {
    title: String,
    data: Array,
    hoursSymbol: String,
    minutesSymbol: String
  },
  setup(props: { title?: string; data?: TooltipItem[]; hoursSymbol: string; minutesSymbol: string }) {
    // Format the value for display in tooltip
    const formatTooltipValue = (item: TooltipItem) => {
      // Debug the tooltip item
      console.log("Tooltip item:", item.name, item.value);
      
      // Debug chart type from context
      console.log("Chart type context:", chartType.value);
      
      // In review mode, never format as time
      if (chartType.value === 'review') {
        return item.value;
      }
      
      // In study time mode, format as time
      if (chartType.value === 'studyTime' && typeof item.value === 'number') {
        const totalMinutes = Math.round(item.value / 60);
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        
        if (hours > 0) {
          return `${hours}${props.hoursSymbol} ${minutes}${props.minutesSymbol}`;
        } else {
          return `${minutes}${props.minutesSymbol}`;
        }
      }
      
      // Default case
      return item.value;
    };
    
    return { formatTooltipValue };
  },
  render() {
    // Ultra simple raw HTML - no styling, borders, or shadows
    const rows = (this.data || []).map((item: TooltipItem, key: number) => {
      return `
        <div style="display:flex;justify-content:space-between;padding:2px 0">
          <div style="display:flex;align-items:center">
            <span style="width:8px;height:8px;margin-right:5px;border-radius:50%;background-color:${item.color}"></span>
            <span>${item.name}</span>
          </div>
          <span style="font-weight:500;margin-left:12px">${this.formatTooltipValue(item)}</span>
        </div>
      `;
    }).join('');
    
    return h('div', { 
      innerHTML: `
        <div style="font-size:12px;line-height:1.4;color:#333;padding:0">
          ${this.title ? `<div style="padding:4px 0;font-weight:500">${this.title}</div>` : ''}
          <div style="min-width:160px">
            ${rows}
          </div>
        </div>
      `
    });
  }
});

const props = defineProps<{
  pageStrings: { [key: string]: string };
  historicalStats: HistoricalStats | null;
}>();

// Time period selection
const timePeriod = ref<'daily' | 'weekly' | 'monthly'>('daily');

// Chart type selection
const chartType = ref<'review' | 'studyTime'>('studyTime');

// Available time periods
const timePeriods = ['monthly', 'weekly', 'daily'] as const;
type TimePeriod = typeof timePeriods[number];

// Get display name for time period
const getTimePeriodDisplayName = (period: TimePeriod): string => {
  // Use pageStrings
  if (period === 'monthly') {
    return props.pageStrings.monthly;
  } else if (period === 'weekly') {
    return props.pageStrings.weekly;
  } else {
    return props.pageStrings.daily;
  }
};

// Get chart type colors
const chartColors = computed(() => {
  // Use a single color for review chart, two colors for study time chart
  return chartType.value === 'review' 
    ? ['#E76E50'] // Single color for review counts
    : ['#737BCB', '#E76E50']; // Colors for call time and flashcard time
});

// Format time in seconds to hours and minutes
const formatTimeHoursMinutes = (seconds: number): string => {
  const totalMinutes = Math.round(seconds / 60);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  
  if (hours > 0) {
    return `${hours}${props.pageStrings.h} ${minutes}${props.pageStrings.m}`;
  } else {
    return `${minutes}${props.pageStrings.m}`;
  }
};

// Format date key based on time period
const formatDateKey = (key: string, period: 'daily' | 'weekly' | 'monthly'): string => {
  if (period === 'daily') {
    // Input: 2024-10-21, Output: Oct 21
    const [year, month, day] = key.split('-');
    const date = new Date(Number(year), Number(month) - 1, Number(day));
    const monthNumber = date.getMonth(); // 0-11
    const monthNames = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
    const monthKey = monthNames[monthNumber];
    const monthDisplay = props.pageStrings[monthKey] || monthKey;
    return `${monthDisplay} ${date.getDate()}`;
  } else if (period === 'weekly') {
    // Input: 2024-43, Output: Oct 15 (show month + day of the first day of the week)
    const [year, week] = key.split('-');
    // Calculate the date of the first day of this week
    // January 4th is always in week 1 per ISO 8601
    const firstDayOfYear = new Date(Number(year), 0, 4);
    const dayOfWeek = firstDayOfYear.getDay() || 7; // Get day of week, make Sunday 7 instead of 0
    const firstMondayOfYear = new Date(firstDayOfYear);
    firstMondayOfYear.setDate(firstDayOfYear.getDate() - dayOfWeek + 1);
    
    // Add the weeks
    const targetDate = new Date(firstMondayOfYear);
    targetDate.setDate(firstMondayOfYear.getDate() + (Number(week) - 1) * 7);
    
    const monthNumber = targetDate.getMonth(); // 0-11
    const monthNames = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
    const monthKey = monthNames[monthNumber];
    const monthDisplay = props.pageStrings[monthKey] || monthKey;
    return `${monthDisplay} ${targetDate.getDate()}`;
  } else {
    // Input: 2024-10, Output: Oct (shorter for better x-axis spacing)
    const [year, month] = key.split('-');
    const monthNumber = Number(month);
    const monthNames = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
    const monthKey = monthNames[monthNumber - 1];
    return props.pageStrings[monthKey] || monthKey;
  }
};


// Log for debugging
const logDataOnChartTypeChange = () => {
  console.log("Chart type:", chartType.value);
  console.log("Chart categories:", chartCategories.value);
  console.log("Chart data sample:", chartData.value[0] || {});
};

// Watch for chart type changes
watch(chartType, () => {
  logDataOnChartTypeChange();
});

// Get data for the chart based on selected time period and chart type
const chartData = computed(() => {
  if (!props.historicalStats) return [];
  
  const periodData = props.historicalStats[timePeriod.value];
  if (!periodData) return [];
  
  // Get last 10 entries
  const keys = Object.keys(periodData).sort();
  const lastTenKeys = keys.slice(-10);
  
  // Get localized category names
  const callsLabel = props.pageStrings.calls;
  const cardReviewsLabel = props.pageStrings.cardsReviewed;
  
  return lastTenKeys.map((key, index) => {
    const record: StatsRecord = periodData[key] || {};
    const displayName = formatDateKey(key, timePeriod.value);
    
    if (chartType.value === 'review') {
      // For review mode, explicitly set 0 for review count if not present
      return {
        name: displayName,
        [cardReviewsLabel]: record.review_count !== undefined ? record.review_count : 0,
      };
    } else {
      // For study time mode, explicitly set 0 for both values if not present
      return {
        name: displayName,
        [callsLabel]: record.call_seconds !== undefined ? record.call_seconds : 0,
        [cardReviewsLabel]: record.flashcard_seconds !== undefined ? record.flashcard_seconds : 0,
      };
    }
  });
});

// Get chart categories based on chart type
const chartCategories = computed(() => {
  // Get localized category names
  const callsLabel = props.pageStrings.calls;
  const cardReviewsLabel = props.pageStrings.cardsReviewed;
  
  if (chartType.value === 'review') {
    // For review chart, only use cardsReviewed as the category
    return [cardReviewsLabel] as any[];
  } else {
    // For study time chart, use both categories
    return [callsLabel, cardReviewsLabel] as any[];
  }
});

// Get Y-axis formatter based on chart type
const yFormatter = computed(() => {
  if (chartType.value === 'review') {
    return (tick: any) => {
      return typeof tick === 'number' ? tick.toString() : '';
    };
  } else {
    return (tick: any) => {
      return typeof tick === 'number' ? formatTimeHoursMinutes(tick) : '';
    };
  }
});

// Get current date range for display
const dateRange = computed(() => {
  if (!props.historicalStats) return '';
  
  const periodData = props.historicalStats[timePeriod.value];
  if (!periodData) return '';
  
  // Get last 10 entries
  const keys = Object.keys(periodData).sort();
  const lastTenKeys = keys.slice(-10);
  
  if (lastTenKeys.length === 0) return '';
  
  // Find keys with non-zero data
  const nonZeroKeys = lastTenKeys.filter(key => {
    const record: StatsRecord = periodData[key] || {};
    
    if (chartType.value === 'review') {
      return (record.review_count || 0) > 0;
    } else {
      return (record.call_seconds || 0) > 0 || (record.flashcard_seconds || 0) > 0;
    }
  });
  
  // If no non-zero data, use the full range
  if (nonZeroKeys.length === 0) {
    return formatDateRangeFromKeys(lastTenKeys[0], lastTenKeys[lastTenKeys.length - 1]);
  }
  
  // Use the first and last non-zero entry for the date range
  return formatDateRangeFromKeys(nonZeroKeys[0], nonZeroKeys[nonZeroKeys.length - 1]);
});

// Format date range from keys
const formatDateRangeFromKeys = (startKey: string, endKey: string): string => {
  let startDate: Date, endDate: Date;
  
  if (timePeriod.value === 'daily') {
    // Format: 2024-10-21
    const [startYear, startMonth, startDay] = startKey.split('-');
    const [endYear, endMonth, endDay] = endKey.split('-');
    
    startDate = new Date(Number(startYear), Number(startMonth) - 1, Number(startDay));
    endDate = new Date(Number(endYear), Number(endMonth) - 1, Number(endDay));
  } else if (timePeriod.value === 'weekly') {
    // Format: 2024-43 (year-week)
    const [startYear, startWeek] = startKey.split('-');
    const [endYear, endWeek] = endKey.split('-');
    
    // Calculate start date (first day of start week)
    startDate = getDateFromYearAndWeek(Number(startYear), Number(startWeek));
    
    // Calculate end date (first day of end week)
    endDate = getDateFromYearAndWeek(Number(endYear), Number(endWeek));
  } else {
    // Format: 2024-10 (year-month)
    const [startYear, startMonth] = startKey.split('-');
    const [endYear, endMonth] = endKey.split('-');
    
    startDate = new Date(Number(startYear), Number(startMonth) - 1, 1);
    endDate = new Date(Number(endYear), Number(endMonth) - 1, 1);
  }
  
  return formatDisplayDateRange(startDate, endDate);
};

// Helper to get date from year and week number
const getDateFromYearAndWeek = (year: number, week: number): Date => {
  // January 4th is always in week 1 per ISO 8601
  const firstDayOfYear = new Date(year, 0, 4);
  const dayOfWeek = firstDayOfYear.getDay() || 7; // Get day of week, make Sunday 7 instead of 0
  const firstMondayOfYear = new Date(firstDayOfYear);
  firstMondayOfYear.setDate(firstDayOfYear.getDate() - dayOfWeek + 1);
  
  // Add the weeks
  const targetDate = new Date(firstMondayOfYear);
  targetDate.setDate(firstMondayOfYear.getDate() + (week - 1) * 7);
  
  return targetDate;
};

// Format date range for display
const formatDisplayDateRange = (start: Date, end: Date): string => {
  return `${start.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })} - ${end.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}`;
};

const emit = defineEmits<{
  (e: 'update:open', value: boolean): void
}>()


</script>

<template>
  <div class="flex flex-col w-full">

    <p class="text-xl font-semibold">{{ pageStrings.studyStatistics }}</p>
    <p class="text-left text-lg font-normal mt-0 text-text-subtle">{{ dateRange }}</p>
    
    <div v-if="historicalStats === null || chartData.length === 0" class="flex justify-center items-center min-h-[200px] italic text-gray-500">
      {{ props.pageStrings.noDataAvailable }}
    </div>
    <div v-else class="min-h-[220px] my-4">
      <BarChart
        index="name"
        :data="chartData"
        :categories="chartCategories"
        :y-formatter="yFormatter"
        :showXAxis="true"
        :maxNumTicks="chartData.length"
        :type="chartType === 'studyTime' ? 'stacked' : 'grouped'"
        :margin="{ bottom: 0, top: 20, left: 0, right: 0 }"
        class="bar-chart-with-labels h-[220px]"
        :colors="chartColors"
        :customTooltip="CustomTooltip"
        :hoursSymbol="props.pageStrings.h"
        :minutesSymbol="props.pageStrings.m"
        :chart-mode="chartType"
      />
    </div>
    
    <div class="flex flex-col gap-3 mt-8">
      <div>
        <div class="text-sm font-medium mb-1 text-text-subtle">{{ props.pageStrings.granularity }}</div>
        <div class="flex justify-start gap-1">
          <button 
            v-for="period in timePeriods" 
            :key="period"
            @click="timePeriod = period"
            :class="[
              'py-[0.1rem] px-[0.3rem] text-xs bg-[#F3F3F3] text-text-chat-label border border-text-subtle rounded-full cursor-pointer transition-all duration-200 ease-in-out mx-[0.15rem] hover:opacity-90',
              { 'bg-text-chat-label text-white': timePeriod === period }
            ]">
            {{ getTimePeriodDisplayName(period) }}
          </button>
        </div>
      </div>
      
      <div>
        <div class="text-sm font-medium mb-1 text-text-subtle">{{ props.pageStrings.metric }}</div>
        <div class="flex justify-start gap-1">
          <button 
            @click="chartType = 'studyTime'"
            :class="[
              'py-[0.1rem] px-[0.3rem] text-xs bg-[#F3F3F3] text-text-chat-label border border-text-subtle rounded-full cursor-pointer transition-all duration-200 ease-in-out mx-[0.15rem] hover:opacity-90',
              { 'bg-text-chat-label text-white': chartType === 'studyTime' }
            ]">
            {{ props.pageStrings.totalStudyTime }}
          </button>
          <button 
            @click="chartType = 'review'"
            :class="[
              'py-[0.1rem] px-[0.3rem] text-xs bg-[#F3F3F3] text-text-chat-label border border-text-subtle rounded-full cursor-pointer transition-all duration-200 ease-in-out mx-[0.15rem] hover:opacity-90',
              { 'bg-text-chat-label text-white': chartType === 'review' }
            ]">
            {{ props.pageStrings.cardsReviewed }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Keep only the chart-specific styles that can't be replaced with Tailwind */
/* Additional styles to improve the display */
.bar-chart-with-labels :deep(.vis-axis) {
  font-size: 12px;
}

/* Fix spacing between tick labels */
.bar-chart-with-labels :deep(.vis-x-axis .tick) text {
  font-size: 10px;
  /* Force all x-axis labels to display, especially for monthly view */
  display: block !important;
  visibility: visible !important;
}

/* Ensure all ticks are visible */
.bar-chart-with-labels :deep(.vis-x-axis .tick) {
  visibility: visible !important;
  opacity: 1 !important;
}

/* Hide tick marks but keep labels */
.bar-chart-with-labels :deep(.vis-x-axis .tick) line {
  display: none;
}

/* Custom colors */
.bar-chart-with-labels :deep(.vis-legend-container .vis-legend-item:nth-child(1)) .vis-legend-icon {
  fill: #737BCB !important;
}

.bar-chart-with-labels :deep(.vis-legend-container .vis-legend-item:nth-child(2)) .vis-legend-icon {
  fill: #E76E50 !important;
}

/* Remove previous tooltip fixes that didn't work */
</style>
