<script setup lang="ts">
import KickstartArea from "@/practice/KickstartArea.vue";
import Transcript from "@/practice/Transcript.vue";
import {apiRes, type CreateUserResponse, PracticeAPI, type UpdateUserResponse, type GetUserResponse, type GetWebRTCCredentialsResponse, type LogoutResponse, type StartSessionResponse, type LoginResponse, type GetLeaderboardResponse, type GetLanguageSettingsResponse, type GetLocalizedKickstartsResponse, type Kickstart, type LocalizedKickstart, type GetWordsResponse, type GetDueCardsResponse} from "@/practice/api";
import {domainPort, protocol} from "@/url";
import { onMounted, onUnmounted, ref, watch, type Ref, nextTick, computed, getCurrentInstance} from 'vue';
import ChatToolbar from "@/practice/ChatToolbar.vue";
import { templateIdToLanguage, UserStatus, type Message, type UserSettings } from "@/types/model";
import { PhCircleNotch, PhSpinner } from "@phosphor-icons/vue";
import { Button } from "@/components/ui/button";
import SavedWords from "@/practice/SavedWords.vue";
// @ts-ignore
import { DailyCallManager } from '@/practice/DailyCallManager'; 
import { getMessagesFromLanguage} from "@/languages/messages";
import IssenHeader from "@/practice/IssenHeader.vue";
import { getDefaultSourceLanguage } from "@/practice/util";
import router from "@/router";
import { getErrorMessagesFromLanguage } from "@/languages/errors/serverErrors";
import { ClientErrorType, getClientErrorMessagesFromLanguage } from "@/languages/errors/clientErrors";
import FirstUserArea from "@/practice/FirstUserArea.vue";
import { AlertDialog, AlertDialogContent, AlertDialogHeader, AlertDialogFooter, AlertDialogTitle, AlertDialogDescription, AlertDialogAction } from "@/components/ui/alert-dialog";
import { waitForEither, waitForRef } from "@/practice/waitForRef";
import { useAnalytics } from '@/practice/useAnalytics';
import ColorfulBackground from "@/practice/ColorfulBackground.vue";
let dailyCallManager: DailyCallManager | null = null;
const startEnabled: Ref<boolean> = ref(false);
const error: Ref<string> = ref("");
const API: PracticeAPI = new PracticeAPI(protocol + domainPort);
let userId: Ref<string> = ref("");
let agentId: Ref<string | null> = ref(null);
const isConnected: Ref<boolean> = ref(false);
const aiReady: Ref<boolean> = ref(false);
const messages: Ref<Message[]> = ref([]);
const getUserResult = apiRes<GetUserResponse>();
const isMicOn = ref(true);
const isUserLoggedIn: Ref<boolean> = ref(false);
const startSessionResult = apiRes<StartSessionResponse>();
const loginResult = apiRes<LoginResponse>();
const guiLanguage: Ref<string> = ref(getDefaultSourceLanguage());
const pageStrings: Ref<{ [key: string]: string }> = ref(getMessagesFromLanguage(guiLanguage.value));
const targetLangPageStrings: Ref<{ [key: string]: string }> = ref(getMessagesFromLanguage(guiLanguage.value));
const serverErrorStrings = ref<{ [key: number]: string }>({});
const clientErrorStrings = ref<{ [key: number]: string }>({});
const nameInputField: Ref<string> = ref("");
const sessionError: Ref<number | null> = ref(null);
const leaderboardResult = apiRes<GetLeaderboardResponse>();
const isFocused: Ref<boolean> = ref(false);
const showLeaderboard: Ref<boolean> = ref(false);
const dailyGoal: Ref<number> = ref(60);
const sessionMessageIndexStart: Ref<number> = ref(0);
const streamingId: Ref<string | null> = ref(null);
const micBeforeStreaming: Ref<boolean> = ref(true);
const replayingAudio: Ref<boolean> = ref(false);
const languageSettings = apiRes<GetLanguageSettingsResponse>();
const targetLanguage: Ref<string> = ref("");
const messageCommitted: Ref<boolean> = ref(true);
const updateUserResult = apiRes<UpdateUserResponse>();
const gotOnboardingFinishedMsg: Ref<boolean> = ref(false);
const joinedDaily: Ref<boolean> = ref(false);
const joiningDaily: Ref<boolean> = ref(false);
const localizedKickstartsResult = apiRes<GetLocalizedKickstartsResponse>();
const selectedKickstart: Ref<LocalizedKickstart | null> = ref(null);
const joinedDailyFailed: Ref<boolean> = ref(false);
const leaderboardOpen: Ref<boolean> = ref(false);
const availableDevices: Ref<Object[]> = ref([]);
const selectedDevice: Ref<Object | null> = ref(null);
const updatingDevices: Ref<boolean> = ref(false);
const isLoggingOut: Ref<boolean> = ref(false);
const initialTutorPatience = computed(() => getUserResult.data.value?.user.tutorPatience ?? 5);
const initialSpeechSpeedRatio = computed(() => getUserResult.data.value?.user.speechSpeedRatio ?? 1.0);
const initialManualMode = computed(() => getUserResult.data.value?.user.manualMode ?? false);
const localizedKickstarts = computed(() => localizedKickstartsResult.data.value ?? null);
const savedWordsOpen = ref(false);
const getWordsResult = apiRes<GetWordsResponse>();
const dueCardsResult = apiRes<GetDueCardsResponse>();
const { trackEvent } = useAnalytics()

const handleKeyDown = (event: KeyboardEvent) => {
  if (event.key === 'Enter' && !event.shiftKey) {
    event.preventDefault();
  }
};


onMounted(async () => {
  await API.getUser(getUserResult);
  if (!getUserResult.data.value || getUserResult.data.value.user.guiLang === null || getUserResult.data.value.user.sourceLang === null) {
    await API.update_user(updateUserResult, {guiLang: guiLanguage.value, sourceLang: guiLanguage.value});
  } else {
    guiLanguage.value = getUserResult.data.value.user.guiLang;
  }
  pageStrings.value = getMessagesFromLanguage(guiLanguage.value);
  targetLangPageStrings.value = getMessagesFromLanguage(targetLanguage.value);
  serverErrorStrings.value = getErrorMessagesFromLanguage(guiLanguage.value);
  clientErrorStrings.value = getClientErrorMessagesFromLanguage(guiLanguage.value);
  sessionError.value = null;
  selectedKickstart.value = null;


  if (getUserResult.data.value) {
    if (getUserResult.data.value.user.name !== "") {
      isUserLoggedIn.value = true;
      nameInputField.value = getUserResult.data.value.user.name;
    }
    userId.value = getUserResult.data.value.user.userId;
    dailyGoal.value = getUserResult.data.value.user.dailyMinutesGoal;
    showLeaderboard.value = false;
    await updateTimezoneIfNeeded();
  } else {
    // error.value = serverErrorStrings.value[26];
    // return;
  } 
  if (getUserResult.data.value?.user.hasOnboarded) {
    targetLanguage.value = templateIdToLanguage(getUserResult.data.value.user.selectedAgentTemplateId);
    API.getLanguageSettings(languageSettings, true);
    targetLangPageStrings.value = getMessagesFromLanguage(targetLanguage.value);
  }
  let noiseCancellation= getUserResult.data.value?.user.noiseCancellation ?? false;
  dailyCallManager = new DailyCallManager(joinedDailyFailed, aiReady, messages, sessionError, noiseCancellation, 
  streamingCb, vadCb, onboardingFinishedCb, messageCommittedCb, aiLeftCb);
  API.getDueCards(dueCardsResult);

  const savedWords = router.currentRoute.value.query.savedWords;
  if (savedWords) {
    savedWordsOpen.value = true;
    updatedSavedWords(true);
  } else {
    updatedSavedWords(false);
  }
  
  if (!getUserResult.data.value) { // if we are not logged in, we need to log in
    
    const potentialUsername = await createTmpUser("tmpUser");
    await tmpUserLogin(potentialUsername);
  }
  API.getLeaderboard(leaderboardResult).then(() => {
    isFocused.value = true;
  });
  window.addEventListener('keydown', handleKeyDown);  
  setVH();
  window.addEventListener('resize', setVH);
  window.addEventListener("beforeunload", (event) => {
  if (dailyCallManager && joinedDaily.value) {
      dailyCallManager.leaveRoom();
    }
  });

  API.getLocalizedKickstarts(localizedKickstartsResult).then(() => {
    startEnabled.value = true;
  });

  await autoStartIfNotStarted();
  document.addEventListener('click', () => {
    setTimeout(() => {
      autoStartIfNotStarted();
    }, 100);
  });
  sendEventForTrial('mounted_chatview')
});

async function refreshUserData() {
  await API.getUser(getUserResult);

  if (getUserResult.data.value?.user.hasOnboarded) {
    targetLanguage.value = templateIdToLanguage(getUserResult.data.value.user.selectedAgentTemplateId);
    API.getLanguageSettings(languageSettings, true);
    targetLangPageStrings.value = getMessagesFromLanguage(targetLanguage.value);
  }

  API.getDueCards(dueCardsResult);

  API.getLocalizedKickstarts(localizedKickstartsResult).then(() => {
    startEnabled.value = true;
  });
}

onUnmounted(async () => {
  window.removeEventListener('resize', setVH);
  window.removeEventListener('keydown', handleKeyDown);
  document.removeEventListener('click', autoStartIfNotStarted);
  if (dailyCallManager) {
    dailyCallManager.leaveRoom().then(() => {
      if (dailyCallManager) {
        dailyCallManager.destroy();
      }
    })
  }

});

const setVH = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
};

const sendEventForTrial = (eventName: string) => {
  if (getUserResult.data.value && getUserResult.data.value.user.userStatus === UserStatus.TRIAL) {
    trackEvent(eventName)
  }
}


async function streamingCb(streamingObj: any) {
  if (streamingObj.type === "start_streaming") {
    streamingId.value = streamingObj.message_id;
  } else if (streamingObj.type === "stop_streaming") {
    if (streamingObj.message_id === streamingId.value) {
      streamingId.value = null;
      if (replayingAudio.value) {
        setMic(micBeforeStreaming.value);
      }
      replayingAudio.value = false;
      micBeforeStreaming.value = false;
    }
  }
}

watch(messages, (newMessages, oldMessages) => {
  gotOnboardingFinishedMsg.value = false;
  if (!getUserResult.data.value?.user.hasOnboarded) {
    if (newMessages.length > 0 && newMessages[newMessages.length - 1].confirmed) {
      sendEventForTrial('onboarding_message_confirmed')
    }
  }
}, { deep: true});

watch(savedWordsOpen, async (newValue) => {
  if (!newValue) {
    await API.getDueCards(dueCardsResult);
  }
})
async function vadCb() {
  messageCommitted.value = false;
}
async function onboardingFinishedCb() {
  gotOnboardingFinishedMsg.value = true;
  await API.getLocalizedKickstarts(localizedKickstartsResult);
  setTimeout(async () => {
    await API.getUser(getUserResult);
    if (getUserResult.data.value && getUserResult.data.value.user.hasOnboarded) {
      dailyGoal.value = getUserResult.data.value.user.dailyMinutesGoal;
      targetLanguage.value = templateIdToLanguage(getUserResult.data.value.user.selectedAgentTemplateId);
    }
  }, 3000);

  sendEventForTrial('onboarding_finished')
}
async function messageCommittedCb() {
  messageCommitted.value = true;
}
async function aiLeftCb() {
  await leaveRoom();
}

async function createTmpUser(name: string): Promise<string> {
    const tempUsername = name.toLowerCase() + "_" + Math.random().toString(36).substring(2, 16); // Generate a random username for this person
    const createUserResult = apiRes<CreateUserResponse>();
    console.log("Creating temp user", tempUsername)
    await API.create_user(tempUsername, "password", createUserResult);
    return tempUsername
}

async function tmpUserLogin(potentialUsername: string) {
  await API.login(potentialUsername, "password", loginResult);
  if (!loginResult.data.value) {
    console.error("Failed to login", loginResult.error.value);
    return false;
  }
  await API.getUser(getUserResult);
  if (!getUserResult.data.value) {
    console.error("Failed to get user, does not exist", getUserResult.error.value);
    return false;
  }
  // @ts-ignore
  userId.value = getUserResult.data.value?.user.userId ?? "";
  return true;
}

async function updateTimezoneIfNeeded() {
  if (getUserResult.data.value && !getUserResult.data.value.user.timezone) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    await API.update_user(updateUserResult, {timezone});
  }
}

const autoStart = async (leaveRoom: boolean = false): Promise<void> => {
if (isLoggingOut.value) {
    return;
  }
  joiningDaily.value = true;
  joinedDaily.value = false;
  joinedDailyFailed.value = false;
  if (leaveRoom) {
    await dailyCallManager?.leaveRoom();
  }
  
  await API.startSession(startSessionResult);
  if (startSessionResult.error.value !== null) {
    console.log("Error in start session", startSessionResult.error.value)
    if (startSessionResult.error.value === 18) {
      router.push("/subscribe?timelimit=true");
    } else if (startSessionResult.error.value === 1) {
      // Making this a generic error for now
      sessionError.value = 0;
      // router.push("/max-usage");
    } else {
      error.value = serverErrorStrings.value[startSessionResult.error.value];
      startEnabled.value = true;
      // sessionError.value = startSessionResult.error.value;
      joiningDaily.value = false;
      return;
    }
  }
  if (getCurrentInstance()?.isUnmounted) {
    return;
  }
  if (isLoggingOut.value) {
    joiningDaily.value = false;
    return;
  }
  if (startSessionResult.data.value && dailyCallManager) {
    await dailyCallManager.joinRoom(startSessionResult.data.value.room.url, startSessionResult.data.value.token); 
  }
  joiningDaily.value = false;
  joinedDaily.value = true;
  return;
}

const autoStartIfNotStarted = async () => {
  if (!getUserResult.data.value) {
    return;
  }

  if (joinedDaily.value) {
    let meetingState = await dailyCallManager?.call.meetingState();
    if (meetingState === "error") {
      joinedDailyFailed.value = true;
    }
  }

  if (joinedDaily.value == false) {
    if (joiningDaily.value) {
      await waitForRef(joinedDaily, true);
      if (joinedDailyFailed.value) {
        joinedDaily.value = false;
        await autoStart();
      }
    } else {
      await autoStart();
    }
  } else if (joinedDailyFailed.value) {
    if (joiningDaily.value) { 
      await waitForRef(joinedDaily, true);
    } else {
      joiningDaily.value = true;
      await autoStart(true);
    }
  }
}


const start = async (kickstartMessage: LocalizedKickstart) => {
  leaderboardOpen.value = false;
  if (nameInputField.value === "") {
    error.value = clientErrorStrings.value[ClientErrorType.NameRequired];
    return;
  }
  if (isLoggingOut.value) {
    return;
  }
  selectedKickstart.value = kickstartMessage;
  sessionError.value = null;
  startEnabled.value = false;
  error.value = "";
  sessionMessageIndexStart.value = messages.value.length;

  // Update the user's name
  if (nameInputField.value !== getUserResult.data.value?.user.name) {
    await API.update_user(updateUserResult, {name: nameInputField.value});
  }
  if (isLoggingOut.value) {
    return;
  }
  isUserLoggedIn.value = true;
  if (dailyCallManager) {
    dailyCallManager.setKickstartMessage({
      type: "kickstart",
      kickstart_id: kickstartMessage.kickstart.id,
      kickstart_type: kickstartMessage.kickstart.type
    });
  }
  await autoStartIfNotStarted();

  if (dailyCallManager) {
    await dailyCallManager.startAudio();
  } else {
    error.value = "Failed to start audio";
  }
  
  if (updateUserResult.error.value) {
    console.error('Failed to update user name:', updateUserResult.error);
    error.value = serverErrorStrings.value[updateUserResult.error.value];
    startEnabled.value = true;
    return;
  }

  isMicOn.value = true;
  if (startSessionResult.data.value && dailyCallManager) {
    isConnected.value = true;
  }
}

const handleEnterKey = async (event: KeyboardEvent, name: string) => {
  if (event.key === 'Enter') {
    clickedStartNewUser(name);
  }
};

const clickedFlashcards = () => {
  router.push('/flashcards');
}

const changeKickstart = async (localizedKickstart: LocalizedKickstart) => {
  if (dailyCallManager) {
    const defaultKickstart = {
      kickstart: {
        id: "open_ended",
        description: "",
        prompt: "",
        type: "open_ended"
      },
      language: "",
      localized_description: ""
    };
    const kickstart = localizedKickstart || defaultKickstart;
    
    dailyCallManager.sendAppMessage({
      type: "kickstart",
      kickstart_id: kickstart.kickstart.id, 
      kickstart_type: kickstart.kickstart.type
    });
    selectedKickstart.value = kickstart;
  }
}

const restartSession = async (reloadUserData: boolean = false) => {
  startEnabled.value = false;
  await leaveRoom(false, false);
  if (reloadUserData) {
    await refreshUserData();
  }
  await autoStartIfNotStarted();
  startEnabled.value = true;
}

const clickStart = async (localizedKickstart?: LocalizedKickstart) => {
  sendEventForTrial('started_trial_call')
  if (localizedKickstart) {
    console.log("Starting with kickstart", localizedKickstart)
    start(localizedKickstart);
  } else {
    start({
      kickstart: {
        id: "open_ended",
        description: "",
        prompt: "",
        type: "open_ended"
      },
      language: "",
      localized_description: ""
    });
}
}

// Add these new refs and function
const toggleMic = (newValue: boolean) => {
  console.log("Clicked toggle mic. Muted:", newValue)
  if (muteDisabled.value) {
    console.log("Mic is disabled, not toggling")
    return;
  }
  isMicOn.value = !newValue;
  if (dailyCallManager && aiReady.value) {
    dailyCallManager.toggleMicrophone(isMicOn.value);
  }
  if (replayingAudio.value) {
    micBeforeStreaming.value = isMicOn.value;
  }
};

const setMic = (value: boolean) => {
  isMicOn.value = value;
  if (dailyCallManager) {
    dailyCallManager.setMic(value);
  }
};



async function leaveRoom(enableStart: boolean = true, refreshData: boolean = true) {
  console.log("Leaving room with name", nameInputField.value);

  setTimeout(() => {
    isConnected.value = false;
  }, 200);

  aiReady.value = false;
  if (enableStart) {
    startEnabled.value = true;
  }
  setTimeout(async () => {
    await refreshLeaderboard();
  }, 1000);

  if (dailyCallManager) {
    if (joiningDaily.value) {
      await waitForRef(joinedDaily, true);
    }
    await dailyCallManager.leaveRoom();
  }
  joinedDaily.value = false;
  if (refreshData) {
    await Promise.all([
      API.getUser(getUserResult),
      API.getDueCards(dueCardsResult),
      API.getLocalizedKickstarts(localizedKickstartsResult)
    ]);
    if (getUserResult.data.value?.user.hasOnboarded) {
      await API.getLanguageSettings(languageSettings);
    }
  }
};

const numCardsToReview = computed(() => {
  if (!getUserResult.data.value) {
    return null;
  }
  const user = getUserResult.data.value.user;
  if (!user.hasOnboarded) {
    return null;
  }
  
  if (!dueCardsResult.data.value?.cards) {
    return null;
  }
  
  return dueCardsResult.data.value.cards.length;
});

async function preLogout() {
  console.log("Pre logout");
  getUserResult.data.value = null;
  getUserResult.isFetching.value = false;
  getUserResult.error.value = null;
  gotOnboardingFinishedMsg.value = false;
  targetLanguage.value = "";
  agentId.value = null;
  userId.value = "";
  nameInputField.value = "";
  aiReady.value = false;
  isUserLoggedIn.value = false;
  isLoggingOut.value = true;
  messages.value = [];
  if (isConnected.value) {
    isConnected.value = false;
  }
}
async function postLogout() {
  leaderboardOpen.value = false;
  if (joiningDaily.value && (!joinedDaily.value || joinedDailyFailed.value)) {
    await waitForEither(joinedDaily, true, joiningDaily, false);
  }
  await leaveRoom(false, false);
  const potentialUsername = await createTmpUser("tmpUser");
  await tmpUserLogin(potentialUsername);
  startEnabled.value = true;
  isLoggingOut.value = false;
  await autoStartIfNotStarted();
}



watch(sessionError, async (newValue) => {
  if (newValue !== null) {
    console.log("Session error", newValue);

    await leaveRoom();
    if (newValue === 14) {
      router.push("/subscribe?timelimit=true");
    }
    await API.getUser(getUserResult);
  }
});

async function goToEmail() {
  router.push("/email");
}

const alertDialogOpen = computed(() => {
  return sessionError.value !== null;
});

watch(sessionError, async (newValue) => {
  if (newValue) {
    leaderboardOpen.value = false;
    savedWordsOpen.value = false;
  }
});

const closeAlertDialog = () => {
  sessionError.value = null;
} 
const userStreak = computed(() => {
  return leaderboardResult.data.value?.currentUser?.streak ?? 0;
});


const onPlayAudio = (messageId: string) => {
  streamingId.value = messageId;
  if (dailyCallManager) {
    dailyCallManager.sendAppMessage({"type": "play_audio", "message_id": messageId});
  }
  replayingAudio.value = true;
  micBeforeStreaming.value = isMicOn.value;
  setMic(false);
};

const onStopAudio = (messageId: string) => {
  if (dailyCallManager) {
    dailyCallManager.sendAppMessage({"type": "stop_audio", "message_id": messageId});
  }
  setMic(micBeforeStreaming.value);
};

const onUserTriggeredInterrupt = () => {
  if (dailyCallManager) {
    dailyCallManager.sendAppMessage({"type": "user_triggered_interrupt"});
  }
};

const muteDisabled = computed(() => {
  return !aiReady.value || replayingAudio.value || messages.value.length == 0 || (messages.value.length == sessionMessageIndexStart.value + 1 && !messages.value[sessionMessageIndexStart.value].confirmed)
});


const clickedStartNewUser = async (name: string) => {
  nameInputField.value = name;
  console.log("clickedStartNewUser", name);
  clickStart(undefined);
}

const reloadUser = async () => {
  if (dailyCallManager  ) {
    await dailyCallManager.reloadUser();
  }
}

const sendMessage = async () => {
  if (dailyCallManager) {
    await dailyCallManager.forceCommit();
  }
}
const clickSavedWords = () => {
  savedWordsOpen.value = !savedWordsOpen.value;  // Toggle the dialog instead of just opening it
  if (savedWordsOpen.value) {
    leaderboardOpen.value = false;
  }
}

const updateDevices = async (devices: { mic?: string, speaker?: string }) => {
  if (dailyCallManager) {
    try {
      updatingDevices.value = true;
      if (devices.mic) {
        // This sets the mic, and in some platforms it also sets the speaker.
        await dailyCallManager.call.setInputDevicesAsync({ audioDeviceId: devices.mic });
        console.log("selectedDevice", selectedDevice.value && "speaker" in selectedDevice.value, (selectedDevice.value as any).speaker.deviceId, devices.mic, );
      }
      if (devices.speaker && (devices.speaker !== 'default' || availableDevices.value.some(d => {
        const device = d as any;
        return device.deviceId === 'default' && device.kind === 'audiooutput' && (!('fake' in device) || !device.fake);
      }))) {
        console.log("Setting speaker to", devices.speaker);
        await dailyCallManager.call.setOutputDeviceAsync({ outputDeviceId: devices.speaker });
      }
      availableDevices.value = await dailyCallManager.getDevices();
      selectedDevice.value = await dailyCallManager.getSelectedDevice();
      let addedDefaultSpeaker = false;
      if (!availableDevices.value.some(d => (d as any).kind === 'audiooutput')) {
        addedDefaultSpeaker = true;
      }
      if (selectedDevice.value && 'speaker' in selectedDevice.value && (selectedDevice.value.speaker as any).deviceId === 'default') {
        addedDefaultSpeaker = true;
      }
      if (addedDefaultSpeaker && !availableDevices.value.some(d => (d as any).deviceId === 'default' && (d as any).kind === 'audiooutput')) {
        availableDevices.value = [
          {
            deviceId: 'default',
            kind: 'audiooutput', 
            label: 'Default',
            fake: true
          },
          ...availableDevices.value
        ];
      }
      if (!('speaker' in selectedDevice.value) || (selectedDevice.value as any).speaker.deviceId === '') {
        selectedDevice.value = {
          ...selectedDevice.value,
          speaker: {
            deviceId: 'default',
            kind: 'audiooutput',
            label: 'Default',
          }
        };
      }
    } catch (error) {
      console.error("Error updating devices:", error);
    } finally {
      updatingDevices.value = false;
    }
  }
}

const updatedSavedWords = async (getDefinitions: boolean) => {
  await API.getWords(getDefinitions, targetLanguage.value, getWordsResult);
}

const refreshLeaderboard = async () => {
  await API.getLeaderboard(leaderboardResult);
};
</script>

<style scoped>
.chat-bg {
    background: var(--bg-chat-base);
    transition: background 5s ease;
}
.page-container {
  height: 100%;
}
/* Background elements */
.background-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: -1;
  pointer-events: none;
  transition: opacity 5s ease;
}

.background-visible {
  opacity: 1;
  transition: opacity 2s ease;
}

.background-hidden {
  opacity: 0;
  transition: opacity 5s ease;
}

.normal-bg {
  background: transparent;
}
</style>

<template >
  <AlertDialog v-model:open="alertDialogOpen">
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>{{ sessionError === 19 ? pageStrings.conversationTimeout : pageStrings.error }}</AlertDialogTitle>
        <AlertDialogDescription>
          {{ serverErrorStrings[sessionError as number] }}
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <Button @click="closeAlertDialog" variant="primary">{{ pageStrings.close }}</Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
  <SavedWords :open="savedWordsOpen" @update:open="savedWordsOpen = $event" :pageStrings="pageStrings" :updatedSavedWords="updatedSavedWords" 
  :getWordsResult="getWordsResult.data.value" :loading="getWordsResult.isFetching.value"/>
  <span class="page-container flex flex-col relative" :class="isConnected ? 'chat-bg' : 'normal-bg'">
    <div class="w-full flex justify-between">
      <IssenHeader :pageStrings="pageStrings" :getUserResult="getUserResult" :preLogout="preLogout" :postLogout="postLogout" :clickSavedWordsProp="clickSavedWords"/>
    </div>
    <div class="flex-1 flex flex-col w-full mt-[-80px]" v-if="!getUserResult.isFetching.value && !languageSettings.isFetching.value && !isConnected && isUserLoggedIn && getUserResult.data.value?.user?.hasOnboarded">
      <KickstartArea class="flex-grow justify-center" v-if="isUserLoggedIn" :user="getUserResult.data.value?.user ?? null"
      :isUserLoggedIn="isUserLoggedIn" :startEnabled="startEnabled" :clickedStart="clickStart" :restartSession="restartSession"
      :localizedKickstarts="localizedKickstarts" :pageStrings="pageStrings" :targetLanguage="targetLanguage" :clickedFlashcards="clickedFlashcards" :numCardsToReview="numCardsToReview"
      :savedWords="getWordsResult.data.value?.words || []" :savedCards="getWordsResult.data.value?.cards || []" :userStreak="userStreak" :numCardsReviewed="dueCardsResult.data.value?.doneReviewsToday || 0"
      :historicalStats="languageSettings.data.value?.historicalStats || null"
      :studyStats="languageSettings.data.value?.studyStats || null" :leaderboardResult="leaderboardResult.data.value || null"
      :targetLangPageStrings="targetLangPageStrings" />
    </div>
    <div class="flex-1 flex flex-col items-center w-full" v-else-if="getUserResult.data.value && !getUserResult.isFetching.value && !isConnected && (!isUserLoggedIn || !getUserResult.data.value?.user?.hasOnboarded)">

      <FirstUserArea class="mt-4 md:mt-12" :startEnabled="startEnabled" :clickedStart="clickedStartNewUser"  :handleEnterKey="handleEnterKey" :goToEmail="goToEmail" :user="getUserResult.data.value?.user ?? null" :pageStrings="pageStrings" />
    </div>
    <div class="flex-1 flex flex-col items-center justify-end w-full overflow-auto" v-else-if="getUserResult.data.value?.user && isConnected">
      <span class="h-8 md:h-4"> &nbsp; </span>

      <Transcript class="flex flex-col" :messages="messages" :sessionMessageIndexStart="sessionMessageIndexStart" :userId="userId" 
      :language="targetLanguage" :streamingId="streamingId" :replayingAudio="replayingAudio" :onPlayAudio="onPlayAudio" 
      :onStopAudio="onStopAudio" :pageStrings="pageStrings" :onUserTriggeredInterrupt="onUserTriggeredInterrupt" 
      :updatedSavedWords="updatedSavedWords" :getWordsResult="getWordsResult.data.value?.words || []" :savedWordsOpen="savedWordsOpen" />

      <span class="flex flex-col items-center" v-if="gotOnboardingFinishedMsg">
        <PhCircleNotch class="animate-spin [animation-duration:2s] w-12 h-12" />
        <p class="text-lg">{{ pageStrings.connectingToTutor }}</p>
      </span>
      <ChatToolbar v-if="getUserResult.data.value?.user" :kickstart="selectedKickstart" :isMuted="!isMicOn" :muteDisabled="muteDisabled" :toggleMute="toggleMic" :aiReady="aiReady" 
      :leaveCall="leaveRoom" :initialTutorPatience="initialTutorPatience" :initialSpeechSpeedRatio="initialSpeechSpeedRatio" 
      :initialManualMode="initialManualMode" @reloadUser="reloadUser" :pageStrings="pageStrings" 
      :availableDevices="availableDevices" :selectedDevice="selectedDevice" @updateDevices="updateDevices" 
      :updatingDevices="updatingDevices" :localizedKickstarts="localizedKickstarts" :sendMessage="sendMessage"
      :changeKickstart="changeKickstart" :isOnboarded="getUserResult.data.value.user.hasOnboarded" 
      :canPracticeSavedWords="getWordsResult.data.value?.words ? getWordsResult.data.value.words.length > 0 : false" />
    </div>
    <div class="flex-1 flex flex-col items-center justify-center w-full overflow-auto" v-else> 
      <PhCircleNotch class="animate-spin [animation-duration:2s] w-12 h-12" />
    </div>
  </span>

</template>
