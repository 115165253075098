import axios, {AxiosError} from "axios";
import type {AxiosRequestConfig, ResponseType} from "axios";
import {ref, type Ref} from "vue";
import {sha256} from "@/practice/crypto";
import {Message, type Agent, type LanguageSettings, type UserSettings, CardType, State, Rating, type Word, type Card, type Review, type StudyStats, type HistoricalStats} from "@/types/model";
import type {AgentTemplate, Voice} from "@/types/agents";

axios.defaults.withCredentials = true;

export type ApiRes<T> = {
    data: Ref<T | null>,
    isFetching: Ref<boolean>,
    error: Ref<number | null>
}
export function apiRes<T>() : ApiRes<T> {
    const data: Ref<T|null> = ref(null);
     return {
         data,
         isFetching: ref(false),
         error: ref(null)
     }
}


export type CheckUsernameResponse = {
    usernameExists: boolean
}

export type CheckAllowlistResponse = {
    allowlisted: boolean
}

export type CreateUserResponse = {
    message: string
}

export type UpdateUserResponse = {}

export type LoginResponse = {
    success: boolean,
    message: string,
    user: UserSettings | null
}

export type LogoutResponse = {}

export type GetLoginStatusResponse = {
    loggedIn: boolean
}

export type GetUserResponse = {
    user: UserSettings,
    defaultPassword: boolean
}

export type CheckSentenceResponse = {
    feedback: string,
    score: number
}

export type GetLeaderboardResponse = {
    leaderboard: {name: string, userId: string, language: string, totalDuration: number, streak?: number, ranking: number}[],
    currentUser?: {name: string, userId: string, language: string, totalDuration: number, streak?: number, ranking: number}
}

export type GetUserUsageResponse = {
    weeklyDuration: number,
    totalDuration: number
}

export type GetVoicesResponse = {
    voices: Voice[]
}

export type GetAvailableAgentTemplatesResponse = {
    agentTemplates: AgentTemplate[]
}

export type GetWebRTCCredentialsResponse = {
    username: string,
    password: string
}   
export type StartSessionResponse = {
    room: {
        id: string,
        name: string,
        url: string
    },
    token: string
}

export type SubscribeResponse = {
    url: string
}

export type CreateStripePortalSessionResponse = {
    url: string
}

export type GetAgentsResponse = {
    agents: Agent[]
}

export type GetAgentMessagesResponse = {
    sessions: Message[][]
}
export type GetLanguageSettingsResponse = {
    languageSettings: {
        id: string;
        language: string;
        proficiencyLevel: string;
        formalness: string;
        grammarCorrection: string;
        tutorLanguageUsage: string;
        savedWordsPracticeStyles: string[];
    },
    studyStats: StudyStats,
    historicalStats: HistoricalStats
}

export type UpdateLanguageSettingsResponse = {
    languageSettings: {
        id: string;
        language: string;
        proficiencyLevel: string;
        formalness: string;
        grammarCorrection: string;
        tutorLanguageUsage: string;
        savedWordsPracticeStyles: string[];
    }
}

export type Kickstart = {
    id: string,
    description: string,
    prompt: string,
    type: string
}


export type LocalizedKickstart = {
    kickstart: Kickstart,
    language: string,
    localized_description: string
}

export type GetLocalizedKickstartsResponse = {
    dailyKickstart: {
        kickstart: LocalizedKickstart,
        completed: boolean
    },
    studentLifeKickstarts: LocalizedKickstart[],
    topicKickstarts: LocalizedKickstart[],
    scenarioKickstarts: LocalizedKickstart[],
    grammarKickstarts: LocalizedKickstart[],
    savedWordsKickstarts: LocalizedKickstart[]
}
export type CorrectMessageResponse = {
    correctionSummary: string,
    score: number
}

export type DeleteUserResponse = {}

export type FlagMessageResponse = {}

export type AddWordResponse = {
    id: number
}

export type GetWordsResponse = {
    words: Word[],
    cards: Card[]
}

export type DeleteWordResponse = {}

export type ResetPasswordResponse = {
}
export type GetDueCardsResponse = {
    cards: Card[],
    words: Word[],
    doneReviewsToday: number,
}
export type RateCardResponse = {
    card: Card,
    review: Review
}   

export type SetCardsEnabledResponse = {}

export type GetCardsByWordIdResponse = {
    cards: Card[]
}
export type UpdateWordResponse = {}

export type AddWordEntry = {
    word: string,
    sentence?: string
}
export type AddWordsResponse = {}

class PracticeAPI {
    domainPort: string;

    constructor(domainPort: string) {
        this.domainPort = domainPort;
    }

    private handleAPICall(url: string, inputData: Object, outputData: Ref, isFetching: Ref<boolean>, errorOut: Ref<number | null>, responseType?: ResponseType) {
        isFetching.value = true;
        errorOut.value = null;
        const config: AxiosRequestConfig = {
            method: 'post',
            url: this.domainPort + url,
            data: inputData,
            responseType,
            headers: {
                'Client-Platform': 'web',
                'Client-Version': '1.6.8'
            }
        }
        return axios(config).then((response) =>  {
            console.log("POST /", url, " response: ", response);
            if (!response.data) {
                outputData.value = {};
            } else {
                outputData.value = response.data; //@ts-ignore
            }
            isFetching.value = false;
        }).catch((error: AxiosError) => {
            console.log("POST /", url, " error: ", error.response, error.message);
            if (error.response) {
                if (error.response.data !== null && typeof error.response.data === 'object') {
                    if ("error" in error.response.data && typeof error.response.data.error === 'number') {
                        errorOut.value = error.response.data.error;
                    } else if ("error" in error.response.data) {
                        errorOut.value = error.response.data.error as number;
                    } else {
                        errorOut.value = 0;
                    }
                } else {
                    errorOut.value = 0;
                }
            } else {
                errorOut.value = 0;
            }
            isFetching.value = false;
        });
    }


    async checkUsername(username: string, result: ApiRes<CheckUsernameResponse>) : Promise<void> {
        return this.handleAPICall("/check_username", {username}, result.data, result.isFetching, result.error);
    }
    async checkAllowlist(username: string, result: ApiRes<CheckAllowlistResponse>) : Promise<void> {
        return this.handleAPICall("/check_allowlist", {username}, result.data, result.isFetching, result.error);
    }
    async create_user(username: string, password: string, result: ApiRes<CreateUserResponse>) : Promise<void> {
        return sha256(password).then((prehashed: string) => {
            return this.handleAPICall("/create_user", {username, prehashed}, result.data, result.isFetching, result.error);
        })
    }
    async login(username: string, password: string, result: ApiRes<LoginResponse>) : Promise<void> {
        return sha256(password).then((prehashed: string) => {
            return this.handleAPICall("/login", {username, prehashed}, result.data, result.isFetching, result.error);
        })
    }
    async update_user(result: ApiRes<UpdateUserResponse>, partialUserSettings: Partial<UserSettings>): Promise<void> {
        const updateData: Partial<UserSettings> = {};
        for (const [key, value] of Object.entries(partialUserSettings)) {
            if (value !== undefined) {
                //@ts-ignore
                updateData[key as keyof UserSettings] = value;
            }
        }
        return this.handleAPICall("/update_user", updateData, result.data, result.isFetching, result.error);
    }
    async deleteUser(prehashed: string, result: ApiRes<DeleteUserResponse>) : Promise<void> {
        return this.handleAPICall("/delete_user", {prehashed}, result.data, result.isFetching, result.error);
    }
    
    async logout(result: ApiRes<LogoutResponse>) : Promise<void> {
        return this.handleAPICall("/logout", {}, result.data, result.isFetching, result.error);
    }

     async getUser(result: ApiRes<GetUserResponse>) : Promise<void> {
        return this.handleAPICall("/get_user", {}, result.data, result.isFetching, result.error);
    }
    async getAvailableAgentTemplates(result: ApiRes<GetAvailableAgentTemplatesResponse>) : Promise<void> {
        return this.handleAPICall("/get_available_agent_templates", {}, result.data, result.isFetching, result.error);
    }
    async checkSentence(sentence: string, previousMessages: Message[], result: ApiRes<CheckSentenceResponse>) : Promise<void> {
        return this.handleAPICall("/check_sentence", {sentence, previousMessages}, result.data, result.isFetching, result.error);
    }
    async getLeaderboard(result: ApiRes<GetLeaderboardResponse>) : Promise<void> {
        return this.handleAPICall("/get_leaderboard", {}, result.data, result.isFetching, result.error);
    }
    async getUserUsage(result: ApiRes<GetUserUsageResponse>) : Promise<void> {
        return this.handleAPICall("/get_user_usage", {}, result.data, result.isFetching, result.error);
    }
    async startSession(result: ApiRes<StartSessionResponse>): Promise<void> {
        return this.handleAPICall("/start_session", {}, result.data, result.isFetching, result.error);
    }
    async subscribe(priceId: string, result: ApiRes<SubscribeResponse>): Promise<void> {
        return this.handleAPICall("/subscribe", {priceId}, result.data, result.isFetching, result.error);
    }
    async createStripePortalSession(result: ApiRes<CreateStripePortalSessionResponse>): Promise<void> {
        return this.handleAPICall("/create_stripe_portal_session", {}, result.data, result.isFetching, result.error);
    }
    async getAgents(result: ApiRes<GetAgentsResponse>, sorted: boolean = true): Promise<void> {
        return this.handleAPICall("/get_agents", { sorted }, result.data, result.isFetching, result.error);
    }
    async getAgentMessages(result: ApiRes<GetAgentMessagesResponse>, agentId: string) : Promise<void> {
        return this.handleAPICall("/get_agent_messages", {agentId}, result.data, result.isFetching, result.error);
    }
    async getLanguageSettings(result: ApiRes<GetLanguageSettingsResponse>, historical: boolean = false): Promise<void> {
        return this.handleAPICall("/get_language_settings", {historical}, result.data, result.isFetching, result.error);
    }
    async getLanguageSettingsForLanguage(language: string, result: ApiRes<GetLanguageSettingsResponse>): Promise<void> {
        return this.handleAPICall("/get_language_settings_for_language", {language: language}, result.data, result.isFetching, result.error);
    }
    async updateLanguageSettings(result: ApiRes<UpdateLanguageSettingsResponse>, data: {
        language: string;
        formalness?: string;
        proficiencyLevel?: string;
        tutorLanguageUsage?: string;
        grammarCorrection?: string;
        savedWordsPracticeStyles?: string[];
    }): Promise<void> {
        return this.handleAPICall("/update_language_settings", data, result.data, result.isFetching, result.error);
    }
    async getLocalizedKickstarts(result: ApiRes<GetLocalizedKickstartsResponse>): Promise<void> {
        return this.handleAPICall("/get_localized_kickstarts", {}, result.data, result.isFetching, result.error);
    }
    async correctMessage(previousMessages: string[], message: string, language: string, result: ApiRes<CorrectMessageResponse>): Promise<void> {
        return this.handleAPICall("/correct_message", {previousMessages, message, language}, result.data, result.isFetching, result.error);
    }
    async flagMessage(messageId: string, result: ApiRes<FlagMessageResponse>): Promise<void> {
        return this.handleAPICall("/flag_message", {messageId}, result.data, result.isFetching, result.error);
    }
    async addWord(messageId: string, word: string, occurranceIndex: number, language: string, result: ApiRes<AddWordResponse>): Promise<void> {
        return this.handleAPICall("/add_word", {messageId, word, occurranceIndex, language}, result.data, result.isFetching, result.error);
    }
    async getWords(getDefinitions: boolean, language: string, result: ApiRes<GetWordsResponse>): Promise<void> {
        return this.handleAPICall("/get_words", {getDefinitions, language}, result.data, result.isFetching, result.error);
    }
    async deleteWord(wordId: number, result: ApiRes<DeleteWordResponse>): Promise<void> {
        return this.handleAPICall("/delete_word", {wordId}, result.data, result.isFetching, result.error);
    }
    async resetPassword(email: string, result: ApiRes<ResetPasswordResponse>): Promise<void> {
        return this.handleAPICall("/reset_password", {email}, result.data, result.isFetching, result.error);
    }
    async loginWithCode(email: string, code: string, result: ApiRes<LoginResponse>): Promise<void> {
        return this.handleAPICall("/login_with_code", {email, code}, result.data, result.isFetching, result.error);
    }
    async getDueCards(result: ApiRes<GetDueCardsResponse>): Promise<void> {
        return this.handleAPICall("/get_due_cards", {}, result.data, result.isFetching, result.error);
    }
    async rateCard(cardId: number, rating: number, reviewDuration: number, result: ApiRes<RateCardResponse>): Promise<void> {
        return this.handleAPICall("/rate_card", {cardId, rating, reviewDuration}, result.data, result.isFetching, result.error);
    }
    async setCardsEnabled(cardIds: number[] = [], wordIds: number[] = [], enabled: boolean, result: ApiRes<SetCardsEnabledResponse>): Promise<void> {
        return this.handleAPICall("/set_cards_enabled", {cardIds, wordIds, enabled}, result.data, result.isFetching, result.error);
    }
    async updateWord(wordId: number, wordData: Partial<Word>, result: ApiRes<UpdateWordResponse>): Promise<void> {
        return this.handleAPICall("/update_word", { wordId, ...wordData }, result.data, result.isFetching, result.error);
    }
    async addWords(words: AddWordEntry[], result: ApiRes<AddWordsResponse>): Promise<void> {
        return this.handleAPICall("/add_words", { words }, result.data, result.isFetching, result.error);
    }
}
export {
    PracticeAPI,
}
