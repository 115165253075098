<script setup lang="ts">
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'


import { Button } from '@/components/ui/button'
import { Ellipsis, Settings, LogOut, MessageSquareText, Smartphone, MessageCircleHeart} from 'lucide-vue-next'
import { ref } from 'vue'
import type { Ref } from 'vue'
import { apiRes, PracticeAPI, type GetUserResponse, type LogoutResponse } from '@/practice/api'
import { useRouter } from 'vue-router'
import { domainPort, protocol } from '@/url'
const router = useRouter();
const API: PracticeAPI = new PracticeAPI(protocol + domainPort);
const installPrompt = ref<Event | null>(null);
import { PhCaretDown, PhChats, PhUserCircle, PhDeviceMobile, PhMedal, PhHandWaving, PhGearSix, PhListPlus, PhDiscordLogo} from "@phosphor-icons/vue";

interface Props {
  pageStrings: { [key: string]: string },
  getUserResult: {
    data: Ref<GetUserResponse | null>
    isFetching: Ref<boolean>
    error: Ref<number | null>
  }
  preLogout: () => void,
  postLogout?: () => void,
  clickSavedWords?: () => void
}

async function goToEmail() {
  router.push("/email");
}

async function goToSettings() {
  router.push("/settings");
}

const props = defineProps<Props>()

import { onMounted } from 'vue';

onMounted(() => {
  window.addEventListener("beforeinstallprompt", (event) => {

    event.preventDefault();
    installPrompt.value = event;
    console.log("installPrompt", installPrompt.value);
  });
});


const logout = async () => {
  await props.preLogout();
  const logoutResult = await apiRes<LogoutResponse>();
  await API.logout(logoutResult);
  if (props.postLogout) {
    await props.postLogout();
  }
  router.push("/");
}
const goToHistory = async () => {
  router.push("/history");
}

const goToSubscribe = async () => {
  router.push("/subscribe");
}


async function installApp() {
  // window.location.href = "https://apps.apple.com/us/app/issen/id6444280289";
  console.log("Installing app");
  if (installPrompt.value) {
    // @ts-ignore
    const result = await installPrompt.value.prompt();
    console.log(`Install prompt was: ${result?.outcome}`);
    installPrompt.value = null;
  }
}

async function submitFeedback() {
  // const whatsappUrl = 'https://wa.me/+18574006779';
  const discordUrl = 'https://www.issen.com/discord';
  window.open(discordUrl, '_blank');
}

</script>

<style scoped> 
.border-badge {
  border: 0.5px solid var(--border-base, #E5E7EB);
}
</style>

<template>
  <DropdownMenu class="z-[9999]">
      <DropdownMenuTrigger class="border-0" as-child>

        <Button class="flex flex-row w-fit h-fit rounded-md p-0.5 border-0 bg-bg-base shadow-card-rest hover:bg-bg-base-hover" :disabled="!getUserResult.data.value">
            <span class="text-fg-subtle text-custom-lg bg-bg-component border-[0.5px] w-[3.5rem] px-[1rem] py-[0.8rem] rounded-10 hover:bg-blue">
              {{ (getUserResult.data.value && getUserResult.data.value.user.name.length > 0) ? getUserResult.data.value.user.name.substring(0, 1).toUpperCase() : "U" }}
            </span>
            <PhCaretDown class="text-fg-muted w-4 h-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent class="z-[9999] min-w-[14rem] md:min-w-[18.75rem] bg-bg-base shadow-flyout rounded-sm" align="end" v-if="getUserResult.data.value && getUserResult.data.value.user.userStatus !== 'TRIAL'">
        <DropdownMenuItem class="flex flex-col gap-0 items-start hover:bg-bg-base ">
          <DropdownMenuLabel class="text-md text-fg-subtle py-0 px-0">
            {{ getUserResult.data.value.user.username }}
          </DropdownMenuLabel>
        <DropdownMenuLabel class="text-md text-fg-base py-0 px-0">
          {{ getUserResult.data.value.user.name}}
        </DropdownMenuLabel>
        </DropdownMenuItem>
        <DropdownMenuSeparator class="" v-if="getUserResult.data.value && installPrompt" />
        <DropdownMenuItem v-if="getUserResult.data.value && installPrompt" @click="installApp" class="cursor-pointer">
          <PhDeviceMobile class="text-fg-subtle w-[1.25rem] h-[1.25rem]" />
          <span class="flex items-center">{{ pageStrings.addHomeScreen }}</span>
        </DropdownMenuItem>
        <DropdownMenuSeparator v-if="getUserResult.data.value" />
        <DropdownMenuItem v-if="getUserResult.data.value" @click="goToSettings" class="cursor-pointer">
          <PhGearSix class="text-fg-subtle w-[1.25rem] h-[1.25rem]" />
          <span class="flex items-center">{{ pageStrings.settings }}</span>
        </DropdownMenuItem>
        <DropdownMenuItem v-if="getUserResult.data.value" @click="goToHistory" class="cursor-pointer">
          <PhChats class="text-fg-subtle w-[1.25rem] h-[1.25rem]" />
          <span class="flex items-center">{{ pageStrings.goToHistory }}</span>
        </DropdownMenuItem>
        <DropdownMenuItem v-if="getUserResult.data.value" @click="clickSavedWords" class="cursor-pointer">
          <PhListPlus class="text-fg-subtle w-[1.25rem] h-[1.25rem]" />
          <span class="flex items-center">{{ pageStrings.savedWords }}</span>
        </DropdownMenuItem>
        <DropdownMenuItem v-if="getUserResult.data.value" @click="submitFeedback" class="cursor-pointer">
          <PhDiscordLogo class="text-fg-subtle w-[1.25rem] h-[1.25rem]" />
          <span class="flex items-center">{{ pageStrings.sendFeedback }}</span>
        </DropdownMenuItem>
        <DropdownMenuSeparator v-if="getUserResult.data.value && getUserResult.data.value.user.userStatus !== 'SUBSCRIBED'" />
        <DropdownMenuItem v-if="getUserResult.data.value && getUserResult.data.value.user.userStatus !== 'SUBSCRIBED'" @click="goToSubscribe" class="cursor-pointer">
          <PhMedal class="text-fg-subtle w-[1.25rem] h-[1.25rem]" />
          <span class="flex items-center">{{ pageStrings.subscribe }}</span>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem v-if="getUserResult.data.value" @click="logout" class="cursor-pointer">
          <PhHandWaving class="text-fg-subtle w-[1.25rem] h-[1.25rem]" />
          <span class="flex items-center">{{ pageStrings.logout }}</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
      <DropdownMenuContent class="z-[9999] min-w-[14rem] md:min-w-[18.75rem] bg-bg-base shadow-flyout rounded-sm" align="end" v-if="getUserResult.data.value && getUserResult.data.value.user.userStatus === 'TRIAL'">
        <DropdownMenuItem v-if="getUserResult.data.value" @click="goToEmail" class="cursor-pointer">
          <PhUserCircle class="text-fg-subtle w-[1.25rem] h-[1.25rem]" />
          <span class="flex items-center">{{ pageStrings.createAccount }}</span>
        <DropdownMenuSeparator v-if="getUserResult.data.value" />
        </DropdownMenuItem>
        <DropdownMenuItem v-if="getUserResult.data.value && installPrompt" @click="installApp" class="cursor-pointer">
          <PhDeviceMobile class="text-fg-subtle w-[1.25rem] h-[1.25rem]" />
          <span class="flex items-center">{{ pageStrings.addHomeScreen }}</span>
        </DropdownMenuItem>
        <DropdownMenuItem v-if="getUserResult.data.value" @click="clickSavedWords" class="cursor-pointer">
          <PhListPlus class="text-fg-subtle w-[1.25rem] h-[1.25rem]" />
          <span class="flex items-center">{{ pageStrings.savedWords }}</span>
        </DropdownMenuItem>
        <DropdownMenuItem v-if="getUserResult.data.value" @click="goToSettings" class="cursor-pointer">
          <PhGearSix class="text-fg-subtle w-[1.25rem] h-[1.25rem]" />
          <span class="flex items-center">{{ pageStrings.settings }}</span>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem v-if="getUserResult.data.value" @click="submitFeedback" class="cursor-pointer">
          <PhDiscordLogo class="text-fg-subtle w-[1.25rem] h-[1.25rem]" />
          <span class="flex items-center">{{ pageStrings.sendFeedback }}</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
</template>

