export let domainPort = "";
export let htmlDomainPort = "";
export let protocol: string = "";
export let static_path = "";
export let wsProtocol = "";
export const buildEnv = import.meta.env.VITE_ISSEN_ENV;

if (typeof import.meta.hot !== "undefined") {
    htmlDomainPort = "localhost:5173"
    domainPort = "localhost:5000";
    protocol = "http://";
    static_path = "/dist/assets";
    wsProtocol = "ws://"
} else if (buildEnv === 'local') {
    htmlDomainPort = "localhost:5000";
    domainPort = "localhost:5000";
    protocol = "http://";
    static_path = "/assets";
    wsProtocol = "ws://"
} else if (buildEnv === "development") {
    htmlDomainPort = "dev.issen.com";
    domainPort = "dev.issen.com"
    protocol = "https://";
    static_path = "/assets"
    wsProtocol = "wss://"
} else if (buildEnv === "production") {
    htmlDomainPort = "app.issen.com";
    domainPort = "app.issen.com"
    protocol = "https://";
    static_path = "/assets"
    wsProtocol = "wss://"
}