<script setup lang="ts">
import { ref, computed } from 'vue';

const props = defineProps<{
  isVisible: boolean;
}>();

// Restore original opacity
const opacity = computed(() => props.isVisible ? 0.7 : 0);
</script>

<template>
  <div class="colorful-background-container" :class="{ 'visible': isVisible, 'hidden': !isVisible }">
    <div class="background-content">
      <div class="blob blob-blue"></div>
      <div class="blob blob-amber"></div>
      <div class="blob blob-pink"></div>
    </div>
  </div>
</template>

<style scoped>
.colorful-background-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.background-content {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 1400px; /* Cap width for large screens */
}

.visible {
  opacity: 1;
  transition: opacity 2s ease;
}

.hidden {
  opacity: 0;
  /* Longer fade-out transition */
  transition: opacity 8s ease;
}

.blob {
  position: absolute;
  border-radius: 50%;
  filter: blur(120px);
  transform-origin: center center;
  will-change: transform, opacity;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.blob-blue {
  /* Ultra-smooth gradient with many more steps to eliminate visible bands */
  background: radial-gradient(
    circle, 
    rgba(59, 130, 246, 0.9) 0%, 
    rgba(59, 130, 246, 0.85) 1%,
    rgba(59, 130, 246, 0.8) 2%,
    rgba(59, 130, 246, 0.75) 3%,
    rgba(59, 130, 246, 0.7) 4%,
    rgba(59, 130, 246, 0.65) 5%,
    rgba(59, 130, 246, 0.6) 6%,
    rgba(59, 130, 246, 0.55) 8%,
    rgba(59, 130, 246, 0.5) 10%,
    rgba(59, 130, 246, 0.45) 12%,
    rgba(59, 130, 246, 0.4) 14%,
    rgba(59, 130, 246, 0.38) 16%,
    rgba(59, 130, 246, 0.36) 18%,
    rgba(59, 130, 246, 0.34) 20%,
    rgba(59, 130, 246, 0.32) 22%,
    rgba(59, 130, 246, 0.3) 25%,
    rgba(59, 130, 246, 0.28) 28%,
    rgba(59, 130, 246, 0.26) 32%,
    rgba(59, 130, 246, 0.24) 36%,
    rgba(59, 130, 246, 0.22) 40%,
    rgba(59, 130, 246, 0.2) 45%,
    rgba(59, 130, 246, 0.18) 50%,
    rgba(59, 130, 246, 0.16) 55%,
    rgba(59, 130, 246, 0.14) 60%,
    rgba(59, 130, 246, 0.12) 65%,
    rgba(59, 130, 246, 0.1) 70%,
    rgba(59, 130, 246, 0.08) 75%,
    rgba(59, 130, 246, 0.06) 80%,
    rgba(59, 130, 246, 0.04) 85%,
    rgba(59, 130, 246, 0.02) 90%,
    rgba(59, 130, 246, 0.01) 95%,
    rgba(59, 130, 246, 0) 100%
  );
  /* Position blob with center lower on the screen */
  width: 80%;
  height: 80%;
  left: 10%;
  top: 15%;
  opacity: v-bind('opacity');
  animation: move-blue 30s infinite alternate ease-in-out;
}

.blob-amber {
  /* Ultra-smooth gradient with many more steps to eliminate visible bands */
  background: radial-gradient(
    circle, 
    rgba(245, 158, 11, 0.9) 0%, 
    rgba(245, 158, 11, 0.85) 1%,
    rgba(245, 158, 11, 0.8) 2%,
    rgba(245, 158, 11, 0.75) 3%,
    rgba(245, 158, 11, 0.7) 4%,
    rgba(245, 158, 11, 0.65) 5%,
    rgba(245, 158, 11, 0.6) 6%,
    rgba(245, 158, 11, 0.55) 8%,
    rgba(245, 158, 11, 0.5) 10%,
    rgba(245, 158, 11, 0.45) 12%,
    rgba(245, 158, 11, 0.4) 14%,
    rgba(245, 158, 11, 0.38) 16%,
    rgba(245, 158, 11, 0.36) 18%,
    rgba(245, 158, 11, 0.34) 20%,
    rgba(245, 158, 11, 0.32) 22%,
    rgba(245, 158, 11, 0.3) 25%,
    rgba(245, 158, 11, 0.28) 28%,
    rgba(245, 158, 11, 0.26) 32%,
    rgba(245, 158, 11, 0.24) 36%,
    rgba(245, 158, 11, 0.22) 40%,
    rgba(245, 158, 11, 0.2) 45%,
    rgba(245, 158, 11, 0.18) 50%,
    rgba(245, 158, 11, 0.16) 55%,
    rgba(245, 158, 11, 0.14) 60%,
    rgba(245, 158, 11, 0.12) 65%,
    rgba(245, 158, 11, 0.1) 70%,
    rgba(245, 158, 11, 0.08) 75%,
    rgba(245, 158, 11, 0.06) 80%,
    rgba(245, 158, 11, 0.04) 85%,
    rgba(245, 158, 11, 0.02) 90%,
    rgba(245, 158, 11, 0.01) 95%,
    rgba(245, 158, 11, 0) 100%
  );
  /* Position blob with center lower on the screen */
  width: 75%;
  height: 75%;
  left: 12.5%;
  top: 20%;
  opacity: v-bind('opacity');
  animation: move-amber 33s infinite alternate-reverse ease-in-out;
}

.blob-pink {
  /* Ultra-smooth gradient with many more steps to eliminate visible bands */
  background: radial-gradient(
    circle, 
    rgba(236, 72, 153, 0.9) 0%, 
    rgba(236, 72, 153, 0.85) 1%,
    rgba(236, 72, 153, 0.8) 2%,
    rgba(236, 72, 153, 0.75) 3%,
    rgba(236, 72, 153, 0.7) 4%,
    rgba(236, 72, 153, 0.65) 5%,
    rgba(236, 72, 153, 0.6) 6%,
    rgba(236, 72, 153, 0.55) 8%,
    rgba(236, 72, 153, 0.5) 10%,
    rgba(236, 72, 153, 0.45) 12%,
    rgba(236, 72, 153, 0.4) 14%,
    rgba(236, 72, 153, 0.38) 16%,
    rgba(236, 72, 153, 0.36) 18%,
    rgba(236, 72, 153, 0.34) 20%,
    rgba(236, 72, 153, 0.32) 22%,
    rgba(236, 72, 153, 0.3) 25%,
    rgba(236, 72, 153, 0.28) 28%,
    rgba(236, 72, 153, 0.26) 32%,
    rgba(236, 72, 153, 0.24) 36%,
    rgba(236, 72, 153, 0.22) 40%,
    rgba(236, 72, 153, 0.2) 45%,
    rgba(236, 72, 153, 0.18) 50%,
    rgba(236, 72, 153, 0.16) 55%,
    rgba(236, 72, 153, 0.14) 60%,
    rgba(236, 72, 153, 0.12) 65%,
    rgba(236, 72, 153, 0.1) 70%,
    rgba(236, 72, 153, 0.08) 75%,
    rgba(236, 72, 153, 0.06) 80%,
    rgba(236, 72, 153, 0.04) 85%,
    rgba(236, 72, 153, 0.02) 90%,
    rgba(236, 72, 153, 0.01) 95%,
    rgba(236, 72, 153, 0) 100%
  );
  /* Position blob with center lower on the screen */
  width: 70%;
  height: 70%;
  left: 15%;
  top: 25%;
  opacity: v-bind('opacity');
  animation: move-pink 35s infinite alternate ease-in-out;
}

@keyframes move-blue {
  0% {
    transform: translate3d(-10%, -10%, 0) scale(0.85);
  }
  33% {
    transform: translate3d(5%, 15%, 0) scale(1);
  }
  66% {
    transform: translate3d(15%, -5%, 0) scale(0.95);
  }
  100% {
    transform: translate3d(-5%, 10%, 0) scale(1.05);
  }
}

@keyframes move-amber {
  0% {
    transform: translate3d(10%, 10%, 0) scale(0.85);
  }
  33% {
    transform: translate3d(-15%, 5%, 0) scale(0.9);
  }
  66% {
    transform: translate3d(-5%, -15%, 0) scale(1.05);
  }
  100% {
    transform: translate3d(15%, -10%, 0) scale(0.95);
  }
}

@keyframes move-pink {
  0% {
    transform: translate3d(0%, -15%, 0) scale(0.85);
  }
  33% {
    transform: translate3d(-10%, 5%, 0) scale(1.05);
  }
  66% {
    transform: translate3d(15%, 10%, 0) scale(0.9);
  }
  100% {
    transform: translate3d(-5%, -5%, 0) scale(1);
  }
}
</style> 