<script setup lang="ts">
import { ref, onMounted, computed, watch } from 'vue'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogClose,
  DialogHeader,
  DialogTitle,
  DialogDescription
} from '@/components/ui/dialog'
import { apiRes, PracticeAPI } from './api'
import { domainPort, protocol } from '@/url'
import type { AddWordsResponse, DeleteWordResponse, GetWordsResponse, SetCardsEnabledResponse, UpdateWordResponse } from './api'
import { PhPlayCircle,  PhPauseCircle, PhX, PhPlusCircle, PhTrash, PhSpinnerBall, PhPencilSimple, PhCheck, PhPlus } from "@phosphor-icons/vue";
import { boldedSentence } from '@/practice/util';
import { Checkbox } from '@/components/ui/checkbox'
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
const props = defineProps<{
  open: boolean,
  pageStrings: { [key: string]: string },
  updatedSavedWords: (getDefinitions: boolean) => void,
  getWordsResult: GetWordsResponse | null,
  loading: boolean
}>()

const emit = defineEmits<{
  (e: 'update:open', value: boolean): void
}>()

const searchQuery = ref('')
const selectedWord = ref<string | null>(null)
const selectedWordId = ref<number | null>(null)
const isAddingWord = ref(false)
const additionalWords = ref<Array<{ word: string, sentence: string }>>([{ word: '', sentence: '' }])
const showValidationErrors = ref(false)

// Store the deleteWordResult at component level so we can access isFetching
const deleteWordResult = apiRes<DeleteWordResponse>()
const updateWordResult = apiRes<UpdateWordResponse>()
const isEditing = ref(false)
const editedWord = ref<{
  word: string,
  wordWithTransliteration: string,
  sentence: string,
  shortDefinitionSourceLang: string,
  shortDefinitionTargetLang: string,
  longDefinitionSourceLang: string,
  longDefinitionTargetLang: string
} | null>(null)

// Audio state tracking
const currentAudio = ref<HTMLAudioElement | null>(null)
const isWordAudioPlaying = ref(false)
const isSentenceAudioPlaying = ref(false)
const currentAudioType = ref<'word' | 'sentence' | null>(null)
const cardEnabled = ref(true)
const noWordsToAdd = ref(false)
const API: PracticeAPI = new PracticeAPI(protocol + domainPort)
const addWordsResult = apiRes<AddWordsResponse>()

// Watch for changes to cardEnabled and update the server
const setCardEnabled = async (newValue: boolean) => {
  const setCardEnabledResult = apiRes<SetCardsEnabledResponse>()
  if (selectedWordId.value) {
    await API.setCardsEnabled([], [selectedWordId.value], newValue, setCardEnabledResult)
  }
  cardEnabled.value = newValue
  if (props.getWordsResult) {
    // Update all cards associated with this word
    const cards = props.getWordsResult.cards?.filter((card: any) => card.wordId === selectedWordId.value)
    if (cards && cards.length > 0) {
      cards.forEach(card => {
        card.enabled = newValue;
      })
    }
  }
}

// Replace the existing watch block with a single watch for additionalWords
watch(additionalWords, () => {
  // If validation is showing and all words are valid, hide validation errors
  if (showValidationErrors.value && 
      !additionalWords.value.some(item => !validateWordAndSentence(item.word, item.sentence))) {
    showValidationErrors.value = false
  }
}, { deep: true })

onMounted(async () => {
})

watch(() => props.open, async (newValue: any) => {
  if (newValue) {
    props.updatedSavedWords(true)
    searchQuery.value = ''
  } else {
    // Stop audio when dialog is closed
    stopAudio()
  }
})

// Stop audio when selecting a different word
watch(() => selectedWordId.value, () => {
  stopAudio()
  isEditing.value = false
})

const selectWord = async (word: string, wordId: number) => {
  // If we're in adding word mode, exit it but keep the data for next time
  if (isAddingWord.value) {
    isAddingWord.value = false
    // We keep additionalWords as is to preserve data
  }

  selectedWord.value = word
  selectedWordId.value = wordId
  
  // Fix type issues by properly typing the response and card
  if (props.getWordsResult) {
    const cardsForThisWord = props.getWordsResult.cards?.filter((card: any) => card.wordId === wordId)
    // Check if any card for this word is enabled
    const enabledCard = cardsForThisWord?.find((card: { enabled: boolean }) => card.enabled)
    cardEnabled.value = enabledCard !== undefined ? true : false
  } else {
    cardEnabled.value = false
  }
}

const filteredWords = computed(() => {
  if (!props.getWordsResult) return []
  return props.getWordsResult.words
    .filter(word => 
      word.word.toLowerCase().includes(searchQuery.value.toLowerCase())
    )
    .sort((a, b) => b.addedTime - a.addedTime) // Sort by newest first
})

const selectedWordInfo = computed(() => {
  if (!props.getWordsResult) return null;
  return props.getWordsResult.words.find(word => word.id === selectedWordId.value);
})

const deleteWord = async (wordId: number) => {
  await API.deleteWord(wordId, deleteWordResult)
  selectedWord.value = null
  props.updatedSavedWords(true)
}

const parsedWordWithTransliteration = computed(() => {
  if (!selectedWordInfo.value || !selectedWordInfo.value.wordWithTransliteration) return null
  
  const wordWithTransliteration = selectedWordInfo.value.wordWithTransliteration
  
  // Check if there are parentheses in the string
  const match = wordWithTransliteration.match(/^(.*?)\s*\((.*?)\)\s*$/)
  
  if (match) {
    // Return an array with [word, transliteration]
    return [match[1].trim(), match[2].trim()]
  } else {
    // If no parentheses, return the word as is and null for transliteration
    return [wordWithTransliteration, null]
  }
})

const toggleAudio = (url: string, type: 'word' | 'sentence') => {
  // If we have a current audio and it's the same type
  if (currentAudio.value && currentAudioType.value === type) {
    if (type === 'word' ? isWordAudioPlaying.value : isSentenceAudioPlaying.value) {
      // Pause the audio
      currentAudio.value.pause()
      if (type === 'word') {
        isWordAudioPlaying.value = false
      } else {
        isSentenceAudioPlaying.value = false
      }
    } else {
      // Resume the audio
      currentAudio.value.play()
      if (type === 'word') {
        isWordAudioPlaying.value = true
      } else {
        isSentenceAudioPlaying.value = true
      }
    }
  } else {
    // Stop any currently playing audio
    stopAudio()
    
    // Create and play new audio
    const audio = new Audio(url)
    currentAudio.value = audio
    currentAudioType.value = type
    
    // Set up event listeners
    audio.addEventListener('ended', () => {
      if (type === 'word') {
        isWordAudioPlaying.value = false
      } else {
        isSentenceAudioPlaying.value = false
      }
      currentAudio.value = null
      currentAudioType.value = null
    })
    
    // Play the audio
    audio.play()
    if (type === 'word') {
      isWordAudioPlaying.value = true
    } else {
      isSentenceAudioPlaying.value = true
    }
  }
}

const stopAudio = () => {
  if (currentAudio.value) {
    currentAudio.value.pause()
    currentAudio.value = null
  }
  isWordAudioPlaying.value = false
  isSentenceAudioPlaying.value = false
  currentAudioType.value = null
}

const editWord = () => {
  if (!selectedWordInfo.value) return
  
  // Initialize the editedWord with current values
  editedWord.value = {
    word: selectedWordInfo.value.word,
    wordWithTransliteration: selectedWordInfo.value.wordWithTransliteration,
    sentence: selectedWordInfo.value.sentence,
    shortDefinitionSourceLang: selectedWordInfo.value.shortDefinitionSourceLang,
    shortDefinitionTargetLang: selectedWordInfo.value.shortDefinitionTargetLang,
    longDefinitionSourceLang: selectedWordInfo.value.longDefinitionSourceLang,
    longDefinitionTargetLang: selectedWordInfo.value.longDefinitionTargetLang
  }
  
  isEditing.value = true
}

const saveWordEdit = async () => {
  if (!selectedWordInfo.value || !editedWord.value) return
  
  await API.updateWord(
    selectedWordInfo.value.id, 
    editedWord.value, 
    updateWordResult
  )
  
  isEditing.value = false
  props.updatedSavedWords(true) // Refresh the word list
}


const startAddingWord = () => {
  isAddingWord.value = true
  selectedWord.value = null
  selectedWordId.value = null
  showValidationErrors.value = false
}


const hasInvalidSentences = computed(() => {
  // Check all words for invalid sentences
  return additionalWords.value.some(({ word, sentence }) => 
    word && sentence && !sentence.toLowerCase().includes(word.toLowerCase())
  )
})

const hasEmptyWords = computed(() => {
  // Check if any word is empty
  return additionalWords.value.some(item => showValidationErrors.value && !item.word && item.sentence)
})


const isSentenceInvalid = (word: string, sentence: string) => {
  return word && sentence && !sentence.toLowerCase().includes(word.toLowerCase())
}

const isWordEmpty = (word: string, sentence: string) => {
  return showValidationErrors.value && (!word || word.trim() === '') && sentence && sentence.trim() !== ''
}

const validateWordAndSentence = (word: string, sentence: string): boolean => {
  // Check if word is empty
  if (!word || word.trim() === '') {
    return false
  }
  
  // If sentence is provided, make sure it contains the word
  if (sentence && !sentence.toLowerCase().includes(word.toLowerCase())) {
    return false
  }
  
  return true
}

const addMoreWords = () => {
  // Show validation errors when trying to add more words
  showValidationErrors.value = true
  
  // Validate all existing words and sentences first
  for (const entry of additionalWords.value) {
    // Check for empty words
    if ((!entry.word || entry.word.trim() === '') && entry.sentence && entry.sentence.trim() !== '') {
      console.log('entry.word', entry.word, 'entry.sentence', entry.sentence.trim() !== '')
      return // Return early if any word is empty
    }
    
    // Check if sentence contains word
    if (entry.sentence && !entry.sentence.toLowerCase().includes(entry.word.toLowerCase())) {
      return // Return early if any sentence doesn't contain its word
    }
  }
  
  // If all existing entries are valid, add a new empty row
  additionalWords.value.push({ word: '', sentence: '' })
  showValidationErrors.value = false
}

const confirmAddWord = async () => {
  // Show validation errors when confirming
  showValidationErrors.value = true
  
  // Filter out empty rows
  const wordsToAdd = additionalWords.value.filter(item => item.word.trim() !== '')
  
  // If no words at all, don't proceed
  if (wordsToAdd.length === 0) {
    noWordsToAdd.value = true
    return
  }
  
  // Validate all words and sentences
  for (const entry of additionalWords.value) {
    // Skip completely empty rows (empty word AND sentence)
    if (!entry.word.trim() && !entry.sentence.trim()) {
      continue
    }
    
    // Check for empty words
    if ((!entry.word || entry.word.trim() === '') && entry.sentence && entry.sentence.trim() !== '') {
      return // Return early if any word is empty (but sentence has content)
    }
    
    // Check if sentence contains word
    if (entry.sentence && !entry.sentence.toLowerCase().includes(entry.word.toLowerCase())) {
      return // Return early if any sentence doesn't contain its word
    }
  }

  showValidationErrors.value = false
  try {
    const nonEmptyWords = additionalWords.value.filter(item => item.word.trim() !== '')
    // For each word in wordsToAdd, call the API
    console.log('nonEmptyWords', nonEmptyWords)
    await API.addWords(nonEmptyWords, addWordsResult)
    await new Promise(resolve => setTimeout(resolve, 1000)) // Simulated API call
  } finally {
    additionalWords.value = [{ word: '', sentence: '' }]
    isAddingWord.value = false
    showValidationErrors.value = false
    props.updatedSavedWords(true)
  }
}

// Add a new function to delete a specific row
const deleteWordRow = (index: number) => {
  // Prevent deleting the last row if it's the only one
  if (additionalWords.value.length === 1) {
    // Just clear the fields instead
    additionalWords.value[0] = { word: '', sentence: '' }
  } else {
    // Remove the row at the specified index
    additionalWords.value.splice(index, 1)
  }
  
  // Clear validation errors since the form changed
  showValidationErrors.value = false
}

</script>

<template>
  <Dialog :open="open" @update:open="emit('update:open', $event)">
    <DialogContent class="w-[95%] md:max-w-[700px] rounded-xl h-[84%] p-0 grid-cols-[150px_1fr] md:grid-cols-[300px_1fr] bg-bg-subtle-hover [&>button]:hidden">
      <!-- Left Column - Word List -->
      <div class="ml-0 md:ml-0 bg-bg-base h-full flex flex-col min-h-0 rounded-md  w-[150px] md:w-auto">
        <div class="p-3 pt-3 border-0 flex flex-col h-full">
        <DialogClose
            @click="emit('update:open', false)"
            class="w-8 h-8 ml-1 rounded-full bg-bg-subtle-hover cursor:pointer hover:bg-bg-component-hover flex items-center justify-center transition-colors"
        >
            <PhX class="w-4 h-4" />
        </DialogClose>
        <div class="flex items-center justify-between mt-3">
          <p class="text-text-base text-xl md:text-hxl ml-1">{{ props.pageStrings.savedWords }} <span class="text-fg-subtle text-xs ml-1">({{ props.getWordsResult?.words.length }})</span></p>
          <button
            @click="startAddingWord"
            class="w-8 h-8 rounded-full bg-bg-subtle-hover cursor:pointer hover:bg-bg-component-hover flex items-center justify-center transition-colors"
            title="Add new word"
          >
            <PhPlus class="w-4 h-4" />
          </button>
        </div>
          <Input 
            type="text"
            v-model="searchQuery"
            :placeholder="pageStrings.search"
            class="w-full ml-0 text-lg mt-2 mb-4 bg-bg-component"
          />
          
          <div class="space-y-1 border-0 overflow-y-auto flex-1 min-h-0">
            <template v-if="props.getWordsResult">
              <div
                v-for="word in filteredWords" 
                :key="word.id"
                class="w-full text-left text-lg p-1 hover:bg-bg-base-hover rounded-sm transition-colors"
                :class="{'bg-bg-base-hover': selectedWordId === word.id}"
              >
                <button
                  @click="selectWord(word.word, word.id)"
                  class="w-full text-left"
                >
                  <div class="flex flex-col">
                    <span class="text-md">{{ word.word }}</span>
                    <span v-if="word.shortDefinitionSourceLang" class="text-xs text-fg-muted mt-1 line-clamp-1">{{ word.shortDefinitionSourceLang }}</span>
                  </div>
                </button>
              </div>
              
              <div v-if="filteredWords.length === 0" 
                   class="text-center text-md text-fg-subtle p-4">
                {{ searchQuery ? pageStrings.noMatchingWordsFound : (loading ? pageStrings.loadingWords : pageStrings.noSavedWordsYet) }}
              </div>
            </template>
          </div>
        </div>
      </div>
      <!-- Right Column - Word Details -->
      <div class="bg-bg-subtle-hover rounded-lg border-0 p-2 md:p-4 lg:p-6 mt-4 w-full max-h-[calc(100vh-100px)] overflow-y-auto">
        <DialogHeader class="text-left">
          <DialogDescription class="text-sm text-fg-subtle">
          </DialogDescription>
          <DialogTitle class="text-xl flex items-center justify-between w-full">
            <div class="flex items-center">
              <span v-if="isAddingWord" class="font-bold text-fg-base">{{ pageStrings.addWord }}</span>
              <span v-else-if="selectedWordInfo && parsedWordWithTransliteration" class="font-bold text-fg-base">
                <span v-html="parsedWordWithTransliteration[0]"
                v-if="selectedWordInfo && parsedWordWithTransliteration">
                </span>
                <span 
                class="text-lg"
                v-if="selectedWordInfo && parsedWordWithTransliteration && parsedWordWithTransliteration[1]">
                  (<span >
                    {{ parsedWordWithTransliteration[1] }}
                  </span>)
                </span>
              </span>
              <span v-else class="font-bold text-fg-base">
                {{ selectedWord || '' }}
              </span>
              
              <!-- Word Audio Button next to title -->
              <span 
                v-if="selectedWordInfo && selectedWordInfo.wordAudioS3Link && !isAddingWord"
                @click="toggleAudio(selectedWordInfo.wordAudioS3Link, 'word')" 
                class="ml-2 p-2 cursor-pointer"
                title="Play word audio"
              >
                <PhPlayCircle v-if="!isWordAudioPlaying" class="w-4 h-4 text-black" />
                <PhPauseCircle v-else class="w-4 h-4 text-black" />
              </span>
            </div>
            
            <!-- Delete and Edit buttons moved to the right of the title -->
            <div class="flex items-center space-x-2">
              <!-- Edit button -->
              <span 
                v-if="selectedWordInfo && !isEditing && !isAddingWord"
                @click="editWord"
                class="text-primary p-2 cursor-pointer"
                title="Edit word"
              >
                <PhPencilSimple class="w-5 h-5" />
              </span>
              
              <!-- Delete button -->
              <span 
                v-if="selectedWordInfo && !isEditing && !isAddingWord"
                @click="deleteWord(selectedWordInfo.id)"
                class="text-red-500 p-2 cursor-pointer"
                :class="{ 'opacity-50 cursor-not-allowed': deleteWordResult.isFetching.value }"
                :aria-disabled="deleteWordResult.isFetching.value"
                title="Delete word"
              >
                <PhSpinnerBall v-if="deleteWordResult.isFetching.value" class="w-5 h-5 animate-spin" />
                <PhTrash v-else class="w-5 h-5" />
              </span>
              
              <!-- Save button (check icon) -->
              <span 
                v-if="isEditing"
                @click="!updateWordResult.isFetching.value && saveWordEdit()"
                class="text-primary p-2 cursor-pointer"
                :class="{ 'opacity-50 cursor-not-allowed': updateWordResult.isFetching.value }"
                :aria-disabled="updateWordResult.isFetching.value"
                title="Save changes"
              >
                <PhSpinnerBall v-if="updateWordResult.isFetching.value" class="w-5 h-5 animate-spin" />
                <PhCheck v-else class="w-5 h-5" />
              </span>
            </div>
          </DialogTitle>
          <!-- Added date moved under the title -->
          <p v-if="selectedWordInfo && !isAddingWord" class="text-xs text-fg-muted mt-1">
            {{ new Date(selectedWordInfo.addedTime * 1000).toLocaleString() }}
          </p>
        </DialogHeader>

        <div class="mt-4" v-if="selectedWord || isAddingWord">
          <!-- Add Word Form -->
          <p v-if="isAddingWord" class="text-sm text-fg-subtle mb-0">{{ pageStrings.enterAWord }}</p>
          <div v-if="isAddingWord" class="space-y-4">
            <!-- Header -->
            <div class="grid grid-cols-[1fr_2fr] gap-4">
              <!-- <span class="text-sm font-medium text-fg-base">{{ pageStrings.word }}</span>
              <span class="text-sm font-medium text-fg-base">{{ pageStrings.sentence }} ({{ pageStrings.optional }})</span> -->
            </div>

            <!-- Additional Words (filled) -->
            <div class="space-y-1 px-0">
              <div v-for="(word, index) in additionalWords" :key="index" class="grid grid-cols-[1fr_2fr_auto] gap-1">
                <Input 
                  :id="'additional-word-' + index"
                  v-model="additionalWords[index].word" 
                  class="w-full font-normal text-fg-base edit-input h-8 m-0 pr-0 pl-2"
                  :class="{ 'border-red-500': isWordEmpty(additionalWords[index].word, additionalWords[index].sentence) }"
                  :placeholder="pageStrings.word"
                />
                <Input 
                  :id="'additional-sentence-' + index"
                  v-model="additionalWords[index].sentence" 
                  class="w-full font-normal text-fg-base edit-input h-8 m-0 pr-0 pl-2"
                  :class="{ 'border-red-500': showValidationErrors && isSentenceInvalid(additionalWords[index].word, additionalWords[index].sentence) }"
                  :placeholder="pageStrings.sentenceOptional"
                />
                <button 
                  @click="deleteWordRow(index)"
                  class="text-red-500 hover:text-red-700 flex items-center justify-center h-8 w-8"
                  :title="pageStrings.deleteWord || 'Delete word'"
                >
                  <PhX class="w-4 h-4" />
                </button>
              </div>
            </div>

            <!-- Action Buttons -->
            <div class="flex flex-col gap-4 mt-4">
              <!-- Error messages -->
              <div v-if="showValidationErrors" class="text-red-500 text-sm space-y-1">
                <div v-if="noWordsToAdd">
                  {{ pageStrings.noWordsToAdd }} 
                </div>
                <div v-if="hasEmptyWords">
                  {{ pageStrings.wordMustNotBeEmpty }}
                </div>
                <div v-if="hasInvalidSentences">
                  {{ pageStrings.sentenceMustContainWord}}
                </div>
              </div>
              
              <!-- Add more link -->
              <div class="flex items-center">
                <button 
                  @click="addMoreWords"
                  class="text-primary flex items-center gap-1 text-sm hover:underline cursor-pointer"
                >
                  <PhPlus class="w-4 h-4" />
                  {{ pageStrings.addMore }}
                </button>
              </div>
              
              <!-- Confirm adding button at the bottom -->
              <div class="flex justify-start mt-4">
                <Button
                  @click="confirmAddWord"
                  class="rounded-md transition-colors flex items-center justify-center gap-2" 
                  variant="primary"
                  :disabled="addWordsResult.isFetching.value || additionalWords.filter(word => word.word.trim() !== '').length === 0"
                >
                  <PhSpinnerBall v-if="addWordsResult.isFetching.value" class="w-4 h-4 animate-spin" />
                  {{ addWordsResult.isFetching.value ? pageStrings.addingWords : pageStrings.confirmAdding }}
                </Button>
              </div>
            </div>
          </div>
          <!-- Existing Word Details -->
          <div v-else-if="props.getWordsResult">
            <div v-if="selectedWordInfo">
              <!-- Edit mode -->
              <div v-if="isEditing && editedWord">
                <div class="mb-4">
                  <Label for="edit-word" class="block text-sm font-medium mb-1 text-fg-base">{{ pageStrings.word || 'Word' }}</Label>
                  <Input 
                    id="edit-word"
                    v-model="editedWord.word" 
                    class="w-full font-normal text-fg-base edit-input"
                  />
                </div>
                <div class="mb-4">
                  <Label for="edit-word-transliteration" class="block text-sm font-medium mb-1 text-fg-base">{{ pageStrings.wordWithTransliteration || 'Word with Transliteration' }}</Label>
                  <Input 
                    id="edit-word-transliteration"
                    v-model="editedWord.wordWithTransliteration" 
                    class="w-full font-normal text-fg-base edit-input"
                  />
                </div>
                
                <div class="mb-4">
                  <Label for="edit-sentence" class="block text-sm font-medium mb-1 text-fg-base">{{ pageStrings.sentence || 'Sentence' }}</Label>
                  <Textarea 
                    id="edit-sentence"
                    v-model="editedWord.sentence" 
                    class="w-full min-h-[80px] font-normal text-fg-base"
                  />
                </div>
                <div class="mb-4">
                  <Label for="edit-short-def-source" class="block text-sm font-medium mb-1 text-fg-base">{{ pageStrings.shortDefinition || 'Short Definition' }} ({{ pageStrings.sourceLang || 'Source Language' }})</Label>
                  <Input 
                    id="edit-short-def-source"
                    v-model="editedWord.shortDefinitionSourceLang" 
                    class="w-full font-normal text-fg-base edit-input"
                  />
                </div>
                
                <div class="mb-4">
                  <Label for="edit-short-def-target" class="block text-sm font-medium mb-1 text-fg-base">{{ pageStrings.shortDefinition || 'Short Definition' }} ({{ pageStrings.targetLang || 'Target Language' }})</Label>
                  <Input 
                    id="edit-short-def-target"
                    v-model="editedWord.shortDefinitionTargetLang" 
                    class="w-full font-normal text-fg-base edit-input"
                  />
                </div>
                
                <div class="mb-4">
                  <Label for="edit-long-def-source" class="block text-sm font-medium mb-1 text-fg-base">{{ pageStrings.longDefinition || 'Long Definition' }} ({{ pageStrings.sourceLang || 'Source Language' }})</Label>
                  <Textarea 
                    id="edit-long-def-source"
                    v-model="editedWord.longDefinitionSourceLang" 
                    class="min-h-[100px] font-normal text-fg-base w-full"
                  />
                </div>
                
                <div class="mb-4">
                  <Label for="edit-long-def-target" class="block text-sm font-medium mb-1 text-fg-base">{{ pageStrings.longDefinition || 'Long Definition' }} ({{ pageStrings.targetLang || 'Target Language' }})</Label>
                  <Textarea 
                    id="edit-long-def-target"
                    v-model="editedWord.longDefinitionTargetLang" 
                    class="min-h-[100px] font-normal text-fg-base w-full"
                  />
                </div>
              </div>
              <span v-else>
                <span>
                  <p class="text-lg mb-1 font-bold text-fg-base" v-if="selectedWordInfo.shortDefinitionSourceLang">{{ pageStrings.shortDefinition }}</p>
                  <p class="text-lg mb-4 text-fg-base leading-relaxed" v-if="selectedWordInfo.shortDefinitionSourceLang">{{ selectedWordInfo.shortDefinitionSourceLang }}</p>
                  <p class="text-lg mb-1 font-bold text-fg-base" v-if="selectedWordInfo.longDefinitionSourceLang">{{ pageStrings.longDefinition }}</p>
                  <p class="text-lg mb-4 text-fg-base leading-relaxed" v-if="selectedWordInfo.longDefinitionSourceLang">{{ selectedWordInfo.longDefinitionSourceLang}}</p>
                </span>
                <div class="mt-4">
                  <!-- Sentence section with audio button -->
                  <div class="flex items-center mb-1" v-if="selectedWordInfo.sentence">
                    <p class="text-lg font-bold text-fg-base">{{ pageStrings.sentence }}</p>
                    
                    <!-- Sentence Audio Button next to sentence title -->
                    <span 
                      v-if="selectedWordInfo && selectedWordInfo.sentenceAudioS3Link"
                      @click="toggleAudio(selectedWordInfo.sentenceAudioS3Link, 'sentence')" 
                      class="ml-2 p-2 cursor-pointer"
                      title="Play sentence audio"
                    >
                      <PhPlayCircle v-if="!isSentenceAudioPlaying" class="w-4 h-4 text-black" />
                      <PhPauseCircle v-else class="w-4 h-4 text-black" />
                    </span>
                  </div>
                  
                  <p class="text-lg mb-6 text-fg-base leading-relaxed" v-if="selectedWordInfo.sentence" v-html="boldedSentence(selectedWordInfo.sentence, selectedWordInfo.word)"></p>
                  
                  <div class="flex items-center mt-4">
                    <Checkbox 
                      id="flashcard-enabled" 
                      :checked="cardEnabled"
                      @update:checked="setCardEnabled"
                    />
                    <Label for="flashcard-enabled" class="text-md ml-2 text-fg-base">{{ pageStrings.flashcardsEnabled }}</Label>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>

        <div v-else class="h-full flex items-center justify-center">
          <p class="text-fg-subtle text-center">{{ props.getWordsResult?.words.length === 0 ? pageStrings.saveWordExplanation : pageStrings.selectWordToViewDetails }} 
            <PhPlusCircle v-if="props.getWordsResult?.words.length === 0" class="w-6 h-6 text-fg-subtle ml-1 inline-block align-middle" /></p>
        </div>
      </div>
    </DialogContent>
  </Dialog>
</template>

<style scoped>
input:focus, textarea:focus {
  outline: none;
  border-color: var(--primary);
}
/* Improve text clarity */
.text-fg-base {
  color: rgba(0, 0, 0, 0.85);
}

.leading-relaxed {
  line-height: 1.625;
}

/* Improve contrast for better readability */
.bg-bg-component {
  background-color: rgba(255, 255, 255, 0.9);
}

/* Enhanced shadow for buttons */
.shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

/* Gray background for inputs in edit mode */
.edit-input {
  background-color: var(--bg-subtle-hover) !important;
}

</style>
