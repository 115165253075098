<script setup lang="ts">
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogClose,
  DialogTrigger,
} from '@/components/ui/dialog'
import { onMounted, ref, type Ref } from 'vue';

const props = defineProps<{
  clickedContinue: () => void,
  pageStrings: { [key: string]: string },
}>()

</script>
<style scoped>
/* TODO: replace this with the tailwind class*/
</style>
<template>  
  <Dialog>
    <DialogTrigger as-child>
        <slot/>
    </DialogTrigger>
    <DialogContent class="sm:max-w-[425px] grid gap-3">
      <DialogHeader>
        <DialogTitle>{{ pageStrings.goToSettings }}</DialogTitle>
        <DialogDescription>
          {{ pageStrings.goingToSettings }}
        </DialogDescription>
      </DialogHeader>
          <Button @click="props.clickedContinue" variant="primary" class="w-full">{{ pageStrings.continue}}</Button>
      <DialogClose class="w-full">
          <Button variant="secondary" class="w-full">{{ pageStrings.cancel}}</Button>
      </DialogClose>
    </DialogContent>
    </Dialog>
</template>