<script setup lang="ts">
import { PhCaretUp } from '@phosphor-icons/vue';
import type { StudyStats } from '@/types/model';

const props = defineProps<{
  pageStrings: { [key: string]: string },
  studyStats: StudyStats | null,
  userStreak?: number,
  todayStreakMet: boolean
}>();

const formatTimeWithHoursAndMinutes = (seconds: number): string => {
  const totalMinutes = Math.floor(seconds / 60);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  
  if (hours > 0) {
    return `${hours}${props.pageStrings.h} ${minutes}${props.pageStrings.m}`;
  } else {
    return `${minutes}${props.pageStrings.m}`;
  }
};
</script>

<template>
  <div class="bg-white rounded-lg shadow-modal p-4 w-full grid grid-cols-3 gap-3 cursor-pointer hover:bg-bg-subtle transition-colors max-w-[380px] mx-auto">
    <!-- Daily Streak Column -->
    <div class="flex items-center justify-center">
      <div class="w-[100px]">
        <div class="flex items-center">
          <div class="relative">
            <img src="@/assets/assets/fire2.svg" alt="Icon" v-if="todayStreakMet">
            <img src="@/assets/assets/firegray.svg" alt="Icon" v-else>
            <div class="absolute -bottom-1 -right-1 rounded-full w-5 h-5 flex items-center justify-center shadow-sm" :class="todayStreakMet ? 'bg-[#DDEDF9]' : 'bg-[#E9EDF3]'">
              <span class="text-[12px] text-text-muted font-bold leading-none flex items-center">{{ userStreak || 0 }}</span>
            </div>
          </div>
          <div class="ml-2 flex flex-col text-text-subtle">
            <span class="text-xs font-bold">{{ pageStrings.dailyStreak1}}</span>
            <span class="text-xs font-bold">{{ pageStrings.dailyStreak2}}</span>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Call Time Column -->
    <div class="flex items-center justify-center">
      <div class="w-[100px] flex flex-col">
        <div class="text-xs text-text-subtle text-left">{{ pageStrings.totalStudyTime}}</div>
        <div class="text-md-semibold text-text-subtle text-left">
          {{ formatTimeWithHoursAndMinutes((studyStats?.totalCallSeconds || 0) + (studyStats?.totalFlashcardSeconds || 0)) }}
        </div>
        <div class="flex items-center">
          <span class="bg-[#DDEDF9] rounded-md px-[0.2rem] py-[0.15rem] inline-flex items-center leading-none" v-if="studyStats?.todayCallSeconds && studyStats?.todayCallSeconds > 60">
            <span class="text-xs font-[12px] font-bold text-text-muted py-0 inline-block">
              <PhCaretUp class="w-3 h-3 inline-flex items-center" weight="fill"/>
            </span>
            <span class="text-xs font-[12px] font-bold text-text-muted mr-[0.1rem]" v-if="studyStats?.todayCallSeconds">
              {{ formatTimeWithHoursAndMinutes(studyStats.todayCallSeconds + studyStats.todayFlashcardSeconds) }}
            </span>
          </span>
          <span class="p-2" v-else-if="studyStats?.todayReviews"></span>
        </div>
      </div>
    </div>
    
    <!-- Flashcard Time Column -->
    <div class="flex items-center justify-center">
      <div class="w-[100px] flex flex-col">
        <div class="text-xs text-text-subtle text-left">{{ pageStrings.cardsReviewed }}</div>
        <div class="text-md-semibold text-text-subtle text-left">{{ studyStats?.totalReviews || 0 }}</div>
        <div class="flex items-center">
          <span class="bg-[#DDEDF9] rounded-md px-[0.2rem] py-[0.15rem] inline-flex items-center leading-none" v-if="studyStats?.todayReviews">
            <span class="text-xs font-[12px] font-bold text-text-muted py-0 inline-block">
              <PhCaretUp class="w-3 h-3 inline-flex items-center" weight="fill"/>
            </span>
            <span class="text-xs font-[12px] font-bold text-text-muted mr-[0.1rem]" v-if="studyStats?.todayReviews">{{ studyStats.todayReviews }}</span>
          </span>
          <span class="p-2" v-else-if="studyStats?.todayCallSeconds && studyStats?.todayCallSeconds > 60"></span>
        </div>
      </div>
    </div>
  </div>
</template> 