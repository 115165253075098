<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import {
  Dialog,
  DialogContent,
  DialogClose,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { PhX, PhChats, PhBookOpenText, PhPersonSimpleTaiChi, PhLightbulb, PhFilmSlate, PhPenNib } from "@phosphor-icons/vue";
import type { GetLocalizedKickstartsResponse, LocalizedKickstart } from './api'

const props = defineProps<{
  open: boolean,
  pageStrings: { [key: string]: string },
  localizedKickstarts: GetLocalizedKickstartsResponse | null,
  clickedStart: (kickstart: LocalizedKickstart) => void
}>()

const emit = defineEmits<{
  (e: 'update:open', value: boolean): void
}>()

// Track the selected category
const selectedCategory = ref<string>('open_ended')

// Define the categories with their icons and kickstart arrays
const categories = computed(() => {
  if (!props.localizedKickstarts) return []
  
  const categoryList = [
    {
      id: 'open_ended',
      icon: PhChats,
      title: props.pageStrings.freeConversation,
      kickstarts: [{
        kickstart: {
          id: "open_ended",
          type: "open_ended",
          prompt: "",
          description: ""
        },
        language: "",
        localized_description: props.pageStrings.goMoreOpenEnded
      }]
    },
    {
      id: 'grammar',
      icon: PhBookOpenText,
      title: props.pageStrings.grammarLesson,
      kickstarts: props.localizedKickstarts.grammarKickstarts || []
    },
    {
      id: 'student_life',
      icon: PhPersonSimpleTaiChi,
      title: props.pageStrings.studentLifeKickstart,
      kickstarts: props.localizedKickstarts.studentLifeKickstarts || []
    },
    {
      id: 'topic',
      icon: PhLightbulb,
      title: props.pageStrings.topicKickstart,
      kickstarts: props.localizedKickstarts.topicKickstarts || []
    },
    {
      id: 'scenario',
      icon: PhFilmSlate,
      title: props.pageStrings.scenario,
      kickstarts: props.localizedKickstarts.scenarioKickstarts || []
    }
  ]
  
  // Add saved_words category at the bottom if it exists and has items
  if (props.localizedKickstarts.savedWordsKickstarts && props.localizedKickstarts.savedWordsKickstarts.length > 0) {
    categoryList.push({
      id: 'saved_words',
      icon: PhPenNib,
      title: props.pageStrings.practiceSavedWords,
      kickstarts: props.localizedKickstarts.savedWordsKickstarts
    })
  }
  
  return categoryList
})

// Get the currently selected category
const currentCategory = computed(() => {
  return categories.value.find(category => category.id === selectedCategory.value)
})

// Handle selecting a kickstart
const selectKickstart = (kickstart: LocalizedKickstart) => {
  props.clickedStart(kickstart)
  emit('update:open', false)
}

// Reset selected category when dialog opens
watch(() => props.open, (newValue) => {
  if (newValue) {
    selectedCategory.value = 'open_ended'
  }
})
</script>

<template>
  <Dialog :open="open" @update:open="emit('update:open', $event)" class="focus-visible:outline-none">
    <DialogContent 
      class="w-[95%] md:max-w-[700px] rounded-xl p-0 grid grid-cols-[80px_1fr] md:grid-cols-[100px_1fr] bg-bg-base text-text-base [&>button]:hidden mobile-dialog md:desktop-dialog overflow-hidden h-[530px] border-0 shadow-none outline-none focus:outline-none focus-visible:outline-none focus:ring-0"
    >
      <!-- Left Column - Categories -->
      <div class="bg-bg-base h-full flex flex-col rounded-l-xl border-r-1 border-text-muted border-opacity-20">
        <div class="p-3 pt-3 flex flex-col h-full">
          <DialogClose
            @click="emit('update:open', false)"
            class="w-8 h-8 ml-1 rounded-full bg-bg-subtle-hover cursor-pointer hover:bg-bg-component-hover flex items-center justify-center transition-colors mb-4"
          >
            <PhX class="w-4 h-4" />
          </DialogClose>
          
          <div class="space-y-3 flex-1">
            <div 
              v-for="category in categories" 
              :key="category.id"
              class="flex flex-col items-center justify-center p-2 rounded-md cursor-pointer transition-colors"
              :class="{ 'bg-bg-subtle-hover text-text-base': selectedCategory === category.id, 'text-text-muted': selectedCategory !== category.id }"
              @click="selectedCategory = category.id"
            >
              <component 
                :is="category.icon" 
                class="w-6 h-6 mb-1"
              />
              <span class="text-xs text-center hidden md:block">{{ category.title }}</span>
            </div>
          </div>
        </div>
      </div>
      
      <!-- Right Column - Kickstart List -->
      <div class="bg-bg-base rounded-r-xl p-4 pl-2 h-full flex flex-col overflow-hidden">
        <DialogHeader class="text-left mb-2 bg-bg-base z-10 md:mb-0 flex-shrink-0">
          <DialogTitle class="text-xl font-bold md:hidden">
            {{ currentCategory?.title || '' }}
          </DialogTitle>
        </DialogHeader>
        
        <div class="overflow-y-auto flex-grow">
          <div class="space-y-1">
            <div 
              v-for="kickstart in currentCategory?.kickstarts" 
              :key="kickstart.kickstart.id"
              class="p-2 rounded-md hover:bg-bg-subtle-hover cursor-pointer transition-colors"
              @click="selectKickstart(kickstart)"
            >
              <p class="text-md font-medium">{{ kickstart.localized_description }}</p>
            </div>
            
            <div v-if="!currentCategory?.kickstarts.length" class="text-center text-fg-subtle p-4">
              <template v-if="selectedCategory === 'grammar'">
              {{ props.pageStrings.noGrammarLessonsAvailable }}
              </template>
              <template v-else>
                {{ props.pageStrings.noKickstartsAvailable }}
              </template>
            </div>
          </div>
        </div>
      </div>
    </DialogContent>
  </Dialog>
</template>

<style scoped>
.dialog-bottom {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: 0ranslateX(-50%);
}

@media (min-width: 768px) {
  .dialog-bottom {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/* Mobile dialog styling */
.mobile-dialog {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  overflow: hidden;
  height: 500px !important;
  max-height: 80vh;
}

/* Desktop dialog styling */
@media (min-width: 768px) {
  .desktop-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 0.75rem;
    height: 500px !important;
  }
}

/* Prevent focus borders */
:deep(.DialogOverlay),
:deep(.DialogContent) {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

:deep(.DialogContent:focus),
:deep(.DialogContent:focus-visible) {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
</style> 