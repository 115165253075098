<script setup lang="ts">
import router from "@/router";

import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import IssenHeader from "@/practice/IssenHeader.vue";
import { ref, type Ref } from "vue";
import { getMessagesFromLanguage } from "@/languages/messages";
import { getDefaultSourceLanguage } from "@/practice/util";
import { onMounted } from 'vue';
import { apiRes, type GetUserResponse, PracticeAPI} from "@/practice/api";
import { protocol, domainPort } from "@/url";
import { UserStatus } from "@/types/model";
import { useAnalytics } from '@/practice/useAnalytics';

const guiLanguage = ref(getDefaultSourceLanguage());
const pageStrings= ref(getMessagesFromLanguage(guiLanguage.value));
const API: PracticeAPI = new PracticeAPI(protocol + domainPort);
const getUserResult = apiRes<GetUserResponse>();
const { trackEvent } = useAnalytics()

onMounted(async () => {
    await API.getUser(getUserResult);
    if (getUserResult.data.value && getUserResult.data.value.user.guiLang) {
      guiLanguage.value = getUserResult.data.value.user.guiLang;
      pageStrings.value = getMessagesFromLanguage(guiLanguage.value);
    }
    if (getUserResult.data.value) {
      if (getUserResult.data.value.user.userStatus !== UserStatus.SUBSCRIBED) {
        router.push("/subscribe");
      }
      trackEvent('subscribed')
      if (getUserResult.data.value.defaultPassword) {
        router.push("/password?subscribed=true")
      }
    }
})

async function goToApp() {
  router.push("/");
}
</script>

<template>
  <span class="page-container flex flex-col">
    <div class="w-full flex justify-between">
      <IssenHeader :pageStrings="pageStrings" :getUserResult="getUserResult" :preLogout="()=>{}" :postLogout="()=>{}" />
    </div>
    <div class="flex-1 flex flex-col w-full">
      <div class="flex flex-grow justify-center items-center">
        <Card class="w-[350px] md:w-[500px] lg:w-[600px] border-2 border-gray-300 shadow-md" v-if="getUserResult.data.value">
          <CardHeader>
            <CardTitle class="text-xl md:text-2xl">{{ pageStrings.subscribedTitle }}</CardTitle>
          </CardHeader>
          <CardContent>
            <p class="text-md md:text-lg flex items-center">
              {{ pageStrings.welcomeToIssen1 }}
            </p>
            <p class="text-md md:text-lg flex items-center mt-2">
              {{ pageStrings.welcomeToIssen2 }}
            </p>
            <p class="text-md md:text-lg flex items-center mt-2">
              {{ pageStrings.welcomeToIssen3 }}
            </p>
            <p class="text-md md:text-lg flex items-center mt-2">
              {{ pageStrings.welcomeToIssen4 }}
            </p>
            <div class="flex flex-col items-center gap-4 mt-8">
              <div class="flex flex-col md:flex-row gap-4">
                <a href="https://apps.apple.com/us/app/issen-language-voice-tutor/id6739271539" target="_blank">
                  <img src="/assets/appstore.png" 
                    class="w-[150px] h-[43px] cursor-pointer" 
                    alt="App Store" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.issen.app" target="_blank">
                  <img src="/assets/playstore.png" 
                    class="w-[150px] h-[43px] cursor-pointer" 
                    alt="Play Store" />
                </a>
              </div>
              <Button @click="goToApp" class="buttonDefault h-[50px]">
                {{ pageStrings.continueToWebApp }}
              </Button>
            </div>
          </CardContent>
          <CardFooter class="flex justify-between px-6 pb-6">
          </CardFooter>
        </Card>
      </div>
    </div>
  </span>
</template>
