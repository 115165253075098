<script setup lang="ts">
import { PhCaretUp, PhChats, PhBookOpenText, PhPersonSimpleTaiChi, PhLightbulb, PhFilmSlate, PhCircleNotch, PhPenNib, PhCards, PhFireSimple} from '@phosphor-icons/vue';
import { watch, computed, onMounted, ref, type Ref, onUnmounted } from 'vue';
import { Button } from '@/components/ui/button';
import type { GetLocalizedKickstartsResponse, LocalizedKickstart, GetAvailableAgentTemplatesResponse, UpdateUserResponse, GetLeaderboardResponse, GetAgentsResponse } from './api';
import { PracticeAPI, apiRes } from './api';
import { templateIdToLanguage, type Agent, type Card, type HistoricalStats, type StudyStats, type UserSettings, type Word } from '@/types/model';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Separator } from '@/components/ui/separator';
import {domainPort, protocol} from "@/url";
import type {AgentTemplate, Voice} from "@/types/agents";
import { useRouter } from 'vue-router'
import { getLanguageFlagCountry } from '@/practice/util';
import recommendedAgents from '@/../../shared/recommended_agents.json';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import Leaderboard from './Leaderboard.vue';
import StudyStatsChart from './StudyStatsChart.vue';
import KickstartDialog from './KickstartDialog.vue';
import StudyStatsSummary from './StudyStatsSummary.vue';
import { PhX } from '@phosphor-icons/vue';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '@/components/ui/drawer'
import {
  Dialog,
  DialogContent,
  DialogClose,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { BookTemplateIcon } from 'lucide-vue-next';


const props = defineProps<{
  pageStrings: { [key: string]: string },
  targetLangPageStrings: { [key: string]: string },
  startEnabled: boolean,
  clickedStart: (kickStartMessage?: LocalizedKickstart) => void,
  clickedFlashcards: () => void,
  localizedKickstarts: GetLocalizedKickstartsResponse | null,
  user: UserSettings | null,
  isUserLoggedIn: boolean,
  targetLanguage?: string,
  restartSession: (reloadUserData: boolean) => void,
  numCardsToReview: number | null,
  numCardsReviewed: number | null,
  savedWords: Word[],
  savedCards: Card[],
  userStreak?: number,
  studyStats: StudyStats | null,
  leaderboardResult: GetLeaderboardResponse | null,
  historicalStats: HistoricalStats | null
}>();

const router = useRouter();
const selectedTutor: Ref<string | undefined> = ref(undefined);
const getAvailableAgentTemplatesResult = apiRes<GetAvailableAgentTemplatesResponse>();
const getAgentsResult = apiRes<GetAgentsResponse>();
const API: PracticeAPI = new PracticeAPI(protocol + domainPort);
const updateUserResult = apiRes<UpdateUserResponse>();
const leaderboardOpen = ref(false);
const drawerOpen = ref(false);
const dialogOpen = ref(false);
const hoveringOrangeButton = ref(false);
const kickstartDialogOpen = ref(false);
const isMobile = ref(false);
const userAgents: Ref<Agent[]> = ref([]);

onMounted(async () => {
  await Promise.all([
    API.getAvailableAgentTemplates(getAvailableAgentTemplatesResult),
    API.getAgents(getAgentsResult, true)
  ]);
  
  if (getAgentsResult.data.value?.agents) {
    userAgents.value = getAgentsResult.data.value.agents;
  }
  
  if (getAvailableAgentTemplatesResult.data.value?.agentTemplates) {
    for (const agentTemplate of getAvailableAgentTemplatesResult.data.value.agentTemplates) {
      if (agentTemplate.agentTemplateId === props.user?.selectedAgentTemplateId) {
        selectedTutor.value = agentTemplate.agentTemplateId;
      }
    }
  }
  
  checkScreenSize();
  window.addEventListener('resize', checkScreenSize);
});

onUnmounted(() => {
  window.removeEventListener('resize', checkScreenSize);
});

const checkScreenSize = () => {
  isMobile.value = window.innerWidth < 768;
};

const startWithKickstart = (kickstart?: LocalizedKickstart) => {
  if (kickstart) {
    props.clickedStart(kickstart);
  } else {
    props.clickedStart({
      kickstart: {
        id: "open_ended",
        type: "open_ended",
        prompt: "",
        description: ""
      },
      language: "",
      localized_description: ""
    });
  }
}

const computedTemplatesForLanguage = computed(() => {
  if (!getAvailableAgentTemplatesResult.data.value?.agentTemplates) {
    return [];
  }
  
  const allTemplates = getAvailableAgentTemplatesResult.data.value.agentTemplates;
  
  if (props.targetLanguage === null || props.targetLanguage === undefined || props.targetLanguage === "") {
    return allTemplates;
  }
  
  const currentUserAgentTemplates = userAgents.value
    .map(agent => allTemplates.find(template => template.agentTemplateId === agent.agentTemplateId))
    .filter(template => template !== undefined) as AgentTemplate[];
  
  const currentUserAgentTemplateIds = new Set(currentUserAgentTemplates.map(template => template.agentTemplateId));
  
  const allUserLanguages = new Set(userAgents.value.map(agent => agent.language));
  
  const currentLanguageTemplates = allTemplates.filter(
    template => template.language === props.targetLanguage && 
                !currentUserAgentTemplateIds.has(template.agentTemplateId) &&
                !template.deprecated

  );
  
  const otherUserLanguageTemplates = allTemplates.filter(
    template => allUserLanguages.has(template.language) && 
                template.language !== props.targetLanguage &&
                !currentUserAgentTemplateIds.has(template.agentTemplateId) &&
                !template.deprecated
  );
  
  otherUserLanguageTemplates.sort((a, b) => a.language.localeCompare(b.language));
  
  return [
    ...currentUserAgentTemplates,
    ...currentLanguageTemplates,
    ...otherUserLanguageTemplates
  ];
});

const isOnboarded = computed(() => {
  return props.user && props.user.hasOnboarded;
});

watch(selectedTutor, async (newVal, oldVal) => {
  if (!oldVal) return;
  if (newVal === "change_language") {
    router.push("/settings");
  } else if (newVal !== undefined && newVal !== null) {
    await API.update_user(updateUserResult, {
      selectedAgentTemplateId: newVal
    });

    const oldLanguage = templateIdToLanguage(oldVal);
    const newLanguage = templateIdToLanguage(newVal);
    props.restartSession(oldLanguage !== newLanguage);
  }
});

const isRecommendedAgent = (agentTemplateId: string) => {
  return recommendedAgents.includes(agentTemplateId);
}

const finalLeaderboard = computed(() => {
  if (!props.leaderboardResult) return [];
  
  let leaderboardData = [...props.leaderboardResult.leaderboard];
  
  const currentUserId = props.user?.userId;
  const currentUserInLeaderboard = currentUserId && leaderboardData.some(user => user.userId === currentUserId);
  
  if (currentUserId && !currentUserInLeaderboard) {
    if (props.leaderboardResult.currentUser) {
      leaderboardData.push(props.leaderboardResult.currentUser);
    }
  }
  
  if (leaderboardData.length > 0 && leaderboardData[0].ranking !== undefined) {
    return leaderboardData
      .filter(user => user.ranking !== undefined)
      .sort((a, b) => (a.ranking || 0) - (b.ranking || 0))
      .map(user => ({
        ...user,
        ranking: user.ranking || 0
      }));
  }
  
  const sortedLeaderboard = leaderboardData.sort((a, b) => b.totalDuration - a.totalDuration);
  
  return sortedLeaderboard.map((user, index) => ({
    ...user,
    ranking: index + 1
  }));
});

const getGreeting = () => {
  if (props.user && !props.user.hasOnboarded) {
    return `${props.targetLangPageStrings.welcomeTitle} 👋`;
  }

  if (props.user == null) {
    return props.targetLangPageStrings.namelessGreeting;
  }
  
  const dayOfMonth = new Date().getDate();
  const currentHour = new Date().getHours();
  
  const useTimeOfDayGreeting = dayOfMonth % 2 === 0;
  
  let greeting = "";
  if (useTimeOfDayGreeting) {
    if (currentHour < 12) {
      greeting = props.targetLangPageStrings.morningGreeting;
    } else if (currentHour < 17) {
      greeting = props.targetLangPageStrings.afternoonGreeting;
    } else {
      greeting = props.targetLangPageStrings.eveningGreeting;
    }
  } else {
    const genericGreetingNum = (currentHour % 5) + 1;
    greeting = props.targetLangPageStrings[`genericGreeting${genericGreetingNum}`];
  }
  
  if (dayOfMonth % 3 === 0) {
    greeting += " 👋";
  }
  
  return greeting.replace("${name}", props.user?.name || "");
}

const getCongratsMessage = () => {
  const congratsMessages = [props.targetLangPageStrings.goodJob1, props.targetLangPageStrings.goodJob2, 
  props.targetLangPageStrings.goodJob3, props.targetLangPageStrings.goodJob4, props.targetLangPageStrings.goodJob5];
  const dayOfMonth = new Date().getDate();
  const messageIndex = dayOfMonth % congratsMessages.length;
  const congratsMessage = congratsMessages[messageIndex];

  return congratsMessage.replace("${name}", props.user?.name || "");
}

const lessonCompleted = computed(() => {
  return props.localizedKickstarts?.dailyKickstart.completed;
});
const flashcardsCompleted = computed(() => {
  return (props.numCardsToReview == 0 && props.numCardsReviewed && props.numCardsReviewed > 0);
});
const noFlashcards = computed(() => {
  return (props.numCardsToReview == null || props.numCardsToReview == undefined || props.numCardsToReview == 0) && 
  (props.numCardsReviewed == null || props.numCardsReviewed == undefined || props.numCardsReviewed == 0) && 
  (props.savedCards.length == 0);
});

const todayStreakMet = computed(() => {
  return (props.studyStats?.todayCallSeconds || 0) >= 120 || (props.numCardsReviewed || 0) >= 1;
});

</script>

<style scoped>  
.star {
  width: 16px;  
  aspect-ratio: 1;
  background: #F8CA00;
  clip-path: polygon(50% 0,
    calc(50%*(1 + sin(.4turn))) calc(50%*(1 - cos(.4turn))),
    calc(50%*(1 - sin(.2turn))) calc(50%*(1 - cos(.2turn))),
    calc(50%*(1 + sin(.2turn))) calc(50%*(1 - cos(.2turn))),
    calc(50%*(1 - sin(.4turn))) calc(50%*(1 - cos(.4turn))) 
   ); 
}

.light-purple-box {
  background-color: #F3E8FF;
}

.purple-gradient {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0) 100%), #5963C2;
}

.orange-gradient {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0) 100%), #D96E59;
}

.streak-fire-full {
  fill: #FF9F56;
  color: #FF9F56;
} 

.streak-fire-mid {
  fill: rgba(255, 159, 86, 0.7);
  color: rgba(255, 159, 86, 0.7);
}

.streak-fire-empty {
  fill: var(--bg-switch-off-hover);
  color: var(--bg-switch-off-hover);
}

.knewave {
  font-family: 'Knewave', cursive;
  font-weight: 400;
  font-style: normal;
}
#drawer-line {
  width: 60px;
  height: 6px;
  border-radius: 10px;

  background-color: #4B5563;
}
.DialogOverlay {
  background: rgba(0 0 0 / 0.1);
  border: none;
}

/* Override focus styles that might cause borders */
.DialogContent:focus,
.DialogOverlay:focus {
  outline: none !important;
  border: none !important;
}

.dialog-grid {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 24px;
  width: 100%;
  max-width: 1000px;
}

@media (max-width: 767px) {
  .dialog-grid {
    grid-template-columns: 1fr;
  }
}

.dialog-stats-section {
  padding: 16px;
  padding-top: 0px;
  width: 100%;
}

.dialog-leaderboard-section {
  padding: 16px;
  padding-top: 0px;
  width: 100%;
}

.dialog-content {
  max-height: 80vh;
  overflow-y: auto;
}

.study-stats-container {
  height: 100%;
  overflow-y: auto;
  min-height: 400px;
  max-height: 600px;
}

.leaderboard-container {
  height: 100%;
  overflow-y: auto;
  /* min-height: 400px; */
  max-height: 650px;
}

/* Target drawer border specifically without affecting shadow */
:deep(.vaul-drawer),
:deep(.vaul-drawer__content) {
  outline: none !important;
  border: none !important;
}

:deep(.vaul-drawer *:focus),
:deep(.vaul-drawer *:focus-visible) {
  outline: none !important;
  border: none !important;
}

:deep(.vaul-drawer__overlay) {
  outline: none !important;
  border: none !important;
}
</style>

<template>
  <div class="flex-grow flex items-center justify-center">
    <div class="flex flex-col items-center justify-center w-full px-6">
      <div class="w-full max-w-[420px]">
        <!-- Tutor Picker UI -->
        <div class="flex flex-row items-center gap-2 mb-3 w-full justify-center" v-if="isOnboarded">
          <span class="text-md">{{ pageStrings.talkTo }}</span>
          <Select v-model="selectedTutor" class="ml-2" v-if="selectedTutor !==''"> 
            <SelectTrigger class="w-[150px] border-1 border-[#8E8EA1] rounded-xl text-md bg-bg-base bg-opacity-30">
              <SelectValue :placeholder="pageStrings.selectTutor" />
            </SelectTrigger>
            <SelectContent class="bg-bg-base">
              <SelectItem v-for="agent in computedTemplatesForLanguage"
                :key="agent.agentTemplateId" 
                :value="agent.agentTemplateId" 
                class="h-9 cursor-pointer bg-bg-base hover:bg-bg-base-hover text-md">
                <TooltipProvider :delayDuration="0" v-if="recommendedAgents.includes(agent.agentTemplateId)">
                  <Tooltip>
                    <TooltipTrigger>
                      <span class="z-10 flex flex-row mt-[0.2rem] items-center">
                        <img :src="`/assets/country_icons/${getLanguageFlagCountry(agent.language, agent.homeCountry)}.svg`" 
                          class="w-[1.5rem] h-[1.5rem]" 
                          alt="flag" />
                        <span class="ml-2 text-md">{{ agent.name }}</span>
                        <span class="z-8 ml-2 mt-[0.05rem] star">   </span>
                      </span>
                    </TooltipTrigger>
                    <TooltipContent class="text-lg bg-toolbar-background tooltip-shadow text-fg-on-inverted" :hide-when-detached="true" >
                      <p>{{ pageStrings.recommendedTutor }}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
                <span v-if="!recommendedAgents.includes(agent.agentTemplateId)" class="flex flex-row items-center">
                  <img v-if="!recommendedAgents.includes(agent.agentTemplateId)" 
                    :src="`/assets/country_icons/${getLanguageFlagCountry(agent.language, agent.homeCountry)}.svg`" 
                    class="w-[1.5rem] h-[1.5rem]" 
                    alt="flag" />
                  <span class="ml-2 text-md">{{ agent.name }}</span>
                </span>
              </SelectItem>
              <SelectItem value="change_language" class="h-9 text-md cursor-pointer bg-bg-base hover:bg-bg-base-hover">
                {{ pageStrings.changeLanguage }}
              </SelectItem>
            </SelectContent>
          </Select>
        </div>

        <!-- Main Card -->
        <div class="bg-bg-base rounded-lg shadow-card-hover p-5 w-full mb-3 max-w-[380px] mx-auto">
          <h1 class="text-hlg mb-4" v-if="lessonCompleted || flashcardsCompleted">{{ getCongratsMessage() }}</h1>
          <h1 class="text-hlg mb-4" v-else>{{ getGreeting() }}</h1>
          <!-- Light Purple Box -->
          <div class="light-purple-box rounded-sm p-4 mb-4 mx-auto" style="max-width: 320px;" v-if="!lessonCompleted">
            <p class="text-lg-semibold mb-0 text-center"> {{pageStrings.todaysLesson}} {{ localizedKickstarts?.dailyKickstart.kickstart.localized_description }}</p>
            
            <!-- Purple Button -->
            <div class="flex justify-center">
              <button 
                class="purple-gradient flex items-center justify-center w-[288px] h-[42px] rounded-sm gap-2 p-2.5 mt-4 text-white shadow-sm disabled:opacity-70 disabled:cursor-not-allowed"
                :disabled="!startEnabled" 
                @click="clickedStart(localizedKickstarts?.dailyKickstart?.kickstart)">
                <PhCircleNotch v-if="!startEnabled" class="w-5 h-5 mr-1 inline-block animate-spin"/>
                <PhChats v-else class="w-5 h-5 mr-1 inline-block"/>
                <span class="text-lg-semibold">{{ !isOnboarded ? pageStrings.openEnded : pageStrings.startLesson}}</span>
              </button>
            </div>
          </div>
          
          <!-- Orange Button -->
          <div class="flex justify-center">
            <TooltipProvider :delayDuration=1000>
              <Tooltip :open="hoveringOrangeButton">
                <TooltipTrigger asChild >
                  <Button
                    class="orange-gradient flex items-center justify-center w-[288px] h-[42px] rounded-sm gap-2 p-2.5 mb-4 text-white shadow-sm disabled:opacity-70 disabled:cursor-not-allowed"
                    :class="{ 'opacity-50 cursor-not-allowed': !startEnabled || !numCardsToReview || numCardsToReview === 0 }"
                    @click="() => {
                      if (!startEnabled || !numCardsToReview || numCardsToReview === 0) return;
                      clickedFlashcards();
                    }"
                    @mouseover="hoveringOrangeButton = true"
                    @mouseleave="hoveringOrangeButton = false">
                    <PhCircleNotch v-if="!startEnabled" class="w-5 h-5 mr-1 inline-block animate-spin"/>
                    <PhCards v-else class="w-5 h-5 mr-1 inline-block"/>
                    <span class="text-lg-semibold" v-if="numCardsToReview && numCardsToReview > 0">{{ pageStrings.reviewVocab}} ({{ numCardsToReview }})</span>
                    <span class="text-lg-semibold" v-else-if="noFlashcards">{{ pageStrings.noSavedWords }}</span>
                    <span class="text-lg-semibold" v-else>{{ pageStrings.noWordsToReview }}</span>
                  </Button>
                </TooltipTrigger>
                <TooltipContent class="text-lg bg-toolbar-background tooltip-shadow text-fg-on-inverted max-w-[300px]" :hide-when-detached="true" v-if="!numCardsToReview || numCardsToReview === 0">
                  <p v-if="numCardsToReview && numCardsToReview > 0">{{ pageStrings.reviewVocabExplanation}}</p>
                  <p v-if="noFlashcards">{{ pageStrings.noSavedWordsExplanation}}</p>
                  <p v-else>{{ pageStrings.noWordsToReviewExplanation}}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>

          <div class="flex justify-center" v-if="lessonCompleted">
            <Button
              variant="default"
              :disabled="!startEnabled"
              class="flex items-center justify-center w-[288px] h-[42px] rounded-sm gap-2 p-2.5 mb-4 text-text-base disabled:opacity-70 disabled:cursor-not-allowed"
              @click="clickedStart()">
              <PhChats v-if="startEnabled" class="w-5 h-5 mr-1 inline-block"/>
              <PhCircleNotch v-if="!startEnabled" class="w-5 h-5 mr-1 inline-block animate-spin"/>
              <span class="text-lg-semibold">{{ pageStrings.continueChatting }}</span>
            </Button>
          </div>

          <!-- Link -->
          <div class="text-center text-text-subtle" v-if="localizedKickstarts">
            <a href="#" class="underline text-sm" @click.prevent="kickstartDialogOpen = true">{{ pageStrings.selectDifferent}}</a>
          </div>
        </div>
        
        <!-- Bottom Stats Card -->
        <div class="w-full max-w-[380px] mx-auto">
          <!-- Mobile Drawer (shown on small screens) -->
          <Drawer v-model:open="drawerOpen" v-if="isMobile" tabindex="-1">
            <DrawerTrigger asChild>
              <StudyStatsSummary 
                :pageStrings="pageStrings"
                :studyStats="studyStats"
                :userStreak="userStreak"
                :todayStreakMet="todayStreakMet"
              />
            </DrawerTrigger>
            <DrawerContent class="max-h-[85vh] overflow-hidden mt-0 mx-4 border-0 shadow-modal scrollbar-hide rounded-xl outline-none ring-0 focus:outline-none focus:border-0 focus-visible:outline-none">
              <DrawerTitle class="sr-only">Study Statistics and Leaderboard</DrawerTitle>
              <DrawerDescription class="sr-only">Study Statistics and Leaderboard</DrawerDescription>
              <div class="flex justify-center w-full m-0 p-0 mb-0 mt-3">
                <span id="drawer-line" class="mt-0"></span>
              </div>
              <div class="flex flex-col overflow-y-auto overflow-x-hidden scrollbar-hide mt-4" style="max-height: calc(85vh - 50px);">
                
                <div class="px-6 py-0 my-0">
                  <StudyStatsChart :pageStrings="pageStrings" :historicalStats="historicalStats" />
                </div>               
                <div class="px-4 pt-7">
                  <Leaderboard 
                    :pageStrings="pageStrings" 
                    :finalLeaderboard="finalLeaderboard" 
                    :userId="props.user?.userId || ''"
                    @close="leaderboardOpen = false"
                  />
                </div>
              </div>
            </DrawerContent>
          </Drawer>

          <!-- Desktop Dialog (shown on larger screens) -->
          <Dialog v-model:open="dialogOpen" class="focus-visible:outline-none" v-else>
            <DialogTrigger asChild>
              <StudyStatsSummary 
                :pageStrings="pageStrings"
                :studyStats="studyStats"
                :userStreak="userStreak"
                :todayStreakMet="todayStreakMet"
              />
            </DialogTrigger>
            <DialogContent class="sm:max-w-[900px] overflow-hidden bg-bg-base p-6 dialog-content [&>button:not(.w-8)]:hidden border-0 shadow-none outline-none focus:outline-none focus-visible:outline-none focus:ring-0">
              <DialogDescription class="sr-only">Study Statistics and Leaderboard</DialogDescription>
              <DialogTitle class="sr-only">Study Statistics and Leaderboard</DialogTitle>
              <DialogClose
                @click="dialogOpen = false"
                class="w-8 h-8 ml-1 rounded-full bg-bg-subtle-hover cursor:pointer hover:bg-bg-component-hover flex items-center justify-center transition-colors"
              >
                <PhX class="w-4 h-4" />
              </DialogClose>
              <div class="dialog-grid">

                <!-- Study Stats section -->
                <div class="dialog-stats-section">
                  <div class="study-stats-container">
                    <StudyStatsChart :pageStrings="pageStrings" :historicalStats="historicalStats" />
                  </div>
                </div>
                
                <!-- Leaderboard section -->
                <div class="dialog-leaderboard-section">
                  <div class="leaderboard-container">
                    <Leaderboard 
                      :pageStrings="pageStrings" 
                      :finalLeaderboard="finalLeaderboard" 
                      :userId="props.user?.userId || ''"
                      @close="dialogOpen = false"
                    />
                  </div>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </div>
    
    <!-- Kickstart Dialog -->
    <KickstartDialog
      v-model:open="kickstartDialogOpen"
      :pageStrings="pageStrings"
      :localizedKickstarts="localizedKickstarts"
      :clickedStart="clickedStart"
    />
  </div>
</template>