<script setup lang="ts">
import router from "@/router";

import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import IssenHeader from "@/practice/IssenHeader.vue";
import { ref, type Ref } from "vue";
import { getMessagesFromLanguage } from "@/languages/messages";
import { getDefaultSourceLanguage } from "@/practice/util";
import { onMounted } from 'vue';
import { apiRes, type CheckAllowlistResponse, type CheckUsernameResponse, type CreateUserResponse, type GetUserResponse, PracticeAPI, type SubscribeResponse, type UpdateUserResponse } from "@/practice/api";
import { protocol, domainPort } from "@/url";
import { UserStatus } from "@/types/model";
import { Check } from 'lucide-vue-next';
import { useRoute } from "vue-router";
import { priceId } from "@/practice/subscription";
import { useAnalytics } from '@/practice/useAnalytics';

const subscribeResult = apiRes<SubscribeResponse>();


const guiLanguage = ref(getDefaultSourceLanguage());
const pageStrings= ref(getMessagesFromLanguage(guiLanguage.value));
const API: PracticeAPI = new PracticeAPI(protocol + domainPort);
const getUserResult = apiRes<GetUserResponse>();
const timelimit = ref(false);
const route = useRoute();
const updateUserResult = apiRes<UpdateUserResponse>();
const { trackEvent } = useAnalytics()
let priceIdOverride: string = priceId;

// TRIAL / STANDARD / SUBSCRIBED
// email / no email
// ?subscribe or not

// 3. TRIAL, no email -> email                            X
// 4. STANDARD, email,  -> stripe                         X 
// 5. SUBSCRIBED -> /                                     X
// 6. not logged in -> /email                             X


onMounted(async () => {
  if (route.query.timelimit) { 
    timelimit.value = true;
  }
  if (route.query.priceId) {
    priceIdOverride = route.query.priceId as string;
  }
  await API.getUser(getUserResult);
  if (getUserResult.data.value && getUserResult.data.value.user.guiLang) {
    guiLanguage.value = getUserResult.data.value.user.guiLang;
    pageStrings.value = getMessagesFromLanguage(guiLanguage.value);
  }
  if (getUserResult.data.value) {
    console.log("User data:", getUserResult.data.value);
    if (getUserResult.data.value.user.userStatus === UserStatus.SUBSCRIBED) {
      // 5. SUBSCRIBED -> /
      router.push("/");
    } else {
      trackEvent('visited_subscribe')
    }
  }
  console.log("priceId", priceIdOverride);
})

async function subscribe() {
  const queryParams: { subscribe?: string, priceId?: string } = {
        subscribe: "true"
      };
      if (route.query.priceId) {
        queryParams.priceId = priceIdOverride;
      }
  trackEvent('clicked_subscribe')
  if (getUserResult.data.value) {
    if (!getUserResult.data.value.user.username.includes("@")) {
      // TEMP users must set their email first (3)
      router.push({ path: "/email", query: queryParams });
      return;
    } else {
      // 1,2,4
      await API.subscribe(priceIdOverride, subscribeResult);
      if (subscribeResult.data.value) {
        window.location.href = subscribeResult.data.value.url;
      } else {
        console.log("No url in subscribeResult");
      }
    }
  } else {
    // 6. not logged in -> /email
    router.push({ path: "/email", query: queryParams });
  }
}

</script>

<template>
  <span class="page-container flex flex-col h-full">
    <div class="w-full">
      <IssenHeader :pageStrings="pageStrings" :getUserResult="getUserResult" :preLogout="()=>{}" :postLogout="()=>{}"/>
    </div>
    <div class="flex-1 flex items-center justify-center overflow-auto">
      <Card class="w-[350px] md:w-[500px] lg:w-[600px] border-2 border-gray-300 shadow-md overflow-auto">
        <CardHeader>
          <CardTitle class="text-xl md:text-2xl" v-if="!timelimit">{{ pageStrings.subscribeTitle }}</CardTitle>
          <CardTitle class="text-xl md:text-2xl" v-if="timelimit">{{ pageStrings.subscribeTitle2 }}</CardTitle>

        </CardHeader>
        <CardContent>
          <p class="text-md md:text-lg flex ">
            {{ pageStrings.subscribeDescription }}
          </p> 
          <p class="text-md md:text-lg mt-4 flex items-center">
            <Check class="min-w-4 min-h-4 w-4 h-4 mr-2 text-green-500" />
            {{ pageStrings.issenBenefit1 }}
          </p> 
          <p class="text-md md:text-lg mt-4 flex items-center">
            <Check class="min-w-4 min-h-4 w-4 h-4 mr-2 text-green-500" />
            {{ pageStrings.issenBenefit2 }}
          </p>
          <p class="text-md md:text-lg mt-4 flex items-center">
            <Check class="min-w-4 min-h-4 w-4 h-4 mr-2 text-green-500" />
            {{ pageStrings.issenBenefit3 }}
          </p>
          <p class="text-md md:text-lg mt-4 flex items-center">
            <Check class="min-w-4 min-h-4 w-4 h-4 mr-2 text-green-500" />
            {{ pageStrings.issenBenefit4 }}
          </p>
          <p class="text-md md:text-lg mt-4 flex items-center">
            <Check class="min-w-4 min-h-4 w-4 h-4 mr-2 text-green-500" />
            {{ pageStrings.issenBenefit5 }}
          </p>
          <p class="text-md md:text-lg mt-4 flex items-center">
            <Check class="min-w-4 min-h-4 w-4 h-4 mr-2 text-green-500" />
            {{ pageStrings.issenBenefit6 }}
          </p>
          <p class="text-md md:text-lg mt-4 flex items-center">
            <Check class="min-w-4 min-h-4 w-4 h-4 mr-2 text-green-500" />
            {{ pageStrings.issenBenefit7 }}
          </p>
          <p class="text-md md:text-lg mt-4 flex items-center">
            <Check class="min-w-4 min-h-4 w-4 h-4 mr-2 text-green-500" />
            {{ pageStrings.issenBenefit8 }}
          </p>
        </CardContent>
        <CardFooter class="flex justify-between px-6 pb-6">
          <Button @click="subscribe" variant="primary" class="text-md md:text-lg" :disabled="subscribeResult.isFetching.value">
            {{ pageStrings.subscribeButton }} (USD $29 {{ pageStrings.perMonth }})
          </Button>
        </CardFooter>
      </Card>
      </div>
    </span>

</template>
