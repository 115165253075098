<script setup lang="ts">
import router from "@/router";

import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import IssenHeader from "@/practice/IssenHeader.vue";
import { ref, type Ref } from "vue";
import { getMessagesFromLanguage } from "@/languages/messages";
import { getDefaultSourceLanguage } from "@/practice/util";
import { apiRes, type DeleteUserResponse, type GetUserResponse, PracticeAPI } from "@/practice/api";
import ColorfulBackground from "@/practice/ColorfulBackground.vue";

const sourceLanguage = ref(getDefaultSourceLanguage());
const pageStrings= ref(getMessagesFromLanguage(sourceLanguage.value));

const getUserResult = apiRes<GetUserResponse>();
const error: Ref<string> = ref("");

async function goToChat() {
  router.push("/chat");
}

</script>

<template>
  <span class="page-container flex flex-col">
    <div class="w-full flex justify-between">
      <IssenHeader :pageStrings="pageStrings" :getUserResult="getUserResult" :preLogout="() => {}" :postLogout="() => {}"/>
    </div>

    <div class="flex-1 flex flex-col w-full">
      <div class="flex flex-grow justify-center items-center">
        <Card class="w-[350px] bg-bg-base shadow-card-rest">
          <CardHeader class="mb-0">
            <CardTitle class="text-xl mb-0">
              {{ pageStrings.accountDeleted }}</CardTitle>
          </CardHeader>
          <CardContent>
            <div v-if="error" class="text-red-500 font-bold mb-2 mt-0">{{ error }}</div>
            <p class="text-md">{{ pageStrings.accountDeletedDescription }}</p>
          </CardContent>
          <CardFooter class="flex px-6 pb-6">
            <Button @click="goToChat" variant="primary">
              {{ pageStrings.goToChat }}
            </Button>

          </CardFooter>
        </Card>
      </div>
    </div>
  </span>
</template>
