import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

import type { Updater } from '@tanstack/vue-table'
import type { Ref } from 'vue'
import { LocaleToLanguage } from '@/languages/messages'


export const FSRS_TARGET_RETENTION = 0.9;

export const getDefaultSourceLanguage = (): string => {
  const browserLang = navigator.language;
  const sourceLocale = browserLang.split('-')[0];
  const sourceLangId = LocaleToLanguage[sourceLocale];

  if (sourceLangId && Object.values(Language).includes(sourceLangId as Language)) {
    return sourceLangId;
  }
  
  return Language.ENGLISH;
}

export function cleanString(input: string): string {
    return input
        .toLowerCase()                          // Convert to lowercase
        .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '')  // Remove punctuation
        .replace(/\s+/g, '');                   // Remove all spaces
}

export async function createAudioBuffer(
    context: BaseAudioContext,
    float32Array: Float32Array,
    channels: number
): Promise<AudioBuffer> {
    const audioBuffer = context.createBuffer(channels, float32Array.length / channels, context.sampleRate);

    for (let channel = 0; channel < channels; channel++) {
        const channelData = audioBuffer.getChannelData(channel);
        for (let i = 0; i < channelData.length; i++) {
            channelData[i] = float32Array[i * channels + channel];
        }
    }

    return audioBuffer;
}

export function audioBufferToFloat32Array(audioBuffer: AudioBuffer): Float32Array {
    const numberOfChannels = audioBuffer.numberOfChannels;
    const length = audioBuffer.length;

    // Initialize a Float32Array to hold the interleaved data
    const interleaved = new Float32Array(length * numberOfChannels);

    // Interleave the channel data
    for (let channel = 0; channel < numberOfChannels; channel++) {
        const channelData = audioBuffer.getChannelData(channel);
        for (let i = 0; i < channelData.length; i++) {
            interleaved[i * numberOfChannels + channel] = channelData[i];
        }
    }

    return interleaved;
}

export async function resampleAudioBuffer(
    audioBuffer: AudioBuffer,
    targetSampleRate: number
): Promise<AudioBuffer> {
    const offlineContext = new OfflineAudioContext(
        audioBuffer.numberOfChannels,
        audioBuffer.duration * targetSampleRate,
        targetSampleRate
    );

    const bufferSource = offlineContext.createBufferSource();
    bufferSource.buffer = audioBuffer;
    bufferSource.connect(offlineContext.destination);
    bufferSource.start(0);

    return await offlineContext.startRendering();
}

export async function audioToWebSocketData(audioContext: AudioContext, float32Data: Float32Array, sampleRate: number): Promise<ArrayBuffer> {
    const audioBuffer = await createAudioBuffer(audioContext, float32Data, 1);
    const resampledBuffer = await resampleAudioBuffer(audioBuffer, sampleRate);
    const resampledData = audioBufferToFloat32Array(resampledBuffer);
    return float32ToByteArray(resampledData);
}

export function float32ToByteArray(float32Array: Float32Array): Uint8Array {
    // Create an ArrayBuffer with the same byte length as the float32Array
    const buffer = new ArrayBuffer(float32Array.length * 4); // 4 bytes per float32
    // Create a DataView to manipulate the ArrayBuffer
    const view = new DataView(buffer);

    // Store each float32 value as bytes
    for (let i = 0; i < float32Array.length; i++) {
        view.setFloat32(i * 4, float32Array[i], true); // true for little-endian
    }

    return new Uint8Array(buffer);
}

export const createAudioLink = (word: string, downloadBlob: Blob) => {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(downloadBlob);
  link.download = word + ".mp3";
  link.click();
  link.remove(); // Clean up the DOM
}

export const getErrorMessage = (event: any) => {
  if (event instanceof Event) {
    return 'A WebSocket error occurred.';
  } else if (event instanceof ErrorEvent) {
    return `Error message: ${event.message}`;
  } else if (typeof event === 'string') {
    return event;
  } else {
    return 'An unknown WebSocket error occurred.';
  }
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function valueUpdater<T extends Updater<any>>(updaterOrValue: T, ref: Ref) {
  ref.value = typeof updaterOrValue === 'function'
    ? updaterOrValue(ref.value)
    : updaterOrValue
}

export const convertRubyToRaw = (text: string | null): string => {
  if (!text) return '';

  // Remove rt tags and their content first
  text = text.replace(/<rt>.*?<\/rt>/g, '');
  
  // Remove ruby tags but keep their base content
  text = text.replace(/<ruby>|<\/ruby>/g, '');
  
  // Remove any remaining ruby or rt tags
  text = text.replace(/<\/?ruby>|<\/?rt>/g, '');
  return text;
};


export const boldedSentence = (sentence: string, word: string) => {
  if (!sentence || !word) return null;
  
  // Check if the word is within ruby tags
  const rubyRegex = new RegExp(`<ruby>[^<]*?(${word})[^<]*?<rt>.*?</rt></ruby>`, 'g');
  const hasRuby = rubyRegex.test(sentence);
  
  if (hasRuby) {
    // Replace the word within ruby tags
    return sentence.replace(
      new RegExp(`(<ruby>[^<]*?)(${word})([^<]*?<rt>.*?</rt></ruby>)`, 'g'), 
      '$1<strong>$2</strong>$3'
    );
  } else {
    // Regular replacement
    return sentence.replace(new RegExp(`(${word})`, 'g'), '<strong>$1</strong>');
  }
};


export enum Language {
    AFRIKAANS = "afrikaans",
    ARABIC = "arabic",
    ARMENIAN = "armenian",
    AZERBAIJANI = "azerbaijani",
    BELARUSIAN = "belarusian",
    BOSNIAN = "bosnian",
    BULGARIAN = "bulgarian",
    CATALAN = "catalan",
    MANDARIN_CHINESE = "mandarin_chinese",
    CANTONESE = "cantonese",
    CROATIAN = "croatian",
    CZECH = "czech",
    DANISH = "danish",
    DUTCH = "dutch",
    ENGLISH = "english",
    ESTONIAN = "estonian",
    FINNISH = "finnish",
    FRENCH = "french",
    GALICIAN = "galician",
    GERMAN = "german",
    GREEK = "greek",
    GUJARATI = "gujarati",
    HEBREW = "hebrew",
    HINDI = "hindi",
    HUNGARIAN = "hungarian",
    ICELANDIC = "icelandic",
    INDONESIAN = "indonesian",
    ITALIAN = "italian",
    JAPANESE = "japanese",
    KANNADA = "kannada",
    KAZAKH = "kazakh",
    KOREAN = "korean",
    LATVIAN = "latvian",
    LITHUANIAN = "lithuanian",
    MACEDONIAN = "macedonian",
    MALAY = "malay",
    MARATHI = "marathi",
    MAORI = "maori",
    NEPALI = "nepali",
    NORWEGIAN = "norwegian",
    PERSIAN = "persian",
    POLISH = "polish",
    PORTUGUESE = "portuguese",
    ROMANIAN = "romanian",
    RUSSIAN = "russian",
    SERBIAN = "serbian",
    SLOVAK = "slovak",
    SLOVENIAN = "slovenian",
    SPANISH = "spanish",
    SWAHILI = "swahili",
    SWEDISH = "swedish",
    TAGALOG = "tagalog",
    TAMIL = "tamil",
    THAI = "thai",
    TURKISH = "turkish",
    UKRAINIAN = "ukrainian",
    URDU = "urdu",
    VIETNAMESE = "vietnamese",
    WELSH = "welsh"
}

export const LanguageToFlag: { [key: string]: string } = {
  "afrikaans": "za",
  "arabic": "sa",
  "armenian": "am",
  "azerbaijani": "az",
  "belarusian": "by",
  "bosnian": "ba",
  "bulgarian": "bg",
  "catalan": "es",
  "cantonese": "hk",
  "mandarin_chinese": "cn",
  "croatian": "hr",
  "czech": "cz",
  "danish": "dk",
  "dutch": "nl",
  "english": "us",
  "estonian": "ee",
  "finnish": "fi",
  "french": "fr",
  "galician": "es",
  "german": "de",
  "greek": "gr",
  "gujarati": "in",
  "hebrew": "il",
  "hindi": "in",
  "hungarian": "hu",
  "icelandic": "is",
  "indonesian": "id",
  "italian": "it",
  "japanese": "jp",
  "kannada": "in",
  "kazakh": "kz",
  "korean": "kr",
  "latvian": "lv",
  "lithuanian": "lt",
  "macedonian": "mk",
  "malay": "my",
  "marathi": "in",
  "maori": "nz",
  "nepali": "np",
  "norwegian": "no",
  "persian": "ir",
  "polish": "pl",
  "portuguese": "pt",
  "romanian": "ro",
  "russian": "ru",
  "serbian": "rs",
  "slovak": "sk",
  "slovenian": "si",
  "spanish": "es",
  "swahili": "tz",
  "swedish": "se",
  "tagalog": "ph",
  "tamil": "in",
  "thai": "th",
  "turkish": "tr",
  "ukrainian": "ua",
  "urdu": "pk",
  "vietnamese": "vn",
  "welsh": "gb"
};

export function getLanguageFlagLipis(language: string): string {
  // Using https://flagicons.lipis.dev/
  const lowercaseLanguage = language.toLowerCase();
  return LanguageToFlag[lowercaseLanguage] || 'un'; // Default is UN!
}


export function getLanguageFlag(language: string): string {
  // Using Figma SVG icons
  const lowercaseLanguage = language.toLowerCase();
  const uppercaseLanguage = LanguageToFlag[lowercaseLanguage];
  if (!uppercaseLanguage) {
    return 'earth'; // Default is a nice earth icon!
  }
  return uppercaseLanguage.toUpperCase();
}

export function getLanguageFlagCountry(language: string, country: string): string {
  if (country === "United States") {
    return "US";
  } else if (country === "United Kingdom") {
    return "GB";
  } else if (country === "Canada") {
    return "CA";
  } else if (country === "Australia") {
    return "AU";
  } else if (country === "New Zealand") {
    return "NZ";
  } else if (country === "Ireland") {
    return "IE";
  } else if (country === "South Africa") {
    return "ZA";
  } else if (country === "India") {
    return "IN";
  } else if (country === "Spain") {
    return "ES";
  } else if (country == "Peru") {
    return "PE";
  } else if (country === "Mexico") {
    return "MX";
  } else if (country === "Argentina") {
    return "AR";
  } else if (country === "Colombia") {
    return "CO";
  } else if (country === "Chile") {
    return "CL";
  } else if (country === "Saudi Arabia") {
    return "SA";
  } else if (country === "Egypt") {
    return "EG";
  } else if (country === "United Arab Emirates") {
    return "AE";
  } else if (country === "Morocco") {
    return "MA";
  } else if (country === "Taiwan") {
    return "TW";
  } else if (country === "Brazil") {
    return "BR";
  } else if (country === "Portugal") {
    return "PT";
  } else if (country === "Armenia") {
    return "AM";
  } else if (country === "Azerbaijan") {
    return "AZ";
  } else if (country === "Belarus") {
    return "BY";
  } else if (country === "Bosnia and Herzegovina") {
    return "BA";
  } else if (country === "Bulgaria") {
    return "BG";
  } else if (country === "China") {
    return "CN";
  } else if (country === "Croatia") {
    return "HR";
  } else if (country === "Czech Republic") {
    return "CZ";
  } else if (country === "Denmark") {
    return "DK";
  } else if (country === "Netherlands") {
    return "NL";
  } else if (country === "Estonia") {
    return "EE";
  } else if (country === "Finland") {
    return "FI";
  } else if (country === "France") {
    return "FR";
  } else if (country === "Germany") {
    return "DE";
  } else if (country === "Greece") {
    return "GR";
  } else if (country === "Hungary") {
    return "HU";
  } else if (country === "Iceland") {
    return "IS";
  } else if (country === "Indonesia") {
    return "ID";
  } else if (country === "Italy") {
    return "IT";
  } else if (country === "Japan") {
    return "JP";
  } else if (country === "Kazakhstan") {
    return "KZ";
  } else if (country === "South Korea") {
    return "KR";
  } else if (country === "Latvia") {
    return "LV";
  } else if (country === "Lithuania") {
    return "LT";
  } else if (country === "North Macedonia") {
    return "MK";
  } else if (country === "Malaysia") {
    return "MY";
  } else if (country === "Nepal") {
    return "NP";
  } else if (country === "Norway") {
    return "NO";
  } else if (country === "Iran") {
    return "IR";
  } else if (country === "Poland") {
    return "PL";
  } else if (country === "Romania") {
    return "RO";
  } else if (country === "Russia") {
    return "RU";
  } else if (country === "Serbia") {
    return "RS";
  } else if (country === "Slovakia") {
    return "SK";
  } else if (country === "Slovenia") {
    return "SI";
  } else if (country === "Tanzania") {
    return "TZ";
  } else if (country === "Sweden") {
    return "SE";
  } else if (country === "Philippines") {
    return "PH";
  } else if (country === "Sri Lanka") {
    return "LK";
  } else if (country === "Thailand") {
    return "TH";
  } else if (country === "Turkey") {
    return "TR";
  } else if (country === "Ukraine") {
    return "UA";
  } else if (country === "Pakistan") {
    return "PK";
  } else if (country === "Vietnam") {
    return "VN";
  } else if (country === "Kenya") {
    return "KE";
  } else if (country === "Jordan") {
    return "JO";
  }


  // Using Figma SVG icons
  const lowercaseLanguage = language.toLowerCase();
  const uppercaseLanguage = LanguageToFlag[lowercaseLanguage];
  if (!uppercaseLanguage) {
    return 'earth'; // Default is a nice earth icon!
  }
  return uppercaseLanguage.toUpperCase();
}

