<script setup lang="ts">
import { PhSpinner, PhBookOpenText, PhCaretRight, PhChats, PhPenNib, PhPaperPlaneRight, PhMicrophone, PhMicrophoneSlash, PhGearSix, PhPersonSimpleTaiChi, PhLightbulb, PhFilmSlate, PhCircleNotch, PhArrowClockwise} from '@phosphor-icons/vue';
import { ToolbarSwitch } from '@/components/ui/toolbarswitch'
import { Slider } from '@/components/ui/slider'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import ConfirmDialog from './ConfirmDialog.vue';
import router from "@/router";
import { apiRes, PracticeAPI, type LocalizedKickstart, type UpdateUserResponse, type GetLocalizedKickstartsResponse } from './api';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import type { UserSettings } from '@/types/model';
import { Checkbox } from '@/components/ui/checkbox'
import { domainPort, protocol } from '@/url';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
const tooltipContent = ref("");
const tooltipShortcut = ref("");

const tooltipHovering = ref(false);
const tutorPatience = ref([0]);
const speechSpeedRatio = ref([0]);
const tutorMenuOpen = ref(false);
const kickstartMenuOpen = ref(false);
const tutorMenuRef = ref(null);
const pressedKeys = new Set();
const timesEditedTutorPatience = ref(0);
const timesEditedManualMode = ref(0);
const timesEditedSpeechSpeedRatio = ref(0);
const API: PracticeAPI = new PracticeAPI(protocol + domainPort);
const updateUserResult = apiRes<UpdateUserResponse>();
const hoveringOnSettings = ref(false);
const hoveringOnKickstart = ref(false);
const justSentMessage = ref(false);
const openSubMenu = ref(0);
const manualMode = ref(false);


const props = defineProps<{
  pageStrings: { [key: string]: string },
  isMuted: boolean,
  muteDisabled: boolean,
  toggleMute: (mute: boolean) => void,
  aiReady: boolean,
  leaveCall: () => void,
  kickstart: LocalizedKickstart | null,
  availableDevices: Object[],
  selectedDevice: Object | null,
  updatingDevices: boolean,
  initialTutorPatience: number,
  initialSpeechSpeedRatio: number,
  initialManualMode: boolean,
  sendMessage: () => void,
  localizedKickstarts: GetLocalizedKickstartsResponse | null,
  changeKickstart: (kickStartMessage: LocalizedKickstart) => void,
  isOnboarded: boolean,
  canPracticeSavedWords: boolean
}>();

const emit = defineEmits<{
  (e: 'reloadUser'): void,
  (e: 'updateDevices', devices: { mic?: string, speaker?: string }): void,
}>();

onMounted(() => {
  document.addEventListener("keydown", handleShortcut);
  tutorPatience.value = [props.initialTutorPatience];
  speechSpeedRatio.value = [props.initialSpeechSpeedRatio];
  manualMode.value = props.initialManualMode;
});
// Remove global listener on unmount
onUnmounted(() => {
  document.removeEventListener("keydown", handleShortcut);
});



watch(() => props.initialTutorPatience, (newValue) => {
  tutorPatience.value = [newValue];
});

watch(() => props.initialSpeechSpeedRatio, (newValue) => {
  speechSpeedRatio.value = [newValue];
});    

watch(() => props.initialManualMode, (newValue) => {
  manualMode.value = newValue;
});

const changeKickstart = (kickstart: LocalizedKickstart) => {
  props.changeKickstart(kickstart);
  toggleKickstartMenu();
  setOpenSubMenu(0);
}
const setOpenSubMenu = (subMenu: number) => {
  openSubMenu.value = subMenu;
}

const toggleKickstartMenu = () => {
    if (!props.isOnboarded) {
        return;
    }
    kickstartMenuOpen.value = !kickstartMenuOpen.value;
    if (!kickstartMenuOpen.value) {
        setOpenSubMenu(0);
    }
}
const setKickstartMenuOpen = (open: boolean, skipHoverCheck: boolean) => {
    if (hoveringOnKickstart.value && !skipHoverCheck) {
        return;
    }
    kickstartMenuOpen.value = open;
    if (!open) {
        setOpenSubMenu(0);
    }
}

const toggleTutorMenu = () => {
    tutorMenuOpen.value = !tutorMenuOpen.value;
    if (tutorMenuOpen.value) {
        emit('updateDevices', {});
    }
}
const setTutorMenuOpen = (open: boolean, skipHoverCheck: boolean) => {
    if (hoveringOnSettings.value && !skipHoverCheck) {
        return;
    }
    tutorMenuOpen.value = open;
    if (tutorMenuOpen.value) {
        emit('updateDevices', {});
    }
}


const clickLeaveCall = () => {
    if (props.aiReady) {
        props.leaveCall();
    }
}
const clickedContinue = () => {
    router.push('/settings');
}
const clickSendMessage = () => {
    justSentMessage.value = true;
    props.sendMessage();
    setTimeout(() => {
        justSentMessage.value = false;
    }, 1000);
}

const isMac = () => {
    return navigator.userAgent.toLowerCase().indexOf('macintosh') > -1;
};

const getCmdCtrlKey = () => {
    if (isMac()) {
        return "⌘";
    } else {
        return "Ctrl+";
    }
}

const lessonStr = computed(() => {
    if (!props.kickstart || props.kickstart.kickstart.type === "open_ended") {
        return props.pageStrings.freeConversation || "";
    } else {
        if (props.kickstart.kickstart.type === "scenario") {
            return props.pageStrings.scenario;
        } else if (props.kickstart.kickstart.type === "topic" || props.kickstart.kickstart.type === "tutor_life") {
            return props.pageStrings.topic;
        } else if (props.kickstart.kickstart.type === "grammar") {
            return props.pageStrings.grammarLesson;
        } else if (props.kickstart.kickstart.type === "student_life") {
            return props.pageStrings.personalLife;
        } else if (props.kickstart.kickstart.type === "saved_words") {
            return props.pageStrings.practiceSavedWords;
        } else {
            return props.kickstart.kickstart.type;
        }
    }
})

const lessonDescription = computed(() => {
    if (!props.kickstart || props.kickstart.kickstart.type === "open_ended" || props.kickstart.kickstart.type === "saved_words") {
        return props.pageStrings.clickToChange || "";
    } else {
        return props.kickstart.localized_description;
    }
})

const onHover = (event: any) => {
    if (!tooltipHovering.value && tooltipContent.value !== "") {
        return;
    }
    const hoveredId = event.target.id; // Access `data-id`
    tooltipHovering.value = true;
    if (hoveredId === "kickstartPicker" || hoveredId === "kickstartPicker2" || hoveredId === "kickstartPicker3" || hoveredId === "kickstartPicker4" || hoveredId === "kickstartPicker5") {
        tooltipContent.value = props.pageStrings.kickstartPicker;
        tooltipShortcut.value = getCmdCtrlKey() + "2";
    } else if (hoveredId === "speed") {
        tooltipContent.value = props.pageStrings.tutorSpeedSettings;
        tooltipShortcut.value = getCmdCtrlKey() + "1";
    } else if (hoveredId === "mute") {
        if (props.isMuted) {
            tooltipContent.value = props.pageStrings.unmuteMic;
        } else {
            tooltipContent.value = props.pageStrings.muteMic;
        }
        tooltipShortcut.value = "M";
    } else if (hoveredId === "settings") {
        tooltipContent.value = props.pageStrings.goToSettings;
        tooltipShortcut.value = "";
    } else if (hoveredId === "leave") {
        tooltipContent.value = props.pageStrings.endLesson;
        tooltipShortcut.value = getCmdCtrlKey() + "3";
    } else if (hoveredId === "send") {
        tooltipContent.value = props.pageStrings.sendMessage;
        tooltipShortcut.value = "Enter";
    }

};
const onLeave = () => {
    if (tooltipHovering.value) {
        tooltipHovering.value = false;
        tooltipContent.value = "";
        tooltipShortcut.value = "";
    }
}

watch(() => props.isMuted, (newValue) => {
    if (newValue) {
        tooltipContent.value = props.pageStrings.micMuted;
        tooltipShortcut.value = "";
    } else {
        tooltipContent.value = props.pageStrings.micUnmuted;
        tooltipShortcut.value = "";
    }
    tooltipHovering.value = false;
    // Clear tooltip after 2 seconds if content hasn't changed
    const timeoutId = setTimeout(() => {
        if (tooltipContent.value === (newValue ? props.pageStrings.micMuted : props.pageStrings.micUnmuted)) {
            tooltipContent.value = "";
            tooltipShortcut.value = "";
        }
    }, 1500);

    // Clean up timeout on next change
    return () => clearTimeout(timeoutId);
});



const handleShortcut = (event: any) => {
    // Skip if key is being held down (repeat event)
    if (event.repeat || pressedKeys.has(event.key)) {
        return;
    }
    pressedKeys.add(event.key);
    // Remove key from pressedKeys after 500ms
    setTimeout(() => {
        pressedKeys.delete(event.key);
    }, 300);
    const isCommandKey = isMac() ? event.metaKey : event.ctrlKey;
    if (event.key.toLowerCase() === 'm') {
        event.preventDefault(); // Prevent default action
        props.toggleMute(!props.isMuted);
    } else if (isCommandKey && event.key.toLowerCase() === '1') {
        event.preventDefault(); // Prevent default action
        toggleTutorMenu();
    } else if (isCommandKey && event.key.toLowerCase() === '2') {
        event.preventDefault(); // Prevent default action
        toggleKickstartMenu();
    } else if (isCommandKey && event.key.toLowerCase() === '3') {
        event.preventDefault(); // Prevent default action
        props.leaveCall();
    } else if (event.key.toLowerCase() === 'enter') {
        event.preventDefault(); // Prevent default action
        clickSendMessage();
    }
};
watch(tutorPatience, async (newValue, oldValue) => {
  if (newValue !== null && oldValue !== undefined && oldValue[0] != 0) { 
    const thisTime = timesEditedTutorPatience.value;
    timesEditedTutorPatience.value += 1;
    await new Promise(resolve => setTimeout(resolve, 1000));
    if (thisTime + 1=== timesEditedTutorPatience.value) { // This means the user has stopped editing
      await API.update_user(updateUserResult, {
        tutorPatience: tutorPatience.value[0],
      });
      emit('reloadUser');
    }
  }
});

watch(manualMode, async (newValue) => {
  const thisTime = timesEditedManualMode.value;
  timesEditedManualMode.value += 1;
  await new Promise(resolve => setTimeout(resolve, 1000));
  if (thisTime + 1 === timesEditedManualMode.value) { // This means the user has stopped editing
    await API.update_user(updateUserResult, {
      manualMode: newValue,
    });
    emit('reloadUser');
  }
});



watch(speechSpeedRatio, async (newValue, oldValue) => {
  if (newValue !== null && oldValue !== undefined && oldValue[0] != 0) { 
    const thisTime = timesEditedSpeechSpeedRatio.value;
    timesEditedSpeechSpeedRatio.value += 1;
    await new Promise(resolve => setTimeout(resolve, 1000));
    if (thisTime + 1 === timesEditedSpeechSpeedRatio.value) { // This means the user has stopped editing
      await API.update_user(updateUserResult, {
        speechSpeedRatio: speechSpeedRatio.value[0],
      });
      emit('reloadUser');
    }
  }
});
const openEndedKickstart = computed(() => {
  return {
    kickstart: {
      id: "open_ended",
      description: props.pageStrings.openEnded,
      prompt: "",
      type: "open_ended"
    },
    language: "",
    localized_description: props.pageStrings.openEnded
  }
})
const kickstartTypes = computed(() => {
    let ret: Record<number, { title: string; kickstarts: LocalizedKickstart[] }> = {
        3: {
            title: props.pageStrings.studentLifeKickstart,
            kickstarts: props.localizedKickstarts?.studentLifeKickstarts || [], 
        },
        4: {
            title: props.pageStrings.topicKickstart,
            kickstarts: props.localizedKickstarts?.topicKickstarts || [],
        },
        5: {
            title: props.pageStrings.scenario,
            kickstarts: props.localizedKickstarts?.scenarioKickstarts || [],
        }
    };
    
    if (props.localizedKickstarts?.grammarKickstarts && props.localizedKickstarts?.grammarKickstarts.length > 0) {
        ret[2] = {
            title: props.pageStrings.grammarLesson,
            kickstarts: props.localizedKickstarts?.grammarKickstarts || [],
        };
    }
    
    return ret;
})

</script>

<style scoped>  
    .tooltip-shortcut {
        color: hsla(0, 0%, 100%, 0.65);
    }
    .component-shadow {
        box-shadow: 0px 2px 4px 0px var(--Components-shadow, rgba(0, 0, 0, 0.09));
    }
</style>

<template>  

    <span
    class="h-[1.5rem] component-shadow rounded-xs text-toolbar-icon mb-2 p-[0.375rem] text-sm flex items-center"
        :class="{ 'bg-[#181818]': tooltipHovering, 'bg-toolbar-background': !tooltipHovering, 'invisible': tooltipContent === '' }">
        {{ tooltipContent}} <span  class="ml-1 tooltip-shortcut">{{ tooltipContent === '' ? '1234as' : tooltipShortcut }}</span>
    </span>
    <span @keydown="handleShortcut" class="p-1 mb-4 md:mb-10 flex w-fit h-fit justify-start items-center bg-toolbar-background text-toolbar-text-primary shadow-toolbar rounded-lg"
    >

        <DropdownMenu ref="kickstartRef" :open="kickstartMenuOpen" :modal="false">
            <DropdownMenuTrigger
            class="rounded-md align-start mr-1"
            :class="{ 'bg-toolbar-hover': hoveringOnKickstart, 'bg-toolbar-pressed': kickstartMenuOpen }"
            >
                <span id="kickstartPicker" class="flex flex-row items-center rounded-md"
                @click="toggleKickstartMenu"
                @mouseover="(e) => { onHover(e); hoveringOnKickstart=true; }" @mouseleave="onLeave(); hoveringOnKickstart=false;">
                    <span id="kickstartPicker2" class="w-12 h-12 mr-1 ml-1 bg-toolbar-control rounded-full flex justify-center items-center"
                :class="{ 'bg-toolbar-hover': hoveringOnKickstart, 'bg-toolbar-pressed': kickstartMenuOpen }"
                    >
                        <PhChats class="w-6 h-6" v-if="!kickstart || kickstart.kickstart.type === 'open_ended'"/>
                        <PhBookOpenText class="w-6 h-6" v-if="kickstart && kickstart.kickstart.type === 'grammar'"/>
                        <PhPersonSimpleTaiChi class="w-6 h-6" v-if="kickstart && kickstart.kickstart.type === 'student_life'"/>
                        <PhLightbulb class="w-6 h-6" v-if="kickstart && (kickstart.kickstart.type === 'topic' || kickstart.kickstart.type === 'tutor_life')"/>
                        <PhFilmSlate class="w-6 h-6" v-if="kickstart && kickstart.kickstart.type === 'scenario'"/>
                        <PhPenNib class="w-6 h-6" v-if="kickstart && kickstart.kickstart.type === 'saved_words'"/>
                    </span>

                    <span id="kickstartPicker3" class="flex flex-col pr-4 h-12 justify-center hidden md:flex">
                        <p id="kickstartPicker4" class="m-0 p-0 text-sm">{{ lessonStr }} </p>
                        <p id="kickstartPicker5" class="m-0 p-0 text-sm text-toolbar-text-secondary">{{ lessonDescription.length > 25 ? lessonDescription.slice(0,25) + '...' : lessonDescription }}</p>
                    </span>
                </span>
            </DropdownMenuTrigger>
            <DropdownMenuContent 
            v-on:interactOutside="setKickstartMenuOpen(false, false)" 
            v-on:escapeKeyDown="setKickstartMenuOpen(false, true)" 
            side="top" :sideOffset="12" class="w-32 mb-1 md:mb-0 md:w-48 p-[0.5rem] text-toolbar-text-primary bg-toolbar-pressed">
                <DropdownMenuLabel 
                class="m-0 p-0 p-[0.5rem] hover:bg-toolbar-hover cursor-pointer flex justify-between rounded-sm"
                @mouseover="setOpenSubMenu(0)"
                @click="changeKickstart(openEndedKickstart)"
                >
                    <p class="text-md-medium text-toolbar-text-primary text-left">{{pageStrings.goMoreOpenEnded }}</p>
                    <div class="w-4 h-4"/>

                </DropdownMenuLabel>
                <DropdownMenuLabel 
                class="m-0 p-0 p-[0.5rem] hover:bg-toolbar-hover cursor-pointer flex justify-between rounded-sm"
                @mouseover="setOpenSubMenu(0)"
                @click="changeKickstart(props.localizedKickstarts!.savedWordsKickstarts[0])"
                v-if="props.canPracticeSavedWords && props.localizedKickstarts && props.localizedKickstarts?.savedWordsKickstarts.length > 0"
                >
                    <p class="text-md-medium text-toolbar-text-primary text-left">{{pageStrings.practiceSavedWords }}</p>
                    <div class="w-4 h-4"/>

                </DropdownMenuLabel>
                <DropdownMenu v-for="(type, index) in kickstartTypes" :key="index" :open="openSubMenu === index" :modal="false">
                    <DropdownMenuTrigger class="w-full">
                        <DropdownMenuLabel 
                        class="m-0 p-0 p-[0.5rem] hover:bg-toolbar-hover cursor-pointer flex justify-between rounded-sm"
                        :class="{ 'bg-toolbar-hover': openSubMenu === index }"
                        @mouseover="setOpenSubMenu(index)"
                        >
                            <p class="text-md-medium text-toolbar-text-primary text-left">{{ type.title }}</p>
                            <PhCaretRight class="w-4 h-4"/>

                        </DropdownMenuLabel>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent side="right" :sideOffset="15"
                     v-on:escapeKeyDown="setKickstartMenuOpen(false, true)" 
                     class="w-48 md:w-full md:max-w-64 p-[0.5rem] text-toolbar-text-primary bg-toolbar-pressed">
                        <DropdownMenuItem v-for="kickstart in type.kickstarts" :key="kickstart.kickstart.id"
                            class="p-[0.5rem] flex flex-col cursor-pointer hover:bg-toolbar-hover"
                            @click="changeKickstart(kickstart)"
                        >
                            <p class="text-md-medium text-toolbar-text-primary">{{ kickstart.localized_description }}</p>
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            </DropdownMenuContent>
        </DropdownMenu>
        <span @click="clickLeaveCall" class="mr-1 w-20 flex justify-center items-center hover:bg-toolbar-hover rounded-md cursor-pointer"
        >
            <ToolbarSwitch id="leave" @mouseover="onHover" @mouseleave="onLeave" :checked="props.aiReady" @update:checked="clickLeaveCall" />

        </span>

        <DropdownMenu ref="tutorMenuRef" :open="tutorMenuOpen" :modal="false">
            <DropdownMenuTrigger>
                <span id="speed" @mouseover="(e) => { onHover(e); hoveringOnSettings=true; }" @mouseleave="onLeave(); hoveringOnSettings=false;"
                @click="toggleTutorMenu"
                 class="w-12 h-12 flex justify-center items-center hover:bg-toolbar-pressed rounded-md cursor-pointer" :class="{ 'bg-toolbar-pressed': tutorMenuOpen, 'mr-1': manualMode, 'mr-0.25': !manualMode }">
                    <PhGearSix class="w-6 h-6"/>
                </span>
            </DropdownMenuTrigger>
            <DropdownMenuContent v-on:interactOutside="setTutorMenuOpen(false, false)" v-on:escapeKeyDown="setTutorMenuOpen(false, true)" side="top" :sideOffset="12" class="w-48 p-[0.875rem] pb-[1.25rem] text-toolbar-text-primary bg-toolbar-pressed">
                <DropdownMenuItem class="m-0 p-0 mb-4 flex flex-col hover:bg-toolbar-pressed">
                    <p class="text-md-medium text-toolbar-text-primary">{{ pageStrings.speechSpeed }}</p>
                    <p class="text-xs text-toolbar-text-secondary">{{ speechSpeedRatio[0] }}x</p>
                    <Slider 
                        class="mt-2"
                        v-model="speechSpeedRatio"
                        :max="1.2"
                        :min="0.7"
                        :step="0.05"
                        :thumbValue="''"
                    /> 
                </DropdownMenuItem>
                <DropdownMenuItem class="m-0 p-0 mb-4 flex flex-col">
                    <p class="text-md-medium text-toolbar-text-primary">{{ pageStrings.tutorPatience }}</p>
                    <p class="text-xs text-toolbar-text-secondary">{{ pageStrings.tutorPatienceDescriptionShort }}</p>
                    <Slider 
                        class="mt-2"
                        v-model="tutorPatience"
                        :disabled="manualMode"
                        :max="10"
                        :min="1"
                        :step="1"
                        :thumbValue="tutorPatience[0]"
                    /> 
                </DropdownMenuItem>
                <DropdownMenuItem class="m-0 p-0 mb-4 flex flex-col hover:bg-toolbar-pressed">
                    <p class="text-md-medium text-toolbar-text-primary">{{ pageStrings.manualMode}}</p>
                    <span class="flex items-center">
                    <Checkbox :checked="manualMode" @update:checked="manualMode = $event" class="mr-2"/>
                    <p class="text-xs text-toolbar-text-secondary">{{ pageStrings.manualModeDescriptionMobile}}</p>
                    </span>
                </DropdownMenuItem>
            <DropdownMenuItem class="m-0 p-0 mb-4 flex flex-col">
                <p class="text-md-medium text-toolbar-text-primary flex">{{ pageStrings.microphone }} 
                    <PhArrowClockwise @click="$emit('updateDevices', {})" class="ml-2 mt-[0.12rem] w-4 h-4 cursor-pointer" v-if="!props.updatingDevices"/>
                    <PhSpinner class="ml-2 mt-[0.12rem] w-4 h-4 animate-spin" v-if="props.updatingDevices"/> </p>
                <Select 
                  @update:model-value="$emit('updateDevices', { mic: $event })"
                  :model-value="(props.selectedDevice as any)?.mic?.deviceId"
                  :disabled="props.updatingDevices"
                >
                  <SelectTrigger class="w-full">
                    <SelectValue :placeholder="pageStrings.selectMicrophone" />
                  </SelectTrigger>
                  <SelectContent class="bg-toolbar-pressed text-toolbar-text-primary">
                    <SelectGroup>
                      <SelectItem
                        class="text-sm cursor-pointer"
                        v-for="device in props.availableDevices.filter(d => (d as any).kind === 'audioinput')"
                        :key="(device as any).deviceId"
                        :value="(device as any).deviceId"
                      >
                        {{ (device as any).label || `Microphone ${(device as any).deviceId}` }}
                      </SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
            </DropdownMenuItem>
            <DropdownMenuItem class="m-0 p-0 mb-4 flex flex-col">
                <p class="text-md-medium text-toolbar-text-primary flex">{{ pageStrings.speaker }}
                    <PhArrowClockwise @click="$emit('updateDevices', {})" class="ml-2 mt-[0.12rem] w-4 h-4 cursor-pointer" v-if="!props.updatingDevices"/>
                    <PhSpinner class="ml-2 mt-[0.12rem] w-4 h-4 animate-spin" v-if="props.updatingDevices"/></p>
                <Select 
                  @update:model-value="$emit('updateDevices', { speaker: $event })"
                  :model-value="(props.selectedDevice as any)?.speaker?.deviceId"
                  :disabled="props.updatingDevices"
                >
                  <SelectTrigger class="w-full">
                    <SelectValue class="text-sm" :placeholder="pageStrings.selectAudioOutput" /> 
                  </SelectTrigger>
                  <SelectContent class="bg-toolbar-pressed text-toolbar-text-primary">
                    <SelectGroup>
                      <SelectItem
                        class = "text-sm cursor-pointer"
                        v-for="device in props.availableDevices.filter(d => (d as any).kind === 'audiooutput')"
                        :key="(device as any).deviceId"
                        :value="(device as any).deviceId"
                      >
                        {{ (device as any).label || `Speaker ${(device as any).deviceId}` }}
                      </SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
            </DropdownMenuItem>
            <DropdownMenuItem class="m-0 p-0 flex flex-col hover:bg-toolbar-pressed">
            <ConfirmDialog :clickedContinue="clickedContinue" :pageStrings="props.pageStrings">
                <span id="settings" @mouseover="onHover" @mouseleave="onLeave" class="mr-1 flex justify-center items-center cursor-pointer">
                    <p>{{ pageStrings.seeAllSettings }}</p>
                </span>
            </ConfirmDialog>
            </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
        <span id="mute" @mouseover="onHover" @mouseleave="onLeave" @click="props.toggleMute(!props.isMuted)" 
            class="w-12 h-12 flex justify-center items-center hover:bg-toolbar-hover rounded-md cursor-pointer"
            :class="{ 'mr-1': manualMode }"
        >
            <PhMicrophoneSlash v-if="props.isMuted || !props.aiReady || props.muteDisabled" class="w-6 h-6"/>
            <PhMicrophone v-else class="w-6 h-6"/>
        </span>


        <span id="send" @mouseover="onHover" @mouseleave="onLeave" @click="clickSendMessage" 
            v-if="manualMode"
            class="w-12 h-12 flex justify-center items-center hover:bg-toolbar-hover rounded-md cursor-pointer"
        >
            <PhPaperPlaneRight class="w-6 h-6" v-if="!justSentMessage"/>
            <PhCircleNotch class="animate-spin [animation-duration:2s] w-6 h-6" v-if="justSentMessage"/>
        </span>

    </span>
</template>