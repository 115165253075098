export const STUDY_AHEAD_TIME_SECS = 1200; // 20 minutes

export enum UserStatus {
    TRIAL = "TRIAL",
    STANDARD = "STANDARD",
    SUBSCRIBED = "SUBSCRIBED"
}

export enum ProficiencyLevel {
    BEGINNER = "beginner",
    INTERMEDIATE = "intermediate",
    ADVANCED = "advanced",
}

export enum TutorLanguageUsage {
    TARGET_LANGUAGE = "target_language",
    MIXED_LANGUAGE = "mixed_language",
}

export enum TutorFormalness {
    STANDARD = "standard",
    FORMAL = "formal",
    SLANG = "slang",
}

export enum StudentGrammarHelp {
    TUTOR_INITIATED = "tutor_initiated",
    STUDENT_INITIATED = "student_initiated",
}

export enum SavedWordsPracticeStyle {
    DEFINITION = "definition",
    EXAMPLE = "example"
}

export type ContentSection = {
    content: string,
    isWord: boolean,
    definition: string
}

export type Agent = {
    agentId: string;
    agentTemplateId: string;
    userId: string;
    voiceId: string;
    voiceService: string;
    agentType: string;
    name: string;
    language: string;
    bio?: string;
    gender: string;
    age: number;
    homeCity: string;
    homeCountry: string;
    timezone?: string;
    createdDate: number;
}

export type UserSettings = {
    userId: string;
    username: string;
    name: string;
    sourceLang: string;
    guiLang: string;
    hasOnboarded: boolean;
    userStatus: UserStatus;
    maxUsageStandard: number;
    tutorPatience: number;
    dailyMinutesGoal: number;
    speechSpeedRatio: number;
    selectedAgentTemplateId: string;
    llmMode: boolean;
    noiseCancellation: boolean;
    timezone?: string;
    stripeCustomerId?: string;
    manualMode: boolean;
    maxDailyReviews: number;
    enableRecognitionCards: boolean;
    enableRecallCards: boolean;
    playAudioAutomatically: boolean;
    targetLangDefinition: boolean;
    enableSpeakingMode: boolean;
    showSentenceInFront: boolean;
    lessonMode: boolean;
}

export type LanguageSettings = {
    id: string;
    userId: string;
    language: string;
    proficiencyLevel: string;
    formalness: string;
    grammarCorrection: string;
    tutorLanguageUsage: string;
}

export type KickstartMessage = {
    type: string;
    kickstart_id: string;
    kickstart_type: string;
}

export class Message {
    messageId: string;
    fromName: string;
    fromId: string;
    toId: string;
    content: ContentSection[];
    time: number;
    confirmed: boolean;
    isKickstartMessage: boolean;
    constructor(messageId: string, fromId: string, fromName: string, toId: string, content: ContentSection[], time: number, confirmed: boolean, isKickstartMessage: boolean) {
        this.messageId = messageId;
        this.fromId = fromId;
        this.fromName = fromName;
        this.toId = toId;
        this.content = content;
        this.time = time;
        this.confirmed = confirmed;
        this.isKickstartMessage = isKickstartMessage;
    }
}

export enum CardType {
    RECALL = "RECALL",
    RECOGNITION = "RECOGNITION"
}

export enum State {
    LEARNING = 1,
    REVIEW = 2,
    RELEARNING = 3
}

export enum Rating {
    AGAIN = 1,
    HARD = 2,
    GOOD = 3,
    EASY = 4
}

export type Word = {
    id: number,
    userId: string,
    word: string,
    wordWithTransliteration: string,
    sentence: string,
    messageId: string,
    addedTime: number,
    language: string,
    shortDefinitionSourceLang: string,
    shortDefinitionTargetLang: string,
    longDefinitionSourceLang: string,
    longDefinitionTargetLang: string,
    wordAudioS3Link?: string,
    sentenceAudioS3Link?: string
}

export type Card = {
    id: number,
    wordId: number,
    cardType: CardType,
    state: State,
    step: number | null,
    stability: number,
    difficulty: number,
    due: number,
    lastReview: number | null,
    enabled: boolean
}


export type Review = {
    id: number,
    cardId: number,
    rating: Rating,
    reviewTime: number,
    reviewDuration: number
}


export type StudyStats = {
    totalCallSeconds: number,
    totalFlashcardSeconds: number,
    todayCallSeconds: number,
    todayFlashcardSeconds: number,
    totalReviews: number,
    todayReviews: number
}

export type HistoricalStats = {
    daily: Record<string, StatsRecord>,
    weekly: Record<string, StatsRecord>,
    monthly: Record<string, StatsRecord>
}

export type StatsRecord = {
    call_seconds?: number,
    flashcard_seconds?: number,
    review_count?: number
}

export const templateIdToLanguage = (templateId?: string) => {
    if (!templateId) {
        return "";
    }
    const parts = templateId.split("-");
    if (parts.length < 2) {
        return "";
    }
    return parts[0];
}