<script setup lang="ts">
import { getLanguageFlag } from '@/practice/util';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { useAnalytics } from '@/practice/useAnalytics';
import { onMounted, onBeforeUnmount } from 'vue'
const { trackEvent } = useAnalytics()

const props = defineProps<{
  pageStrings: { [key: string]: string },
  finalLeaderboard: {
    userId: string;
    name: string;
    language: string;
    totalDuration: number;
    ranking: number;
    streak?: number;
  }[],
  userId: string
}>();

const emit = defineEmits(['close']);

const handleClose = () => {
  emit('close');
}

const formatDuration = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  
  if (hours > 0) {
    return `${hours}${props.pageStrings.h} ${remainingMinutes}${props.pageStrings.m}`;
  }
  return `${remainingMinutes}${props.pageStrings.m}`;
}

// Handle escape key press to close the leaderboard
const handleKeyDown = (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    handleClose();
  }
}

onMounted(() => {
  window.addEventListener('keydown', handleKeyDown);
});

onBeforeUnmount(() => {
  window.removeEventListener('keydown', handleKeyDown);
});
</script>

<style scoped>
.ranking {
    color: var(--Text-text-base, #030712);
    text-align: center;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem; /* 125% */
  letter-spacing: -0.02rem;
}
.my-hover{
    background-color: hsla(210, 20%, 98%, 1);
}
.selected-color {
    color: #0070f3;
}
.bgtransparent{
    background-color: transparent;
}
/* Ensure user row has proper borders */
.user-row {
  border-top: 1px solid var(--border-subtle, #E5E7EB) !important;
  border-bottom: 1px solid var(--border-subtle, #E5E7EB) !important;
}
</style>

<template>
    <div class="pointer-events-auto relative bg-bg-base overflow-hidden rounded-2xl w-[22rem] md:w-[32rem] mx-auto pr-5">
      <div class="flex items-center px-2 mb-0">
        <p class="text-xl font-semibold">{{ pageStrings.leaderboard }}</p>
      </div>
      
      <Table class="mb-0 mt-0 ml-0 mr-0">
        <TableHeader>
          <TableRow class="text-sm-medium2 text-text-subtle h-[1.8rem] border-b border-border-subtle">
            <TableHead class="text-center w-[5%]">{{pageStrings.rank}}</TableHead>
            <TableHead class="text-center pl-2 w-[5%]">{{pageStrings.language}}</TableHead>
            <TableHead class="text-left pl-6 w-[10%]">{{pageStrings.name}}</TableHead>
            <TableHead class="text-left pl-2 w-[20%]">{{pageStrings.Minutes}}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow v-for="(lbUser, index) in finalLeaderboard" :key="lbUser.userId" class="h-[3.5rem] rounded-2xl text-2xs sm:text-xs p-0 hover:bg-bg-subtle border-t border-border-subtle" :class="{ 'selected-color user-row': lbUser.userId === userId }">
            <TableCell class="text-center">
              <span class="text-md rounded-2xl text-text-base bg-bg-subtle border-medium py-[0.25rem] w-[2.6rem] text-center inline-block" :class="{ 'selected-color': lbUser.userId === userId }">
                {{ lbUser.ranking + (lbUser.ranking % 10 === 1 && lbUser.ranking !== 11 ? 'st' : lbUser.ranking % 10 === 2 && lbUser.ranking !== 12 ? 'nd' : lbUser.ranking % 10 === 3 && lbUser.ranking !== 13 ? 'rd' : 'th') }}
              </span>
            </TableCell>
            <TableCell class="flex justify-center items-center pt-4">
              <img :src="`/assets/country_icons/${getLanguageFlag(lbUser.language)}.svg`" 
                class="w-[1.5rem] h-[1.5rem]" 
                alt="flag" />
            </TableCell>
            <TableCell class="text-left pl-6 text-sm-medium">{{ lbUser.name ? (lbUser.name.split(' ')[0].split('@')[0].length > 13 ? lbUser.name.split(' ')[0].split('@')[0].slice(0,13) + '..' : lbUser.name.split(' ')[0].split('@')[0]) : '' }}</TableCell>
            <TableCell class="text-left pl-2 text-sm-medium">
              {{ formatDuration(lbUser.totalDuration) }} 
              <span v-if="lbUser.streak || lbUser.userId === userId"
                class="text-xs" 
                :class="{ 'selected-color': lbUser.userId === userId, 'text-text-subtle': lbUser.userId !== userId }" >({{ pageStrings.streak }} {{ lbUser.streak }})</span>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
</template>
