<script setup lang="ts">
import type { Message, Word } from '@/types/model';
import { computed, nextTick, onMounted, onUnmounted, ref, watch, type Ref } from 'vue';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { PhPlay, PhPause, PhSealQuestion, PhSpinner, PhStopCircle, PhFlag, PhPlusCircle, PhMinusCircle, PhListPlus, PhCheck } from '@phosphor-icons/vue';
const hoveredMessageIndex = ref<number | null>(null);
const hoveredWordIndex = ref<number | null>(null);
import Separator from './Separator.vue';
import { apiRes, PracticeAPI, type AddWordResponse, type ApiRes, type CorrectMessageResponse, type DeleteWordResponse, type FlagMessageResponse } from './api';
import { domainPort, protocol } from '@/url';
import { convertRubyToRaw } from './util';
import { useToast } from '@/components/ui/toast/use-toast'
import { Toaster } from '@/components/ui/toast'
const messageContainer: Ref<HTMLElement | null> = ref(null);
const API: PracticeAPI = new PracticeAPI(protocol + domainPort);
const correctMessageResult = apiRes<CorrectMessageResponse>();
const correctionResults: Ref<{ [messageId: string]: CorrectMessageResponse }> = ref({});
const correctingMessageId = ref<string | null>(null);
const flagMessageResult = apiRes<FlagMessageResponse>();
const flaggedMessages: Ref<string[]> = ref([]);
const addWordResult = apiRes<AddWordResponse>();
const hoveringTooltip = ref(false);
const leftHovering = ref(false);
const addingOrRemovingWord = ref(false);
const isAtBottom = ref(false);


const { toast, dismiss} = useToast()


const props = defineProps<{
  pageStrings: { [key: string]: string },
  messages: Message[],
  sessionMessageIndexStart: number,
  userId: string,
  language: string,
  streamingId: string | null,
  replayingAudio: boolean,
  onPlayAudio: (messageId: string) => void,
  onStopAudio: (messageId: string) => void,
  onUserTriggeredInterrupt: () => void,
  updatedSavedWords: (getDefinitions: boolean) => Promise<void>,
  getWordsResult: Word[],
  savedWordsOpen: boolean,
}>();

const flagMessage = async (messageId: string) => {
  await API.flagMessage(messageId, flagMessageResult);
  flaggedMessages.value.push(messageId);
};


onMounted(async () => {
  scrollToBottom();
  const handleKeyPress = (event: KeyboardEvent) => {
    // Space bar to stop/start audio
    if (event.code === 'KeyS' && props.messages.length > 1) {
      const lastMessage = props.messages[props.messages.length - 1];
      if (lastMessage.fromId !== props.userId && !lastMessage.confirmed && props.streamingId === lastMessage.messageId) {
        event.preventDefault();
        props.onUserTriggeredInterrupt();
      }
    }
    if (event.code === 'Escape') {
      hoveredWordIndex.value = null;
      hoveredMessageIndex.value = null;
    }
  };

  const handleClick = (event: MouseEvent) => {
    // Skip on mobile devices
    if ('ontouchstart' in window) {
      setTimeout(() => {
        if (leftHovering.value && !hoveringTooltip.value) {
          hoveredWordIndex.value = null;
          hoveredMessageIndex.value = null;
        }
      }, 50);
      return;
    }
    if (hoveringTooltip.value) {
      return;
    }
    hoveredWordIndex.value = null;
    hoveredMessageIndex.value = null;
  };

  window.addEventListener('keydown', handleKeyPress);
  window.addEventListener('click', handleClick);
  
  // Clean up event listeners on component unmount
  onUnmounted(() => {
    window.removeEventListener('keydown', handleKeyPress);
    window.removeEventListener('click', handleClick);
  });
  props.updatedSavedWords(false);
});


const addWord = async (messageId: string, word: string, message: Message, wordIndex: number) => {
  if (addingOrRemovingWord.value) {
    return;
  }
  addingOrRemovingWord.value = true;
  const rawWord = convertRubyToRaw(word);
  const lowercaseWord = rawWord.toLowerCase();

  // Since wordIndex directly refers to the index in message.content,
  // we can just check that specific content item
  const content = message.content;
  let occurrence = 0;

  for (let i = 0; i < content.length; i++) {
    const contentItem = content[i];
    const contentText = convertRubyToRaw(contentItem.content);
    if (contentText.toLowerCase() === lowercaseWord) {
      if (i == wordIndex) {
        await API.addWord(messageId, lowercaseWord, occurrence, props.language, addWordResult);
      }
      occurrence++;
    }
  }
  if (localStorage.getItem("issen-add-word-toast") !== "true") {
    localStorage.setItem("issen-add-word-toast", "true");
    dismiss();
    toast({
      title: props.pageStrings.wordAdded + " " + convertRubyToRaw(word),
      description: props.pageStrings.seeInSavedWords,
      class: "max-w-[300px]"
    })
  }
  await props.updatedSavedWords(false);
  addingOrRemovingWord.value = false;
};
const wordIsAdded = (word: string) => {
  return props.getWordsResult.some(w => convertRubyToRaw(w.word).toLowerCase() === convertRubyToRaw(word).toLowerCase()) || false;
};
const removeWord = async (word: string) => {
  if (addingOrRemovingWord.value) {
    return;
  }
  addingOrRemovingWord.value = true;
  const matchingWords = props.getWordsResult.filter(w => convertRubyToRaw(w.word).toLowerCase() === convertRubyToRaw(word).toLowerCase()) || [];
  for (const matchingWord of matchingWords) {
    const deleteWordResult = apiRes<DeleteWordResponse>();
    await API.deleteWord(matchingWord.id, deleteWordResult);
  }
  await props.updatedSavedWords(false);
  addingOrRemovingWord.value = false;
};

const onHoverMessage = (messageIndex: number) => {
  hoveredMessageIndex.value = messageIndex;
};
const onHoverWord = (messageIndex: number, wordIndex: number) => {
  hoveredWordIndex.value = wordIndex;
  leftHovering.value = false;
};

const onLeaveWord = () => {
  leftHovering.value = true;
};

const onHoverTooltip = () => {
  setTimeout(() => {
    hoveringTooltip.value = true;
  }, 10);
};

const onLeaveTooltip = () => {
  hoveringTooltip.value = false;
};

const scrollToBottom = () => {
  nextTick(() => {
    if (messageContainer.value) {
      messageContainer.value.scrollTop = messageContainer.value.scrollHeight;
    }
  });
};

const correctMessage = async (messageId: string) => {
  const message = props.messages.find(m => m.messageId === messageId);
  if (!message) return;
  correctingMessageId.value = messageId;

  // Get previous messages up to this one
  const messageIndex = props.messages.findIndex(m => m.messageId === messageId);
  const prevMessages: string[] = props.messages
    .slice(Math.max(0, messageIndex - 5), messageIndex)
    .map(m => m.content.map(c => c.content).join(''));

  const currentMessage: string = message.content.map(c => c.content).join('');

  await API.correctMessage(prevMessages, currentMessage, props.language, correctMessageResult);
  correctingMessageId.value = null;
  if (correctMessageResult.data.value) {
    correctionResults.value[messageId] = correctMessageResult.data.value;

  }

};
// Watch for changes in the messages array
watch(props.messages, () => {
  if (props.messages.length > 0 && isAtBottom.value) {
    scrollToBottom();
  }
  handleScroll();
}, { deep: true });

watch(() => props.savedWordsOpen, (newValue) => {
  if (newValue) {
    hoveredWordIndex.value = null;
    hoveredMessageIndex.value = null;
    hoveringTooltip.value = false;
  }
});


const getMessageContent = (message: Message) => {
  return message.content.map(c => c.content).join('');
};

const handleScroll = () => {
    if (!messageContainer.value) return;
    const { scrollTop, scrollHeight, clientHeight } = messageContainer.value;
    isAtBottom.value = scrollHeight - scrollTop - clientHeight < 200;
};


const leadingStyle = computed(() => {
  if (props.language === "japanese" || props.language === "mandarin-chinese" || props.language === "cantonese") {
    return 'leading-loose';
  } else {
    return 'leading-normal';
  }
});


</script>

<style>
.tooltip-shadow {
    box-shadow: 0px 2px 4px 0px var(--Components-shadow, rgba(0, 0, 0, 0.09));
}


.scroll-container {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE/Edge */
}

.scroll-container::-webkit-scrollbar { 
  display: none; /* Chrome, Safari, Edge */
}
</style>

<template>  

<div class="w-[90%] w-3/4 px-4 pt-4 mx-auto mb-4 md:mb-8 max-w-[40rem] overflow-y-auto mt-auto scroll-container" ref="messageContainer" @scroll="handleScroll" >
  <div v-for="(message, index) in messages" :key="index" class="flex flex-col">
    <Separator class="mt-2 mb-6 text-gray-400" :label="pageStrings.thisSession" v-if="index === sessionMessageIndexStart && index > 0"/>
    <Separator class="mt-2 mb-6 text-gray-400" :label="getMessageContent(message)" v-if="message.isKickstartMessage"/>
    <Toaster/>
    <div :class="[
      'p-3 rounded-lg',
      message.fromId === userId ? 'bg-bg-chat-sent text-text-chat-sent mb-4 self-end' : 'bg-bg-chat-received text-text-base shadow-chat-message w-auto max-w-[33rem] mb-4 self-start',
      message.confirmed && streamingId === message.messageId && replayingAudio? 'shadow-focus border-0' : ''
    ]"
    @mouseover="onHoverMessage(index)"
    v-if="!message.isKickstartMessage"
    >
      <p :class="[
        'text-lg m-0'
      ]">
        <span v-for="(section, idx) in message.content" :key="idx">
          <span v-if="hoveredMessageIndex === index">
            <TooltipProvider v-if="section.definition && section.definition.length > 0" :delayDuration=500 :skipDelayDuration=500>
              <Tooltip :open="hoveredMessageIndex === index && hoveredWordIndex === idx" >
                <TooltipTrigger as-child>
                  <span :class="[
                    leadingStyle,
                    { 'bg-bg-chat-highlight-over': hoveredMessageIndex === index && hoveredWordIndex === idx }
                  ]"
                  @mouseover="onHoverWord(index, idx)" @mouseleave="onLeaveWord" v-html="section.content"></span>
                </TooltipTrigger>
                <TooltipContent 
                class="text-lg bg-toolbar-background tooltip-shadow text-fg-on-inverted" 
                :hide-when-detached="true" 
                @mouseover="onHoverTooltip"
                @mouseleave="onLeaveTooltip"
                >
                  <p class="flex items-center">
                    <span class="font-bold" v-html="section.content"></span>: {{section.definition}}
                    <PhPlusCircle class="w-4 h-4 ml-2 cursor-pointer" v-if="!wordIsAdded(section.content) && !addingOrRemovingWord"
                      @click="() => addWord(message.messageId, section.content, message, idx)"/>
                    <PhSpinner class="w-4 h-4 ml-2 animate-spin" v-if="addingOrRemovingWord"></PhSpinner>
                    <PhMinusCircle class="w-4 h-4 ml-2 text-red-500 cursor-pointer" 
                    @click="() => removeWord(section.content)"
                    v-else-if="wordIsAdded(section.content) && !addingOrRemovingWord"></PhMinusCircle>
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            <span v-else :class="leadingStyle" v-html="section.content"></span>
          </span>
          <span v-else :class="leadingStyle" v-html="section.content"></span>
        </span>
      </p>
      <p v-if="message.fromId !== userId" class="text-md-medium text-text-chat-label mt-1">
        {{ message.fromName }}
        <TooltipProvider :delayDuration=300 :skipDelayDuration=300>
          <Tooltip>
            <TooltipTrigger as-child>
              <PhStopCircle v-if="message.fromId !== userId && !message.confirmed && streamingId === message.messageId && messages.length > 1" 
                class="w-4 h-4 ml-2 inline cursor-pointer" @click="onUserTriggeredInterrupt" />
            </TooltipTrigger>
            <TooltipContent class="text-lg bg-toolbar-background tooltip-shadow text-fg-on-inverted">
              <p>{{ pageStrings.stopMessage }}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <span class="float-right">
          <!-- <TooltipProvider :delayDuration=300 :skipDelayDuration=300 v-if="message.confirmed">
            <Tooltip>
              <TooltipTrigger as-child>
                <PhFlag v-if="!flaggedMessages.includes(message.messageId)" class="w-4 h-4 ml-2 inline cursor-pointer" @click="() => flagMessage(message.messageId)" />
                <PhCheck v-else class="w-4 h-4 ml-2 inline cursor-pointer" />
              </TooltipTrigger>
              <TooltipContent class="text-lg bg-toolbar-background tooltip-shadow text-fg-on-inverted">
                <p v-if="!flaggedMessages.includes(message.messageId)">{{ pageStrings.flagMessage }}</p>
                <p v-else>{{ pageStrings.flagMessageDone }}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider> -->
          <TooltipProvider :delayDuration=300 :skipDelayDuration=300 v-if="index >= sessionMessageIndexStart && message.confirmed && streamingId !== message.messageId">
            <Tooltip>
              <TooltipTrigger as-child>
                <PhPlay 
                  class="w-4 h-4 ml-2 inline cursor-pointer" @click="onPlayAudio(message.messageId)" />
              </TooltipTrigger>
              <TooltipContent class="text-lg bg-toolbar-background tooltip-shadow text-fg-on-inverted">
                <p>{{ pageStrings.playMessage }}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <TooltipProvider :delayDuration=300 :skipDelayDuration=300 v-if="message.confirmed && streamingId === message.messageId">
            <Tooltip>
              <TooltipTrigger as-child>
                <PhPause 
                  @click="onStopAudio(message.messageId)"
                  class="w-4 h-4 ml-2 inline cursor-pointer" />
              </TooltipTrigger>
              <TooltipContent class="text-lg bg-toolbar-background tooltip-shadow text-fg-on-inverted">
                <p>{{ pageStrings.stopMessagePlayback }}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </span>
      </p>
      <p v-else  class="text-md text-text-chat-label mt-1"> 
        <span class="float-right">
          <PhSealQuestion v-if="message.fromId === userId && !(message.messageId in correctionResults) && correctingMessageId !== message.messageId"
          class="w-4 h-4 ml-2 inline cursor-pointer"
           @click="() => correctMessage(message.messageId)" />
           <PhSpinner v-if="correctingMessageId === message.messageId" class="w-4 h-4 ml-2 inline animate-spin" />
           <span v-if="message.messageId in correctionResults">
            <span v-html="correctionResults[message.messageId].correctionSummary"></span> <span v-if="correctionResults[message.messageId].score > 0">{{ correctionResults[message.messageId].score }}/10</span>
           </span>
        </span>
      </p>
    </div>
  </div>
</div>
</template>