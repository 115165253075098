import { inject } from 'vue'
import type { Analytics } from 'firebase/analytics'
import { logEvent } from 'firebase/analytics'
export function useAnalytics() {
  const analytics = inject('analytics') as Analytics | null

  const trackEvent = (eventName: string, params?: object) => {
    if (analytics) {
      // Not used outside of production
      logEvent(analytics, eventName, params)
    }
  }

  return {
    trackEvent
  }
}