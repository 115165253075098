<script setup lang="ts">
import { PhChats, PhBookOpenText, PhPersonSimpleTaiChi, PhLightbulb, PhFilmSlate, PhCircleNotch  } from '@phosphor-icons/vue';
import { onMounted, ref, watch } from 'vue';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import type { UserSettings } from '@/types/model';
const isFocused = ref(false);

const props = defineProps<{
  pageStrings: { [key: string]: string },
  startEnabled: boolean,
  clickedStart: (name: string) => void,
  handleEnterKey: (event: KeyboardEvent, name: string) => void,
  goToEmail: () => void,
  user: UserSettings | null,
}>();

const nameInputField = ref("");

if (props.user) {
  if (props.user.name && nameInputField.value === "") {
    nameInputField.value = props.user.name;
  }
}
onMounted(() => {
  setTimeout(() => {  
    isFocused.value = true;
  }, 100);
});

watch(() => props.user, (newUser) => {
  if (newUser?.name && nameInputField.value === "") {
    nameInputField.value = newUser.name;
  }
  if (!newUser) {
    nameInputField.value = "";
  }
});
</script>

<style scoped>  
.bg-button-primary {
  background: var(--button-primary);
}
.bg-button-secondary {
  background: var(--button-secondary);
}
</style>

<template>  
  <div class="flex-grow flex items-start md:items-center justify-center pt-20 md:pt-0">
    <div class="flex flex-col items-center justify-center w-full px-4 mt-8 md:-mt-16">
      <div class="w-full max-w-[420px] md:max-w-[450px] lg:max-w-[500px]">
        <!-- Main Card -->
        <div class="bg-white rounded-lg shadow-card-hover p-5 md:p-8 w-full mb-2 mx-auto">
          <p class="mb-4 text-hxl md:text-h2xl text-center">{{ pageStrings.issenSummary1 }}</p>
          <p class="text-custom-lg md:text-custom-lg text-center mb-6">
            {{ pageStrings.issenSubtitle1 }}
            {{ pageStrings.issenSubtitle2 }}
          </p>
          
          <div class="flex flex-col items-center justify-center gap-4">
            <!-- Name input and button in a row -->
            <div class="flex flex-col md:flex-row w-full items-center justify-center gap-3">
              <Input 
                ref="myinput"
                type="text" 
                class="w-full max-w-[320px] md:max-w-none md:flex-1"
                v-model="nameInputField" 
                autofocus
                :focus="isFocused"
                @focus="isFocused=true"
                @blur="isFocused = false"
                :placeholder="pageStrings.enterName"
                @keyup="handleEnterKey($event, nameInputField)"
              />
              <Button variant="primary" class="w-full max-w-[320px] md:w-auto md:whitespace-nowrap" :disabled="!startEnabled" @click="clickedStart(nameInputField)">
                <PhCircleNotch v-if="!startEnabled" class="w-6 h-6 mr-1 animate-spin"/>
                <PhChats v-else class="w-6 h-6 mr-1"/>
                {{ pageStrings.startLesson }}
              </Button>
            </div>
            
            <div class="text-center w-full" v-if="(!user || user.userStatus === 'TRIAL') && startEnabled">
              {{ pageStrings.alreadyHave }} <span class="cursor-pointer text-text-interactive" @click="goToEmail">{{ pageStrings.logIn }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>