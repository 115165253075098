<script setup lang="ts">
import router from "@/router";

import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import { Input } from '@/components/ui/input';
import IssenHeader from "@/practice/IssenHeader.vue";
import { Label } from "@/components/ui/label"
import { ref, type Ref } from "vue";
import { getMessagesFromLanguage } from "@/languages/messages";
import { getDefaultSourceLanguage } from "@/practice/util";
import { onMounted } from 'vue';
import { apiRes, type CheckAllowlistResponse, type CheckUsernameResponse, type CreateUserResponse, type GetUserResponse, type LoginResponse, type LogoutResponse, PracticeAPI, type SubscribeResponse, type UpdateUserResponse } from "@/practice/api";
import { protocol, domainPort } from "@/url";
import { useRoute } from 'vue-router';
import { priceId } from "@/practice/subscription";


const guiLanguage = ref(getDefaultSourceLanguage());
const pageStrings= ref(getMessagesFromLanguage(guiLanguage.value));
const email = ref("");
const password1 = ref("");
const isFocused1: Ref<boolean> = ref(false);
const subscribeResult = apiRes<SubscribeResponse>();

const API: PracticeAPI = new PracticeAPI(protocol + domainPort);
const logoutResponse = apiRes<LogoutResponse>();
const loginResponse = apiRes<LoginResponse>();
const error: Ref<string> = ref("");
let priceIdOverride: string = priceId;
const route = useRoute();
const getUserResult = apiRes<GetUserResponse>();


onMounted(async () => {
  await API.getUser(getUserResult);
  if (getUserResult.data.value && getUserResult.data.value.user.guiLang) {
    guiLanguage.value = getUserResult.data.value.user.guiLang;
    pageStrings.value = getMessagesFromLanguage(guiLanguage.value);
  }
  await API.logout(logoutResponse);
  email.value = route.query.email as string;
  isFocused1.value = true;
  if (route.query.priceId) {
    priceIdOverride = route.query.priceId as string;
  }
})

async function subscribe() {
  await API.subscribe(priceIdOverride, subscribeResult);
    if (subscribeResult.data.value) {
      window.location.href = subscribeResult.data.value.url;
    } else {
      console.log("No url in subscribeResult");
    }
}



async function submitPassword() {
  if (password1.value === "") {
    error.value = pageStrings.value["passwordCannotbeEmpty"];
    return;
  }
  error.value = "";
  await API.login(email.value, password1.value, loginResponse);
  if (loginResponse.data.value && loginResponse.data.value.success) {
    if (route.query.subscribe) {
      await subscribe();
      return;
    } else if (route.query.redirect) {
      router.push("/" + (route.query.redirect as string));
    } else {
      router.push("/chat");
    }
  } else {
    error.value = pageStrings.value["invalidUsernameOrPassword"];
  }
}

async function forgotPassword() {
  router.push({path: "/reset-password", query: {email: email.value}});
}

function back() {
  router.push("/email");
}
</script>

<template>
  <span class="page-container flex flex-col">
    <div class="w-full flex justify-between">
      <IssenHeader :pageStrings="pageStrings" :getUserResult="getUserResult" :preLogout="() => {}" :postLogout="() => {}"/>
    </div>
    <div class="flex-1 flex flex-col w-full">
      <div class="flex flex-grow justify-center items-center">
        <Card class="w-[350px]">
      <CardHeader>
        <CardTitle class="text-xl">

          {{ pageStrings.login }} {{ email }} </CardTitle>

      </CardHeader>
      <CardContent>
        <div v-if="error" class="text-red-500 font-bold mb-2">{{ error }}</div>
          <div class="grid items-center w-full gap-4">
            <div class="flex flex-col space-y-1.5">
              <Label class="text-md">{{ pageStrings.password }}</Label>
              <Input type="password" v-model="password1"  :placeholder="pageStrings.password"
              autofocus
               @keydown.enter="submitPassword"
               :focus="isFocused1"
               @focus="isFocused1=true"
               @blur="isFocused1 = false"
               />
            </div>
        </div>
      </CardContent>
      <CardFooter class="flex justify-end space-x-2">
        <p class="text-md text-gray-500 cursor-pointer" @click="forgotPassword">{{ pageStrings.forgotPassword }}</p>
        <Button variant="ghost" @click="back">
            {{ pageStrings.back }}
        </Button>
        <Button @click="submitPassword" variant="primary">
            {{ pageStrings.continue }}
        </Button>
      </CardFooter>
    </Card>
    </div>
    </div>
    </span>


</template>
