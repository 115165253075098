<script setup lang="ts">
import router from "@/router";

import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import IssenHeader from "@/practice/IssenHeader.vue";
import { ref, type Ref } from "vue";
import { getMessagesFromLanguage } from "@/languages/messages";
import { getDefaultSourceLanguage } from "@/practice/util";
import { onMounted } from 'vue';
import { apiRes, type CheckAllowlistResponse, type CheckUsernameResponse, type GetUserResponse, PracticeAPI } from "@/practice/api";
import { protocol, domainPort } from "@/url";
import { UserStatus } from "@/types/model";


const guiLanguage = ref(getDefaultSourceLanguage());
const pageStrings= ref(getMessagesFromLanguage(guiLanguage.value));
const API: PracticeAPI = new PracticeAPI(protocol + domainPort);
const getUserResult = apiRes<GetUserResponse>();

onMounted(async () => {
    await API.getUser(getUserResult);
    if (getUserResult.data.value && getUserResult.data.value.user.guiLang) {
      guiLanguage.value = getUserResult.data.value.user.guiLang;
      pageStrings.value = getMessagesFromLanguage(guiLanguage.value);
    }
    if (getUserResult.data.value) {
      console.log("User data:", getUserResult.data.value);
      if (getUserResult.data.value.user.userStatus === UserStatus.TRIAL) {
        router.push("/email");
      }
    } else {
      console.error("User not logged in");
    }
})

async function goToApp() {
  router.push("/");
}

</script>

<template>
  <span class="page-container flex flex-col">
    <div class="w-full flex justify-between">
      <IssenHeader :pageStrings="pageStrings" :getUserResult="getUserResult" :preLogout="()=>{}" />
    </div>
    <div class="flex-1 flex flex-col w-full">
      <div class="flex flex-grow justify-center items-center">
        <Card class="w-[350px] md:w-[500px] lg:w-[600px] border-2 border-gray-300 shadow-md" v-if="getUserResult.data.value">
          <CardHeader>
            <CardTitle class="text-xl md:text-2xl">{{ pageStrings.welcome }}, {{ getUserResult.data.value.user.name }}</CardTitle>
          </CardHeader>
          <CardContent>
            <p class="text-md md:text-lg mt-0">
              {{ pageStrings.issenExplanation }}
            </p> 
            <p class="text-md md:text-lg mt-4">
              {{ pageStrings.issenExplanation2 }}
            </p>
            <p class="text-md md:text-lg mt-4">
              {{ pageStrings.issenExplanation3 }}
            </p>
            <p class="text-md md:text-lg mt-4">
              {{ pageStrings.issenExplanation4 }}
            </p>
          </CardContent>
          <CardFooter class="flex justify-between px-6 pb-6">
            <Button @click="goToApp" variant="primary">
                {{ pageStrings.goToApp}}
            </Button>
          </CardFooter>
        </Card>
      </div>
    </div>
  </span>
</template>
